import React, { useState } from "react";
import {
  SearchOutlined,
  UserOutlined,
  PlayCircleOutlined,
  MinusCircleOutlined
} from "@ant-design/icons";
import { Row, Col, Space, Typography, Input, Tooltip, Table } from "antd";
import { LiftedCard } from "../Common/CommonCards";
import { NormalButtonSmall, DangerButtonSmall } from "../Common/CommonButtons";
import { LargeHeaderTitle } from "../Common/CommonFonts";
import { isNil, isUndefined } from "lodash-es";
import { useTranslation } from "react-i18next";
import {
  useGetLicensesQuery,
  User,
  useSetLicenseRenewAutoMutation
} from "../../state/cargologRestApi";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  openRenewalModal,
  addToRenewalCart
} from "../../state/licenseStoreSlice";
import RemoveLicenseModal from "../Modals/RemoveLicenseModal";
import { selectImpersonate } from "../../state/persistantStateSlice";
import {
  impersonatingHeight143,
  impersonatingHeight320,
  size
} from "../../helpers/pageHelper";
import { ColumnsType } from "antd/lib/table";
const { Text, Title } = Typography;

/** All the fields for table used in this component */
interface ITableData {
  key: number;
  licenseData: {
    licenseName: string;
    licenseId: string;
    isActivated: boolean;
  };
  created: string;
  licenseDates: {
    expires: string;
    renewAuto: boolean;
    isActivated: boolean;
  };
  companyId: string;
  userId?: string;
  userData?: User;
  licensePrice: {
    price: number;
    currency: string;
    discount: number;
  };
  renewAuto: boolean;
  actions?: IPlanActions;
}

/** all aviable actions for a field in the table */
interface IPlanActions {
  renewLicense: () => void;
  cancelSubscription: () => void;
  renewAuto: boolean;
  expires: string;
}

const AdminLicenses = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isImpersonating } = useSelector(selectImpersonate);

  const [stringValue, setStringValue] = useState("");

  let tableData: ITableData[] | undefined = undefined;

  const { data: licensesData, isLoading } = useGetLicensesQuery({});

  // Set automatic license renewal
  const [setLicenseRenewAuto] = useSetLicenseRenewAutoMutation();

  const timezoneOffset = new Date().getTimezoneOffset();
  if (!isUndefined(licensesData)) {
    tableData = licensesData
      .filter((license) => {
        return (
          stringValue === "" ||
          license.text.toString().includes(stringValue.toLowerCase()) ||
          license.id.toString().includes(stringValue.toLowerCase()) ||
          license.price.toString().includes(stringValue.toLowerCase()) ||
          license.endDate.toLowerCase().includes(stringValue.toLowerCase()) ||
          license.created.toLowerCase().includes(stringValue.toLowerCase())
        );
      })
      .map((license, index) => {
        const created = dayjs
          .utc(license.created)
          .subtract(timezoneOffset, "minute")
          .format("YYYY-MM-DD, HH:mm");
        const expires = dayjs
          .utc(license.endDate)
          .subtract(timezoneOffset, "minute")
          .format("YYYY-MM-DD, HH:mm");

        const row: ITableData = {
          key: index,
          licenseData: {
            licenseName: license.text,
            licenseId: license.id,
            isActivated: license.isActivated
          },
          created: created,
          licenseDates: {
            expires: expires,
            renewAuto: license.renewAuto,
            isActivated: license.isActivated
          },
          licensePrice: {
            price: license.price,
            currency: license.currency,
            discount: license.discount
          },
          companyId: license.companyId,
          userId: license.userId,
          userData: license.user,
          renewAuto: license.renewAuto,
          actions: {
            renewLicense: () => {
              dispatch(
                addToRenewalCart({
                  licenseId: license.id,
                  licensePriceId: license.licensePriceId
                })
              );
              dispatch(openRenewalModal());
            },
            cancelSubscription: () => {
              if (isNil(license.orderId)) {
                setLicenseRenewAuto({
                  licenseId: license.id,
                  renewAuto: false
                });
              } else {
                setLicenseRenewAuto({
                  licenseId: license.id,
                  renewAuto: false,
                  cancelReason: "Cancelled in Cargolog Connect",
                  orderId: license.orderId
                });
              }
            },
            renewAuto: license.renewAuto,
            expires: license.endDate
          }
        };
        return row;
      })
      .reverse();
  }

  const columns: ColumnsType<ITableData> = [
    {
      title: "",
      dataIndex: "userData",
      key: "userId",
      width: 30,
      align: "center",
      ellipsis: true,
      render: (data) => {
        return data?.userId ? (
          <Tooltip
            placement="right"
            title={data?.firstName + " " + data?.lastName}
          >
            <UserOutlined />
          </Tooltip>
        ) : null;
      }
    },
    {
      title: t("Licenses"),
      dataIndex: "licenseData",
      key: "licenseData",
      ellipsis: true,
      sorter: (a, b) => {
        if (a.licenseData.licenseName < b.licenseData.licenseName) {
          return -1;
        }
        if (a.licenseData.licenseName > b.licenseData.licenseName) {
          return 1;
        }
        return 0;
      },
      render: (data) => (
        <>
          <Title
            level={5}
            style={{ marginBlock: 0 }}
            type={data.isActivated ? undefined : "secondary"}
          >
            {data.licenseName}
          </Title>
          <Text type="secondary">{data.licenseId}</Text>
        </>
      )
    },
    {
      title: t("Created"),
      dataIndex: "created",
      key: "created",
      ellipsis: true,
      sorter: (a, b) => {
        if (a.created < b.created) {
          return -1;
        }
        if (a.created > b.created) {
          return 1;
        }
        return 0;
      },
      render: (created) => (
        <>
          <Text>{created}</Text>
        </>
      )
    },
    {
      title: t("Status"),
      dataIndex: "licenseDates",
      key: "licenseDates",
      ellipsis: true,
      sorter: (a, b) => {
        if (a.licenseDates.expires < b.licenseDates.expires) {
          return -1;
        }
        if (a.licenseDates.expires > b.licenseDates.expires) {
          return 1;
        }
        return 0;
      },
      render: (dates) =>
        dates.isActivated ? (
          <>
            <Text strong>
              {dates.renewAuto ? t("tableRenews") : t("tableExpires")}
            </Text>
            <br />
            <Text>{dates.expires}</Text>
          </>
        ) : (
          <Text strong type="warning">
            {t("WaitingForPayment")}
          </Text>
        )
    },
    {
      title: t("tablePrice"),
      dataIndex: "licensePrice",
      key: "licensePrice",
      align: "right",
      ellipsis: true,
      render: (data) => {
        const price =
          data.discount > 0
            ? data.price * (1 - data.discount / 100)
            : data.price;
        const checkIfDecimals = price % 1 !== 0;

        if (data.currency === null && data.price === 0) {
          data.currency = "SEK";
        }

        return (
          <>
            <Text strong> {checkIfDecimals ? price.toFixed(2) : price}</Text>{" "}
            <Text>{data.currency}</Text>{" "}
            {data.discount > 0 && (
              <Text style={{ textDecoration: "line-through" }}>
                ({data.price} {data.currency})
              </Text>
            )}
          </>
        );
      }
    },
    {
      title: t("tableActions"),
      dataIndex: "actions",
      key: "actions",
      // width: 200,
      ellipsis: true,
      render: (actions: IPlanActions) => {
        const content: React.JSX.Element[] = [];
        const nonrenewable = actions.expires === "2099-12-31T00:00:00";
        let index = 0;
        if (actions) {
          if (actions.renewAuto) {
            content.push(
              <DangerButtonSmall
                key={index++}
                icon={<MinusCircleOutlined />}
                onClick={actions.cancelSubscription}
              >
                {t("CancelSubscription")}
              </DangerButtonSmall>
            );
          }
          if (!actions.renewAuto && !nonrenewable) {
            content.push(
              <NormalButtonSmall
                key={index++}
                icon={<PlayCircleOutlined />}
                onClick={actions.renewLicense}
              >
                {t("RenewLicense")}
              </NormalButtonSmall>
            );
          }
        }
        return <>{content}</>;
      }
    }
  ];

  return (
    <>
      <LiftedCard
        style={{
          margin: size.m1,
          height: impersonatingHeight143(isImpersonating)
        }}
      >
        <Row justify="space-between" style={{ marginBottom: size.l2 }}>
          <Col>
            <Space>
              <LargeHeaderTitle>{t("tableTitleMyPlans")}</LargeHeaderTitle>
            </Space>
          </Col>
          <Col>
            <Input
              placeholder={t("filterPlans")}
              prefix={<SearchOutlined />}
              value={stringValue}
              onChange={(e) => setStringValue(e.target.value)}
            />
          </Col>
        </Row>

        <Row>
          <Table
            columns={columns}
            dataSource={tableData}
            scroll={{
              x: "calc(100vw - 300px)",
              y: impersonatingHeight320(isImpersonating)
            }}
            style={{ width: "100%" }}
            size="small"
            tableLayout="auto"
            loading={isLoading}
            pagination={{
              defaultPageSize: 20,
              hideOnSinglePage: true,
              showSizeChanger: true
            }}
          />
        </Row>
      </LiftedCard>
      <RemoveLicenseModal />
    </>
  );
};

export default AdminLicenses;
