import { GeneralSystemInfo } from "../models/ISystemInfo";
import { UpdxFileFormat } from "../models/UpdxFileFormat";
import { isNil } from "lodash-es";
import { Optional } from "../utils/utilTypes";

export interface AppVersion {
  appVersionMajor: number;
  appVersionMinor: number;
  appBuildMain: number;
  appBuildSub: number;
}

export const getParxVersion = (): number => 1;

export const getDatxVersion = (): number => 1;

export const getCfgVersion = (): number => 10;

export const getOsVersion = (): string => navigator.userAgent;

export const getAppVersionString = (): string => "1.4.28";

export const getAppVersion = () => {
  /** version uses Semantic versioning, e.g x.x.x */
  // const version: string = app.getVersion();

  // const [major, minor, patch] = version.split(".");

  return {
    appVersionMajor: 1,
    appVersionMinor: 4,
    appBuildMain: 28,
    appBuildSub: 0
  };
};

/** Returns app id for windows */
export const getAppId = (): number => 0x01;

export const getTimeZoneId = () => {
  //write matcher and more
};

/**
 * Checks if the current system has at least the needed firmware version
 * If no needed firmware version is set, all systems are allowed to upgrade
 */
export const isFat100Upgradable = (
  systemInfo: GeneralSystemInfo,
  fwNeeded: UpdxFileFormat["fwNeeded"]
): boolean => {
  if (isNil(fwNeeded)) {
    return true;
  }

  const systemVersionString: string =
    systemInfo.fwMajorVersion.toString().padStart(3, "0") +
    systemInfo.fwMinorVersion.toString().padStart(3, "0") +
    systemInfo.fwMainBuild.toString().padStart(3, "0") +
    systemInfo.fwSubBuild.toString().padStart(3, "0");

  const reqVersionString: string =
    fwNeeded.minFwMajorVersion.toString().padStart(3, "0") +
    fwNeeded.minFwMinorVersion.toString().padStart(3, "0") +
    fwNeeded.minFwMainBuild.toString().padStart(3, "0") +
    fwNeeded.minFwSubBuild.toString().padStart(3, "0");

  return systemVersionString >= reqVersionString;
};

export const getSystemInfoVersionAsString = (
  systemInfo: Optional<GeneralSystemInfo>
): string => {
  if (isNil(systemInfo)) {
    return "unknown";
  }

  const { fwMajorVersion, fwMinorVersion, fwMainBuild, fwSubBuild } =
    systemInfo;

  return `${fwMajorVersion}.${fwMinorVersion}.${fwMainBuild}.${fwSubBuild}`;
};

export const getUpdxNeededVersionAsString = (
  fwNeeded: UpdxFileFormat["fwNeeded"]
): string => {
  if (isNil(fwNeeded)) {
    return "unknown";
  }

  const {
    minFwMajorVersion,
    minFwMinorVersion,
    minFwMainBuild,
    minFwSubBuild
  } = fwNeeded;

  return `${minFwMajorVersion}.${minFwMinorVersion}.${minFwMainBuild}.${minFwSubBuild}`;
};
