import { skipToken } from "@reduxjs/toolkit/query";
import { notification } from "antd";
import { Buffer } from "buffer";
import { isUndefined } from "lodash-es";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { impersonateBannerHeight } from "../../helpers/pageHelper";
import {
  useDownloadDatxQuery,
  useDownloadSerializedDataQuery
} from "../../state/cargologRestApi";
import {
  setFailedOpeningDatxFile,
  setIsDownloadingDatxFile,
  unpackDatxAsync
} from "../../state/openDatxSlice";
import {
  RecentFile,
  appendRecentOnlineDatxFile,
  selectImpersonate,
  selectRecentDatxFiles
} from "../../state/persistantStateSlice";
import { NormalSideMenuCard } from "../Common/CommonCards";
import OnlineFiles from "../MicroComponents/OnlineFiles";
import OpenFileButton from "../MicroComponents/OpenFileButton";
import OpenFiles from "../MicroComponents/OpenFiles";
import RecentFiles from "../MicroComponents/RecentFiles";
import { GeneralSideMenuArrow } from "../MicroComponents/GeneralSideMenuArrow";

interface IProps {
  isExpanded?: boolean;
  isFloating?: boolean;
  width?: number;
  style?: React.CSSProperties;
}

const GraphSideMenu: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isImpersonating } = useSelector(selectImpersonate);
  const recentFiles = useSelector(selectRecentDatxFiles);

  interface IDatxToDownload {
    fileId: string;
    fileName: string;
    parameterId: string;
  }
  // Current file being downloaded
  const [datxToDownload, setDatxToDownload] = useState<IDatxToDownload>();
  const [lastIdToDownload, setLastIdToDownload] = useState<string>();

  // Downloads the selected file from the server
  // If the datxToDownload is set, download the datx file with the fileId otherwise download the serialized data with the parameterId
  //! Not used as of now due to pending backend changes
  // const { data: selectedDatxFile, error: selectedDatxFileError } =
  //   datxToDownload && datxToDownload.fileId
  //     ? useDownloadDatxQuery({ datxFileId: datxToDownload.fileId })
  //     : useDownloadSerializedDataQuery(
  //         datxToDownload
  //           ? { parameterId: datxToDownload.parameterId }
  //           : skipToken
  //       );

  const { data: selectedDatxFile, error: selectedDatxFileError } =
    useDownloadSerializedDataQuery(
      datxToDownload ? { parameterId: datxToDownload.parameterId } : skipToken
    );

  // If the file is already cached
  useEffect(() => {
    if (
      !isUndefined(selectedDatxFile) &&
      !isUndefined(datxToDownload) &&
      (datxToDownload.fileId
        ? datxToDownload.fileId === lastIdToDownload
        : datxToDownload.parameterId === lastIdToDownload)
    ) {
      const rawData: number[] = [...selectedDatxFile];
      dispatch<any>(
        unpackDatxAsync({ filePath: datxToDownload.fileName, rawData })
      );
      setDatxToDownload(undefined);
    }
  }, [datxToDownload]);

  // If the file is newly downloaded
  useEffect(() => {
    if (!isUndefined(selectedDatxFile) && !isUndefined(datxToDownload)) {
      const rawData: number[] = [...selectedDatxFile];
      dispatch<any>(
        unpackDatxAsync({ filePath: datxToDownload.fileName, rawData })
      );
      setLastIdToDownload(
        datxToDownload.fileId
          ? datxToDownload.fileId
          : datxToDownload.parameterId
      );
      setDatxToDownload(undefined);
    } else if (selectedDatxFileError) {
      dispatch<any>(setFailedOpeningDatxFile());
      notification.error({ message: t("FailedToDownloadDatxFile") });
    }
  }, [selectedDatxFile, selectedDatxFileError]);

  const openOnlineFile = (
    fileId: string,
    fileName: string,
    parameterId: string
  ) => {
    dispatch(setIsDownloadingDatxFile());
    setDatxToDownload({ fileId, fileName, parameterId });
    dispatch(appendRecentOnlineDatxFile({ fileId, fileName, parameterId }));
  };

  const onSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          const data: Buffer = Buffer.from(reader.result as ArrayBuffer);
          const rawData: number[] = [...data];
          dispatch<any>(unpackDatxAsync({ filePath: file.name, rawData }));
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const openRecentFile = async (recentFile: RecentFile) => {
    if (recentFile.fileType === "online") {
      // If the chosen recent file is setup as it was before the `parameterId` was added, we need to convert it to the new format.
      // This is done by checking if the `parameterId` is undefined and the first part of the fileId is 12 characters long (YYMMDDHHmmss) which indicates the old format.
      if (
        recentFile.parameterId === undefined &&
        recentFile.fileId.split("-")[0].length === 12
      ) {
        const legacyRecentFile = {
          ...recentFile,
          fileId: "",
          parameterId: recentFile.fileId
        };

        dispatch(setIsDownloadingDatxFile());
        setDatxToDownload({
          fileId: legacyRecentFile.fileId,
          fileName: legacyRecentFile.fileName,
          parameterId: legacyRecentFile.parameterId
        });
      } else {
        dispatch(setIsDownloadingDatxFile());
        setDatxToDownload({
          fileId: recentFile.fileId,
          fileName: recentFile.fileName,
          parameterId: recentFile.parameterId
        });
      }
    }
  };

  const [mouseInArea, setMouseInArea] = useState(false);

  return (
    <NormalSideMenuCard
      title={mouseInArea || props.isExpanded ? t("Reports") : undefined}
      isExpanded={mouseInArea || props.isExpanded}
      isFloating={props.isFloating}
      isImpersonating={isImpersonating}
      onMouseEnter={() => setMouseInArea(true)}
      onMouseLeave={() => setMouseInArea(false)}
      style={props.style}
    >
      <div
        style={{
          overflow: "auto",
          outline: "none",
          maxHeight: `calc(100vh - (48px + 65px ${isImpersonating ? `+ ${impersonateBannerHeight}px` : ""}))`
        }}
      >
        {!mouseInArea && !props.isExpanded ? (
          <GeneralSideMenuArrow
            page="reports"
            isImpersonating={isImpersonating}
          />
        ) : (
          <>
            <OpenFileButton
              accept=".datx"
              onSelectFile={onSelectFile}
              buttonLabel={t("Openfile")}
            />

            <OpenFiles />

            <RecentFiles data={recentFiles} openFile={openRecentFile} />

            <OnlineFiles openFile={openOnlineFile} />
          </>
        )}
      </div>
    </NormalSideMenuCard>
  );
};

export default GraphSideMenu;
