import { LineChartOutlined } from "@ant-design/icons";
import { skipToken } from "@reduxjs/toolkit/query";
import { List, Row, Spin, Tooltip, Typography, notification } from "antd";
import { isUndefined } from "lodash-es";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { size } from "../../helpers/pageHelper";
import { useDownloadSerializedDataQuery } from "../../state/cargologRestApi";
import {
  setFailedOpeningDatxFile,
  setIsDownloadingDatxFile,
  unpackDatxAsync
} from "../../state/openDatxSlice";
import { NormalButtonSmall } from "../Common/CommonButtons";
import {
  LargeHeaderTitle,
  ellipsisIfLongTextStyle
} from "../Common/CommonFonts";
import { IDatxToDownload } from "../CompareGraphs/CompareGraphSideMenu";
import { LicenseAccess } from "../MicroComponents/LicenseAccess";
import { LandingCard } from "./Landing";
import {
  RecentFile,
  selectRecentDatxFiles
} from "../../state/persistantStateSlice";
import dayjs from "dayjs";
const { Text } = Typography;

const ReportLandingCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const recentFiles = useSelector(selectRecentDatxFiles);

  const [datxToDownload, setDatxToDownload] = useState<IDatxToDownload>();
  const [lastIdToDownload, setLastIdToDownload] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Downloads the selected file from the server
  // If the datxToDownload is set, download the datx file with the fileId otherwise download the serialized data with the parameterId
  //! Not used as of now due to pending backend changes
  // const { data: selectedDatxFile, error: selectedDatxFileError } =
  //   datxToDownload && datxToDownload.fileId
  //     ? useDownloadDatxQuery({ datxFileId: datxToDownload.fileId })
  //     : useDownloadSerializedDataQuery(
  //         datxToDownload
  //           ? { parameterId: datxToDownload.parameterId }
  //           : skipToken
  //       );

  const { data: selectedDatxFile, error: selectedDatxFileError } =
    useDownloadSerializedDataQuery(
      datxToDownload ? { parameterId: datxToDownload.parameterId } : skipToken
    );

  // If the file is already cached
  useEffect(() => {
    if (
      !isUndefined(selectedDatxFile) &&
      !isUndefined(datxToDownload) &&
      (datxToDownload.fileId
        ? datxToDownload.fileId === lastIdToDownload
        : datxToDownload.parameterId === lastIdToDownload)
    ) {
      const rawData: number[] = [...selectedDatxFile];
      dispatch<any>(
        unpackDatxAsync({ filePath: datxToDownload.fileName, rawData })
      );
      navigate("/graph");
      setDatxToDownload(undefined);
    }
  }, [datxToDownload]);

  // If the file is newly downloaded
  useEffect(() => {
    if (!isUndefined(selectedDatxFile) && !isUndefined(datxToDownload)) {
      const rawData: number[] = [...selectedDatxFile];
      dispatch<any>(
        unpackDatxAsync({ filePath: datxToDownload.fileName, rawData })
      );
      setLastIdToDownload(
        datxToDownload.fileId
          ? datxToDownload.fileId
          : datxToDownload.parameterId
      );
      navigate("/graph");
      setDatxToDownload(undefined);
    } else if (selectedDatxFileError) {
      dispatch<any>(setFailedOpeningDatxFile());
      notification.error({ message: t("FailedToDownloadDatxFile") });
    }
  }, [selectedDatxFile, selectedDatxFileError]);

  const openRecentFile = async (recentFile: RecentFile) => {
    if (recentFile.fileType === "online") {
      // If the chosen recent file is setup as it was before the `parameterId` was added, we need to convert it to the new format.
      // This is done by checking if the `parameterId` is undefined and the first part of the fileId is 12 characters long (YYMMDDHHmmss) which indicates the old format.
      if (
        recentFile.parameterId === undefined &&
        recentFile.fileId.split("-")[0].length === 12
      ) {
        const legacyRecentFile = {
          ...recentFile,
          fileId: "",
          parameterId: recentFile.fileId
        };

        dispatch(setIsDownloadingDatxFile());
        setDatxToDownload({
          fileId: legacyRecentFile.fileId,
          fileName: legacyRecentFile.fileName,
          parameterId: legacyRecentFile.parameterId
        });
      } else {
        dispatch(setIsDownloadingDatxFile());
        setDatxToDownload({
          fileId: recentFile.fileId,
          fileName: recentFile.fileName,
          parameterId: recentFile.parameterId
        });
      }
    }
  };

  return (
    <>
      {LicenseAccess(
        "ACD",
        <LandingCard
          key="analyze"
          styles={{ body: { paddingTop: size.m1 } }}
          title={
            <LargeHeaderTitle>
              <LineChartOutlined /> {t("Reports")}
            </LargeHeaderTitle>
          }
          extra={
            <NormalButtonSmall
              onClick={() => {
                navigate("/graph");
              }}
            >
              {t("Analyze")}
            </NormalButtonSmall>
          }
        >
          {isLoading || !isUndefined(datxToDownload) ? (
            <Row justify="center" align="middle" style={{ height: "200px" }}>
              <Text strong type="secondary">
                {t("OpeningFile")}
              </Text>
              <Spin style={{ paddingLeft: size.m1 }} />
            </Row>
          ) : (
            <List
              size="small"
              split={false}
              locale={{ emptyText: t("NoData") }}
              dataSource={recentFiles}
              renderItem={(item) => (
                <List.Item
                  key={item.fileName}
                  style={{ paddingTop: 0, paddingRight: 0, paddingLeft: 0 }}
                >
                  <Tooltip
                    placement="right"
                    title={() => (
                      <p style={{ marginBlock: 0 }}>
                        {item.fileName}
                        <br />
                        {t("LastInteraction")}:
                        {dayjs.unix(item.lastInteraction).format(" YYYY-MM-DD")}
                      </p>
                    )}
                  >
                    <Typography.Link
                      onClick={() => openRecentFile(item)}
                      className="selectableText"
                      style={{
                        ...ellipsisIfLongTextStyle
                      }}
                      disabled={isLoading || !isUndefined(datxToDownload)}
                    >
                      {item.fileName}
                    </Typography.Link>
                  </Tooltip>
                </List.Item>
              )}
            />
          )}
        </LandingCard>
      )}
    </>
  );
};

export default ReportLandingCard;
