import IDate from "./IDate";

/** System info for config version 4 or 5 */
export interface ISystemInfoCV {
  configVersion: 4 | 5;
  parxVersion: number;
  datxVersion: number;
  serial: number;
  hasFeatures: number;
  accStdFilter: number;
  accExtraFilter: number;
  accStdMaxG: number;
  accExtraMaxG: number;
  memoryAvailable: number;
  memoryUsed: number;
  artNum: string[];
  powerSource: number;
  mainBattery: number;
  backupBattery: number;
  runningStatus: number;
  lastCalibration: IDate;
  fwMajorVersion: number;
  fwMinorVersion: number;
  fwMainBuild: number;
  fwSubBuild: number;
}

export const expectedSystemInfoCVLength = 126;

/** Independant system info versioning starts here with version 2. Before this the system info version was connected to the config version. */
export interface ISystemInfoV2 {
  configVersion: number;
  parxVersion: number;
  datxVersion: number;
  serial: string;
  hasFeatures: number;
  accStdFilter: number;
  accExtraFilter: number;
  accStdMaxG: number;
  accExtraMaxG: number;
  memoryAvailable: number;
  memoryUsed: number;
  artNum: string[];
  powerSource: number;
  mainBatteryVoltage: number; // Added in system info version 2
  rebootTimeStamp: IDate; // Added in system info version 2
  mainBatteryTimeStamp: IDate; // Added in system info version 2
  mainBattery: number;
  backupBattery: number;
  runningStatus: number;
  lastCalibration: IDate;
  fwMajorVersion: number;
  fwMinorVersion: number;
  fwMainBuild: number;
  fwSubBuild: number;
}

/** The expected length for version 2 of SystemInfo. If it's more/less
 * it's most likely the wrong version of SystemInfo */
export const expectedSystemInfoV2Length = 156;

//* System info version 3 */
export interface ISystemInfoV3 {
  configVersion: number;
  parxVersion: number;
  datxVersion: number;
  serial: string;
  hasFeatures: number;
  accStdFilter: number;
  accExtraFilter: number;
  accStdMaxG: number;
  accExtraMaxG: number;
  memoryAvailable: number;
  memoryUsed: number;
  artNum: string[];
  powerSource: number;
  mainBatteryVoltage: number;
  rebootTimeStamp: IDate;
  mainBatteryTimeStamp: IDate;
  mainBattery: number;
  backupBattery: number;
  runningStatus: number;
  lastCalibration: IDate;
  fwMajorVersion: number;
  fwMinorVersion: number;
  fwMainBuild: number;
  fwSubBuild: number;
  serialProtVersion: number; // Added in system info version 3
  coFWMajorVersion: number; // Added in system info version 3
  coFWMinorVersion: number; // Added in system info version 3
  coFWMainBuild: number; // Added in system info version 3
  coFWSubBuild: number; // Added in system info version 3
}

/** The expected length for version 3 of SystemInfo. */
export const expectedSystemInfoV3Length = 162;

export interface ISystemInfoV4 extends ISystemInfoV3 {
  ExtSensors1: number; // First slot for available external sensors
  ExtSensors2: number; // Second slot for available external sensors
  ExtSensors3: number; // Third slot for available external sensors
  ExtSensors4: number; // Fourth slot for available external sensors
}

/** The expected length for version 4 of SystemInfo. */
export const expectedSystemInfoV4Length = 178;

export interface ISystemInfoV5 extends ISystemInfoV4 {
  dataVersion: number; // Added in system info version 5
}

export const expectedSystemInfoV5Length = 180;

export type GeneralSystemInfo =
  | ISystemInfoCV
  | ISystemInfoV2
  | ISystemInfoV3
  | ISystemInfoV4
  | ISystemInfoV5;

export const isSystemInfoV4 = (
  systemInfo: GeneralSystemInfo
): systemInfo is ISystemInfoV4 => {
  return (systemInfo as ISystemInfoV4).ExtSensors1 !== undefined;
};
