import { VMRecParams } from "./VMRecParams";
import { VMAccParams } from "./VMAccParams";
import { VMTempParams } from "./VMTempParams";
import { VMRhParams } from "./VMRhParams";
import { IProgInfo } from "../IProgInfo";
import VMAngleParams from "./VMAngleParams";
import { disabledLteParams, emptyLteParams, VMLteParams } from "./VMLteParams";
import { disabledGpsParams, emptyGpsParams, VMGpsParams } from "./VMGpsParams";
import { VMLteSchdeuleParams } from "./VMLteParams";
import { VMGpsScheduleParams } from "./VMGpsParams";
import { VMExternalOutputParams } from "./VMExternalOutputParams";
import { VMExternalInputParams } from "./VMExtternalInputParams";
import { VMPressureParams } from "./VMPressureParams";
import { VMExternalSensorParams } from "./VMExternalSensorParams";

export interface VMRecordingParameters {
  CfgVersion: number;
  ProgInfo: IProgInfo;
  LicenseKey?: number[];
  RecParams: VMRecParams;
  AccParams: VMAccParams;
  //   AccDVATrigLevel?: IAccDVATrigLevel;
  TempParams: VMTempParams;
  RhParams: VMRhParams;
  AngleParams: VMAngleParams;
  LteParams: VMLteParams;
  GpsParams: VMGpsParams;
  ExternalInputParams: VMExternalInputParams[];
  ExternalOutputParams: VMExternalOutputParams[];
  ExternalSensorParams: VMExternalSensorParams[];
  PressureParams: VMPressureParams;
  //   LightSensorParams?: ILightSensorParams;
  UserInfo?: string;
  ProjectName?: string;
  CompanyId?: string;
  ParameterId?: string;
  SetupVersion: number;
  scheduleBlocks: ScheduleBlock[];
}

export interface ScheduleBlock {
  blockDuration: number;
  flightMode: boolean;
  LteParams: VMLteSchdeuleParams;
  GpsParams: VMGpsScheduleParams;
}

export const emptyScheduleBlock: ScheduleBlock = {
  blockDuration: 8760, // 1 year
  flightMode: false,
  LteParams: emptyLteParams,
  GpsParams: emptyGpsParams
};

export const disabledScheduleBlock: ScheduleBlock = {
  blockDuration: 8760, // 1 year
  flightMode: false,
  LteParams: disabledLteParams,
  GpsParams: disabledGpsParams
};
/*

AccDVATrigLevel
PressureParams
External_Inputs
External_Outputs
LightSensorParams
GPS
LTE
ExternalSensor
AccFilterValues

*/
