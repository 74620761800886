import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LatLngExpression } from "leaflet";
import { DeviceData } from "../components/DevicesPage/DeviceMap";
import { Project } from "./cargologRestApi";
import { StoreApi } from "./store";

interface fleetType {
  unitId: number;
  coordinates: number[];
  path?: LatLngExpression[];
}

interface fileType {
  dataId: number;
  fileName: string;
  timestamp: number;
  fileSize: string;
  deviceId: number;
}

interface alarmType {
  timestamp: number;
  sensor: string;
}

export interface personType {
  name: string;
  email: string;
  role: string;
  license?: string;
}

export interface parameterType {
  parameterId: string;
  deviceName?: string;
  deviceDescription?: string;
  isActive?: boolean;
}

export interface tableDataType {
  project: Project;
  description?: string;
  distance?: number;
  time?: {
    timePercent?: number;
    status: number;
  };
  alarmsCount: number;
  filesCount: number;
  notesCount: number;
  deviceCount: number;
  people: number;
  status?: number;
  companyName?: string;
  latestUpload?: string;
}

export interface projectType {
  projectId: number;
  project: string;
  description: string;
  people?: personType[];
  units?: fleetType[];
  files?: fileType[];
  alarms?: alarmType[];
  distance?: number;
  time?: number;
}

export type ProjectModalTabs =
  | "overview"
  | "alarms"
  | "people"
  | "notes"
  | "status"
  | "recordings";
export type ViewType = "table" | "grid";

/** Main interface for this state-slice */
export interface ProjectState {
  isProjectModalOpen: boolean;
  isCreateProjectModalOpen: boolean;
  isProjectInvitesModalOpen: boolean;
  isEditProjectModalOpen: boolean;
  isAddInternalUserModalOpen: boolean;
  isInviteExternalUserModalOpen: boolean;
  isMoveDeviceModalOpen: boolean;
  isRecoverProjectModalOpen: boolean;
  isArchiveProjectModalOpen: boolean;

  selectedProjectId?: string;
  projectModalTab: ProjectModalTabs;
  viewMode: ViewType;
  parameterType?: parameterType;
  alarmsData?: DeviceData;
}

/** Initial state for this slice */
const initialState: ProjectState = {
  isProjectModalOpen: false,
  isCreateProjectModalOpen: false,
  isProjectInvitesModalOpen: false,
  isEditProjectModalOpen: false,
  isAddInternalUserModalOpen: false,
  isInviteExternalUserModalOpen: false,
  isMoveDeviceModalOpen: false,
  isRecoverProjectModalOpen: false,
  isArchiveProjectModalOpen: false,

  selectedProjectId: undefined,
  projectModalTab: "overview",
  viewMode: "grid",
  parameterType: undefined,
  alarmsData: undefined
};

export const slice = createSlice({
  name: "Projects",
  initialState,
  reducers: {
    closeAllProjectModals: (state) => {
      state.isProjectModalOpen = false;
      state.isCreateProjectModalOpen = false;
      state.isProjectInvitesModalOpen = false;
      state.isEditProjectModalOpen = false;
      state.isAddInternalUserModalOpen = false;
      state.isInviteExternalUserModalOpen = false;
      state.isMoveDeviceModalOpen = false;
      state.isRecoverProjectModalOpen = false;
      state.isArchiveProjectModalOpen = false;
      state.selectedProjectId = undefined;
    },

    resetProjectState: (state) => {
      Object.assign(state, initialState);
    },

    openProjectModal: (state) => {
      state.isProjectModalOpen = true;
    },
    closeProjectModal: (state) => {
      state.isProjectModalOpen = false;
    },

    openCreateProjectModal: (state) => {
      state.isCreateProjectModalOpen = true;
    },
    closeCreateProjectModal: (state) => {
      state.isCreateProjectModalOpen = false;
    },

    openProjectInvitesModal: (state) => {
      state.isProjectInvitesModalOpen = true;
    },
    closeProjectInvitesModal: (state) => {
      state.isProjectInvitesModalOpen = false;
    },

    openEditProjectModal: (state) => {
      state.isEditProjectModalOpen = true;
    },
    closeEditProjectModal: (state) => {
      state.isEditProjectModalOpen = false;
    },

    openAddInternalUserModal: (state) => {
      state.isAddInternalUserModalOpen = true;
    },
    closeAddInternalUserModal: (state) => {
      state.isAddInternalUserModalOpen = false;
    },

    openInviteExternalUserModal: (state) => {
      state.isInviteExternalUserModalOpen = true;
    },
    closeInviteExternalUserModal: (state) => {
      state.isInviteExternalUserModalOpen = false;
    },

    openMoveDeviceModal: (state) => {
      state.isMoveDeviceModalOpen = true;
    },
    closeMoveDeviceModal: (state) => {
      state.isMoveDeviceModalOpen = false;
    },

    openRecoverProjectModal: (state) => {
      state.isRecoverProjectModalOpen = true;
    },
    closeRecoverProjectModal: (state) => {
      state.isRecoverProjectModalOpen = false;
    },

    openArchiveProjectModal: (state) => {
      state.isArchiveProjectModalOpen = true;
    },
    closeArchiveProjectModal: (state) => {
      state.isArchiveProjectModalOpen = false;
    },

    setSelectedProjectId: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.selectedProjectId = action.payload;
    },

    resetProjectModalTab: (state) => {
      state.projectModalTab = "overview";
    },
    setProjectModalTab: (state, action: PayloadAction<ProjectModalTabs>) => {
      state.projectModalTab = action.payload;
    },

    setViewMode: (state, action: PayloadAction<ViewType>) => {
      state.viewMode = action.payload;
    },

    setParameterId: (state, action: PayloadAction<parameterType>) => {
      state.parameterType = action.payload;
    },

    setAlarmsData: (state, action: PayloadAction<DeviceData>) => {
      state.alarmsData = action.payload;
    }
  }
});

export const {
  closeAllProjectModals,
  resetProjectState,
  openProjectModal,
  closeProjectModal,
  openCreateProjectModal,
  closeCreateProjectModal,
  openProjectInvitesModal,
  closeProjectInvitesModal,
  openEditProjectModal,
  closeEditProjectModal,
  openAddInternalUserModal,
  closeAddInternalUserModal,
  openInviteExternalUserModal,
  closeInviteExternalUserModal,
  openMoveDeviceModal,
  closeMoveDeviceModal,
  openRecoverProjectModal,
  closeRecoverProjectModal,
  openArchiveProjectModal,
  closeArchiveProjectModal,
  setSelectedProjectId,
  setProjectModalTab,
  resetProjectModalTab,
  setViewMode,
  setParameterId,
  setAlarmsData
} = slice.actions;

export const projectsState = (state: StoreApi) => state.projects;

export default slice.reducer;
