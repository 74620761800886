import React from "react";
import {
  Row,
  Col,
  InputNumber,
  Switch,
  Typography,
  Popover,
  Tooltip,
  Space
} from "antd";
import {
  TempIcon,
  RhIcon,
  XAccIcon,
  YAccIcon,
  ZAccIcon,
  AngleIcon,
  ExtInputIcon,
  ExtOutputIcon,
  ExtTimerIcon,
  PressureTempIcon,
  PressureRawIcon,
  ExtTempIcon,
  ExtRhIcon,
  GpsIcon,
  HealthIcon,
  RecStatusIcon,
  LteIcon
} from "../../icons";
import { DataFilterChannels } from "../../models/DataFilterChannels";
import DataFilterToggle from "./DataFilterToggle";
import {
  DataFilterStates,
  selectGraphPageVm,
  setDynamicXAccDuration,
  setDynamicXAccMax,
  setDynamicXAccMin,
  setDynamicYAccDuration,
  setDynamicYAccMax,
  setDynamicYAccMin,
  setDynamicZAccDuration,
  setDynamicZAccMax,
  setDynamicZAccMin,
  setShowDynamicFilter,
  getShowDynamicFilter,
  selectDatxOptionalFiltersById,
  selectActiveDatxFile,
  switchZoomMode,
  redoZoomDomain,
  resetZoomDomain,
  removeCursorPosition,
  selectExternalSensorParamsById,
  selectAccParamsById,
  setDynamicAcc,
  AccDataFilterStates
} from "../../state/openDatxSlice";
import { CopyOutlined, UndoOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { size } from "../../helpers/pageHelper";
import { ControlOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { SmallText } from "../Common/CommonFonts";
import { useTranslation } from "react-i18next";
import GraphZoomButtons from "../GraphPage/GraphZoomButtons";
import { ZoomDimension } from "../../helpers/graphHelper";
import { MenuButton, NormalButton } from "../Common/CommonButtons";
import { getSensorNrFromId } from "../../helpers/paramsHelper";
import { externalRhColors, externalTempColors } from "../../constants/colors";

const { Text } = Typography;

interface IProps {
  fileId: string;
  zoomDimension: ZoomDimension;
  hasZoomed: boolean;
  shouldRenderNormalGraphs: boolean;
  activeFilters: DataFilterStates;
  toggleFilter: (target: DataFilterChannels) => void;
}

const DataFilterToggles: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { activeFileId } = useSelector(selectGraphPageVm);
  const filters = useSelector(selectDatxOptionalFiltersById(activeFileId!));
  const extSensorParams = useSelector(
    selectExternalSensorParamsById(activeFileId!)
  );
  const checked = useSelector(getShowDynamicFilter(activeFileId!));
  const accParams = useSelector(selectAccParamsById(activeFileId!));

  /**
   * If filter is turned on or off
   */
  const customFilterMainToggle = (showFilter: boolean) => {
    dispatch(
      setShowDynamicFilter({ fileId: activeFileId!, value: showFilter })
    );
  };

  const applyAlarmsFromParams = () => {
    const dynamicAcc: Partial<AccDataFilterStates> = {
      xAcc: {
        accMin: 0,
        accMax: accParams.params.Xalarm,
        accDuration: accParams.params.Xms
      },
      yAcc: {
        accMin: 0,
        accMax: accParams.params.Yalarm,
        accDuration: accParams.params.Yms
      },
      zAcc: {
        accMin: 0,
        accMax: accParams.params.Zalarm,
        accDuration: accParams.params.Zms
      }
    };

    dispatch(setDynamicAcc({ fileId: activeFileId!, value: dynamicAcc }));
  };

  const resetFilterValues = () => {
    const dynamicAcc: Partial<AccDataFilterStates> = {
      xAcc: {
        accMin: undefined,
        accMax: undefined,
        accDuration: undefined
      },
      yAcc: {
        accMin: undefined,
        accMax: undefined,
        accDuration: undefined
      },
      zAcc: {
        accMin: undefined,
        accMax: undefined,
        accDuration: undefined
      }
    };

    dispatch(setDynamicAcc({ fileId: activeFileId!, value: dynamicAcc }));
  };

  const content = (
    <>
      <Row justify="space-between" style={{ marginBottom: size.m1 }}>
        <Switch
          checked={checked}
          onChange={(value) => customFilterMainToggle(value)}
          style={{ marginTop: "auto", marginBottom: "auto" }}
        />
        <Tooltip
          placement="topLeft"
          title={<>{t("TheFilterIsUsingAbsoluteValues")}</>}
        >
          <SmallText
            type="secondary"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            <QuestionCircleOutlined />
          </SmallText>
        </Tooltip>
      </Row>
      <Row style={{ paddingBottom: size.m1 }}>
        <Col span={8}>
          <Text disabled={!checked}>X-Acc min:</Text>
          <br />
          <InputNumber
            value={filters.xAcc.accMin}
            min={0}
            max={filters.xAcc.accMax}
            style={{ width: 75 }}
            disabled={!checked}
            onChange={(e) =>
              dispatch(
                setDynamicXAccMin({
                  fileId: activeFileId!,
                  value: (e as number) || undefined
                })
              )
            }
          />
          <Text disabled={!checked}> g</Text>
        </Col>
        <Col span={8}>
          <Text disabled={!checked}>X-Acc max:</Text>
          <br />
          <InputNumber
            value={filters.xAcc.accMax}
            min={filters.xAcc.accMin ?? 0}
            style={{ width: 75 }}
            disabled={!checked}
            onChange={(e) =>
              dispatch(
                setDynamicXAccMax({
                  fileId: activeFileId!,
                  value: (e as number) || undefined
                })
              )
            }
          />
          <Text disabled={!checked}> g</Text>
        </Col>
        <Col span={8}>
          <Text disabled={!checked}>X-Acc {t("duration")}: </Text>
          <br />
          <InputNumber
            value={filters.xAcc.accDuration}
            min={0}
            //style={{ width: 75 }}
            disabled={!checked}
            onChange={(e) =>
              dispatch(
                setDynamicXAccDuration({
                  fileId: activeFileId!,
                  value: (e as number) || undefined
                })
              )
            }
          />
          <Text disabled={!checked}> ms</Text>
        </Col>
      </Row>
      <Row style={{ paddingBottom: size.m1 }}>
        <Col span={8}>
          <Text disabled={!checked}>Y-Acc min: </Text>
          <InputNumber
            value={filters.yAcc.accMin}
            min={0}
            max={filters.yAcc.accMax}
            style={{ width: 75 }}
            disabled={!checked}
            onChange={(e) =>
              dispatch(
                setDynamicYAccMin({
                  fileId: activeFileId!,
                  value: (e as number) || undefined
                })
              )
            }
          />
          <Text disabled={!checked}> g</Text>
        </Col>
        <Col span={8}>
          <Text disabled={!checked}>Y-Acc max:</Text>
          <br />
          <InputNumber
            value={filters.yAcc.accMax}
            min={filters.yAcc.accMin ?? 0}
            style={{ width: 75 }}
            disabled={!checked}
            onChange={(e) =>
              dispatch(
                setDynamicYAccMax({
                  fileId: activeFileId!,
                  value: (e as number) || undefined
                })
              )
            }
          />
          <Text disabled={!checked}> g</Text>
        </Col>
        <Col span={8}>
          <Text disabled={!checked}>Y-Acc {t("duration")}:</Text>
          <br />
          <InputNumber
            value={filters.yAcc.accDuration}
            min={0}
            //style={{ width: 75 }}
            disabled={!checked}
            onChange={(e) =>
              dispatch(
                setDynamicYAccDuration({
                  fileId: activeFileId!,
                  value: (e as number) || undefined
                })
              )
            }
          />
          <Text disabled={!checked}> ms</Text>
        </Col>
      </Row>
      <Row style={{ paddingBottom: size.l2 }}>
        <Col span={8}>
          <Text disabled={!checked}>Z-Acc min:</Text>
          <br />
          <InputNumber
            value={filters.zAcc.accMin}
            min={0}
            max={filters.zAcc.accMax}
            style={{ width: 75 }}
            disabled={!checked}
            onChange={(e) =>
              dispatch(
                setDynamicZAccMin({
                  fileId: activeFileId!,
                  value: (e as number) || undefined
                })
              )
            }
          />
          <Text disabled={!checked}> g</Text>
        </Col>
        <Col span={8}>
          <Text disabled={!checked}>Z-Acc max:</Text>
          <br />
          <InputNumber
            value={filters.zAcc.accMax}
            min={filters.zAcc.accMin ?? 0}
            style={{ width: 75 }}
            disabled={!checked}
            onChange={(e) =>
              dispatch(
                setDynamicZAccMax({
                  fileId: activeFileId!,
                  value: (e as number) || undefined
                })
              )
            }
          />
          <Text disabled={!checked}> g</Text>
        </Col>
        <Col span={8}>
          <Text disabled={!checked}>Z-Acc {t("duration")}:</Text>
          <br />
          <InputNumber
            value={filters.zAcc.accDuration}
            min={0}
            //style={{ width: 75 }}
            disabled={!checked}
            onChange={(e) =>
              dispatch(
                setDynamicZAccDuration({
                  fileId: activeFileId!,
                  value: (e as number) || undefined
                })
              )
            }
          />
          <Text disabled={!checked}> ms</Text>
        </Col>
      </Row>
      <Row justify={"space-between"}>
        <NormalButton
          disabled={!checked}
          icon={<CopyOutlined />}
          onClick={() => applyAlarmsFromParams()}
        >
          {t("UseParameterAlarms")}
        </NormalButton>
        <NormalButton
          disabled={!checked}
          icon={<UndoOutlined />}
          onClick={() => resetFilterValues()}
        >
          {t("ResetValues")}
        </NormalButton>
      </Row>
    </>
  );

  const activeFile = useSelector(selectActiveDatxFile);
  /** Used as a check for changing different style attributes depending on if
   * Score Values is visible or hidden */
  const isCursorPosSelected =
    activeFile?.scoreValuesCursorPosPrimaryGraph ?? false;

  const hasXAcc = props.activeFilters.xAcc.dataToggle.isUsed;
  const hasYAcc = props.activeFilters.yAcc.dataToggle.isUsed;
  const hasZAcc = props.activeFilters.zAcc.dataToggle.isUsed;
  const hasTemp = props.activeFilters.temp.dataToggle.isUsed;
  const hasRh = props.activeFilters.rh.dataToggle.isUsed;
  const hasPressureRaw = props.activeFilters.pressureRaw.dataToggle.isUsed;
  const hasPressureComp = props.activeFilters.pressureComp.dataToggle.isUsed;
  const hasAngle = props.activeFilters.angle.dataToggle.isUsed;
  const hasDeviceHealth = props.activeFilters.deviceHealth.isUsed;
  const hasLte = props.activeFilters.lteStatus.dataToggle.isUsed;
  const hasGps = props.activeFilters.gps.dataToggle.isUsed;
  const hasExtInput = props.activeFilters.extInput.dataToggle.isUsed;
  const hasExtOutput = props.activeFilters.extOutput.dataToggle.isUsed;
  const hasExtTimer = props.activeFilters.extTimer.dataToggle.isUsed;
  const hasRunningStatus = props.activeFilters.runningStatus.isUsed;

  return (
    <Row justify="space-between" style={{ padding: size.s2 }}>
      <Col>
        <Row gutter={[size.s2, size.m2]} style={{ display: "flex" }}>
          {hasXAcc && (
            <Col>
              <DataFilterToggle
                icon={XAccIcon}
                state={props.activeFilters.xAcc.dataToggle}
                onClick={() => props.toggleFilter("xAcc")}
                tooltip={t("xAcc_tooltip")}
              />
            </Col>
          )}
          {hasYAcc && (
            <Col>
              <DataFilterToggle
                icon={YAccIcon}
                state={props.activeFilters.yAcc.dataToggle}
                onClick={() => props.toggleFilter("yAcc")}
                tooltip={t("yAcc_tooltip")}
              />
            </Col>
          )}
          {hasZAcc && (
            <Col>
              <DataFilterToggle
                icon={ZAccIcon}
                state={props.activeFilters.zAcc.dataToggle}
                onClick={() => props.toggleFilter("zAcc")}
                tooltip={t("zAcc_tooltip")}
              />
            </Col>
          )}
          {hasTemp && (
            <Col>
              <DataFilterToggle
                icon={TempIcon}
                state={props.activeFilters.temp.dataToggle}
                onClick={() => props.toggleFilter("temp")}
                tooltip={t("temp_tooltip")}
              />
            </Col>
          )}
          {hasRh && (
            <Col>
              <DataFilterToggle
                icon={RhIcon}
                state={props.activeFilters.rh.dataToggle}
                onClick={() => props.toggleFilter("rh")}
                tooltip={t("rh_tooltip")}
              />
            </Col>
          )}
          {hasPressureRaw && (
            <Col>
              <DataFilterToggle
                icon={PressureRawIcon}
                state={props.activeFilters.pressureRaw.dataToggle}
                onClick={() => props.toggleFilter("pressureRaw")}
                tooltip={t("pressureRaw_tooltip")}
              />
            </Col>
          )}
          {hasPressureComp && (
            <Col>
              <DataFilterToggle
                icon={PressureTempIcon}
                state={props.activeFilters.pressureComp.dataToggle}
                onClick={() => props.toggleFilter("pressureComp")}
                tooltip={t("pressureComp_tooltip")}
              />
            </Col>
          )}
          {hasAngle && (
            <Col>
              <DataFilterToggle
                icon={AngleIcon}
                state={props.activeFilters.angle.dataToggle}
                onClick={() => props.toggleFilter("angle")}
                tooltip={t("angle_tooltip")}
              />
            </Col>
          )}
          {hasDeviceHealth && (
            <Col>
              <DataFilterToggle
                icon={HealthIcon}
                state={props.activeFilters.deviceHealth}
                onClick={() => props.toggleFilter("deviceHealth")}
                tooltip={t("deviceHealth_tooltip")}
              />
            </Col>
          )}
          {hasLte && (
            <Col>
              <DataFilterToggle
                icon={LteIcon}
                state={props.activeFilters.lteStatus.dataToggle}
                onClick={() => props.toggleFilter("lteStatus")}
                tooltip={t("lte_tooltip")}
              />
            </Col>
          )}
          {hasGps && (
            <Col>
              <DataFilterToggle
                icon={GpsIcon}
                state={props.activeFilters.gps.dataToggle}
                onClick={() => props.toggleFilter("gps")}
                tooltip={t("gps_tooltip")}
              />
            </Col>
          )}
          {hasExtInput && (
            <Col>
              <DataFilterToggle
                icon={ExtInputIcon}
                state={props.activeFilters.extInput.dataToggle}
                onClick={() => props.toggleFilter("extInput")}
                tooltip={t("extInput_tooltip")}
              />
            </Col>
          )}
          {hasExtOutput && (
            <Col>
              <DataFilterToggle
                icon={ExtOutputIcon}
                state={props.activeFilters.extOutput.dataToggle}
                onClick={() => props.toggleFilter("extOutput")}
                tooltip={t("extOutput_tooltip")}
              />
            </Col>
          )}
          {hasExtTimer && (
            <Col>
              <DataFilterToggle
                icon={ExtTimerIcon}
                state={props.activeFilters.extTimer.dataToggle}
                onClick={() => props.toggleFilter("extTimer")}
                tooltip={t("extTimer_tooltip")}
              />
            </Col>
          )}
          {Object.keys(props.activeFilters.extTemp).map((key, index) => {
            const extTemp = props.activeFilters.extTemp[key];
            if (extTemp && extTemp.dataToggle.isUsed) {
              let sensorName =
                t("ExternalTemperature") +
                " nr " +
                getSensorNrFromId(parseInt(key));
              const sensor = extSensorParams.find(
                (sensor) => sensor.params.sensorTypeId.toString() === key
              );
              if (sensor) {
                sensorName += " (" + sensor.params.sensorName + ")";
              }
              return (
                <Col key={index}>
                  <DataFilterToggle
                    icon={ExtTempIcon}
                    state={extTemp.dataToggle}
                    onClick={() => props.toggleFilter("extTemp_" + key)}
                    tooltip={sensorName}
                    activeChannelColor={externalTempColors[index % 4]}
                  />
                </Col>
              );
            } else {
              return null;
            }
          })}
          {Object.keys(props.activeFilters.extRh).map((key, index) => {
            const extRh = props.activeFilters.extRh[key];
            if (extRh.dataToggle.isUsed) {
              let sensorName =
                t("ExternalHumidity") +
                " nr " +
                getSensorNrFromId(parseInt(key));
              const sensor = extSensorParams.find(
                (sensor) => sensor.params.sensorTypeId.toString() === key
              );
              if (sensor) {
                sensorName += " (" + sensor.params.sensorName + ")";
              }
              return (
                <Col key={index}>
                  <DataFilterToggle
                    icon={ExtRhIcon}
                    state={extRh.dataToggle}
                    onClick={() => props.toggleFilter("extRh_" + key)}
                    tooltip={sensorName}
                    activeChannelColor={externalRhColors[index % 4]}
                  />
                </Col>
              );
            } else {
              return null;
            }
          })}
          {hasRunningStatus && (
            <Col>
              <DataFilterToggle
                icon={RecStatusIcon}
                state={props.activeFilters.runningStatus}
                onClick={() => props.toggleFilter("runningStatus")}
                tooltip={t("runningStatus_tooltip")}
              />
            </Col>
          )}
        </Row>
      </Col>

      <Col>
        <Space>
          {props.shouldRenderNormalGraphs && (
            <GraphZoomButtons
              switchZoomMode={() =>
                dispatch(switchZoomMode({ fileId: props.fileId }))
              }
              zoomOut={() => dispatch(redoZoomDomain({ fileId: props.fileId }))}
              resetZoom={() =>
                dispatch(resetZoomDomain({ fileId: props.fileId }))
              }
              removeCursorPos={() => dispatch(removeCursorPosition())}
              zoomDimension={props.zoomDimension}
              canUseZoomOut={props.hasZoomed}
              isCursorPosSelected={isCursorPosSelected}
            />
          )}

          <Popover placement="bottom" content={content} trigger="click">
            <MenuButton
              icon={
                <ControlOutlined
                  style={{ color: checked ? "green" : undefined }}
                />
              }
              style={{
                height: 32,
                paddingInline: size.s2,
                borderColor: checked ? "green" : undefined,
                color: checked ? "green" : undefined
              }}
            >
              {t("Filter")}
            </MenuButton>
          </Popover>
        </Space>
      </Col>
    </Row>
  );
};

export default DataFilterToggles;
