import { GeneralSystemInfo, ISystemInfoV5 } from "../models/ISystemInfo";

const defaltTimestamp = {
  year: 0,
  month: 0,
  day: 0,
  hour: 0,
  minute: 0,
  second: 0
};

/** Function that converts the combined GeneralSystemInfo type to SystemInfo, which is the format of the API */
export const generalSysInfoToSyncable = (
  generalSystemInfo: GeneralSystemInfo
): ISystemInfoV5 => {
  // If no configVersion is found, config version 1 is assumed
  const configVersion =
    "configVersion" in generalSystemInfo ? generalSystemInfo.configVersion : 1;
  const mainBatteryVoltage =
    "mainBatteryVoltage" in generalSystemInfo
      ? generalSystemInfo.mainBatteryVoltage
      : 0;

  let rebootTimeStamp = defaltTimestamp;
  if ("rebootTimeStamp" in generalSystemInfo) {
    rebootTimeStamp = generalSystemInfo.rebootTimeStamp;
  }

  let mainBatteryTimeStamp = defaltTimestamp;
  if ("mainBatteryTimeStamp" in generalSystemInfo) {
    mainBatteryTimeStamp = generalSystemInfo.mainBatteryTimeStamp;
  }

  const serialProtVersion =
    "serialProtVersion" in generalSystemInfo
      ? generalSystemInfo.serialProtVersion
      : 0;

  const coFWMajorVersion =
    "coFWMajorVersion" in generalSystemInfo
      ? generalSystemInfo.coFWMajorVersion
      : 0;
  const coFWMinorVersion =
    "coFWMinorVersion" in generalSystemInfo
      ? generalSystemInfo.coFWMinorVersion
      : 0;
  const coFWMainBuild =
    "coFWMainBuild" in generalSystemInfo ? generalSystemInfo.coFWMainBuild : 0;
  const coFWSubBuild =
    "coFWSubBuild" in generalSystemInfo ? generalSystemInfo.coFWSubBuild : 0;

  const ExtSensors1 =
    "ExtSensors1" in generalSystemInfo ? generalSystemInfo.ExtSensors1 : 0;
  const ExtSensors2 =
    "ExtSensors2" in generalSystemInfo ? generalSystemInfo.ExtSensors2 : 0;
  const ExtSensors3 =
    "ExtSensors3" in generalSystemInfo ? generalSystemInfo.ExtSensors3 : 0;
  const ExtSensors4 =
    "ExtSensors4" in generalSystemInfo ? generalSystemInfo.ExtSensors4 : 0;

  // If dataVersion is not found, datxVersion is set instead
  const dataVersion =
    "dataVersion" in generalSystemInfo && generalSystemInfo.dataVersion !== 0
      ? generalSystemInfo.dataVersion
      : 0;

  const syncableSysInfo: ISystemInfoV5 = {
    serial: generalSystemInfo.serial.toString(),
    configVersion: configVersion,
    parxVersion: generalSystemInfo.parxVersion,
    datxVersion: generalSystemInfo.datxVersion,
    hasFeatures: generalSystemInfo.hasFeatures,
    accStdFilter: generalSystemInfo.accStdFilter,
    accExtraFilter: generalSystemInfo.accExtraFilter,
    accStdMaxG: generalSystemInfo.accStdMaxG,
    accExtraMaxG: generalSystemInfo.accExtraMaxG,
    memoryAvailable: generalSystemInfo.memoryAvailable,
    memoryUsed: generalSystemInfo.memoryUsed,
    artNum: generalSystemInfo.artNum,
    powerSource: generalSystemInfo.powerSource,
    mainBatteryVoltage: mainBatteryVoltage,
    rebootTimeStamp: rebootTimeStamp,
    mainBatteryTimeStamp: mainBatteryTimeStamp,
    mainBattery: generalSystemInfo.mainBattery,
    backupBattery: generalSystemInfo.backupBattery,
    runningStatus: generalSystemInfo.runningStatus,
    lastCalibration: generalSystemInfo.lastCalibration,
    fwMajorVersion: generalSystemInfo.fwMajorVersion,
    fwMinorVersion: generalSystemInfo.fwMinorVersion,
    fwMainBuild: generalSystemInfo.fwMainBuild,
    fwSubBuild: generalSystemInfo.fwSubBuild,
    serialProtVersion: serialProtVersion,
    coFWMajorVersion: coFWMajorVersion,
    coFWMinorVersion: coFWMinorVersion,
    coFWMainBuild: coFWMainBuild,
    coFWSubBuild: coFWSubBuild,
    ExtSensors1: ExtSensors1,
    ExtSensors2: ExtSensors2,
    ExtSensors3: ExtSensors3,
    ExtSensors4: ExtSensors4,
    dataVersion: dataVersion
  };

  return syncableSysInfo;
};
