import * as React from "react";
import type { SVGProps } from "react";
const SvgHealthIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 141.73 141.73"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M92.8 34.3 70.7 87 48.1 48.3l-8 20.4H13.3v9h32.9l3.4-8.8 22.3 38.2L93.3 56l10.5 21.6h24.3v-9h-18.6z"
      fill={props.fill === undefined ? "#a70f0d" : props.fill}
    />
    <path
      d="M99.8 5.2C89.7 4.8 79.3 9 71 18.5 59.6 5.3 43.8 2.4 30.9 7.7 16.5 13.6 5.4 28.8 5.4 48c-.1 34.9 16.1 57.8 32.1 71.6s32.2 18.8 32.2 18.8l1.3.4 1.3-.4s16.1-5.1 32.1-18.8c16-13.8 32-36.7 32.1-71.6 0-19.2-11-34.4-25.4-40.3-3.6-1.5-7.4-2.3-11.3-2.5m-.5 8.8c2.8.2 5.7.9 8.4 2 10.8 4.4 19.8 16.2 19.8 32 0 32.1-14.4 52.2-28.9 64.8-12.6 10.8-24.4 15.5-27.5 16.6-3.2-1.1-15.1-5.7-27.6-16.6-14.6-12.5-29-32.6-28.9-64.7 0-15.8 9-27.6 19.9-32s23.4-2.2 32.9 12.3l3.8 5.7 3.8-5.7c7.2-10.9 16-14.8 24.5-14.3z"
      fill={props.fill === undefined ? "#ee2624" : props.fill}
    />
  </svg>
);
export default SvgHealthIcon;
