import * as React from "react";
import type { SVGProps } from "react";
const SvgLteIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024.000000 1024.000000"
    width="1024.000000pt"
    height="1024.000000pt"
    preserveAspectRatio="xMidYMid meet"
    version="1.0"
    {...props}
  >
    <g
      transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M4840 10234 c-30 -2 -131 -8 -225 -14 -360 -24 -847 -103 -1175 -192
-948 -257 -1751 -659 -2485 -1243 -120 -96 -359 -301 -399 -343 l-28 -30 218
-249 c475 -540 576 -653 584 -653 5 0 50 39 102 86 617 571 1383 996 2213
1229 769 216 1627 257 2445 115 665 -115 1388 -397 1965 -768 276 -178 595
-424 786 -610 32 -30 61 -53 67 -51 6 2 52 52 104 110 233 264 650 739 669
763 l22 27 -34 36 c-19 19 -97 90 -174 157 -1063 928 -2401 1493 -3809 1611
-171 15 -727 27 -846 19z"
        fill={props.fill === undefined ? "#4faadf" : props.fill}
      />
      <path
        d="M4807 8409 c-945 -64 -1839 -391 -2583 -947 -187 -139 -484 -394
-484 -416 0 -11 83 -108 385 -451 77 -87 195 -222 263 -299 67 -78 129 -145
137 -151 10 -7 33 10 92 65 551 525 1268 866 2033 966 186 24 622 29 830 10
782 -75 1508 -400 2108 -945 61 -56 117 -99 122 -96 10 6 123 133 400 450 63
72 177 202 254 289 l138 158 -33 35 c-91 94 -379 331 -572 470 -332 238 -866
503 -1287 638 -571 183 -1220 264 -1803 224z"
        fill={props.fill === undefined ? "#4faadf" : props.fill}
      />
      <path
        d="M4935 6595 c-11 -2 -69 -9 -130 -15 -529 -55 -1016 -239 -1456 -552
-105 -74 -285 -226 -363 -306 l-49 -51 144 -163 c211 -240 652 -742 656 -747
2 -2 49 41 106 97 344 341 786 522 1270 522 496 0 934 -180 1284 -527 92 -91
96 -94 113 -76 97 100 780 888 780 898 0 15 -205 202 -315 288 -415 322 -925
535 -1455 607 -141 20 -525 36 -585 25z"
        fill={props.fill === undefined ? "#4faadf" : props.fill}
      />
      <path
        d="M820 1770 l0 -1770 1215 0 1215 0 0 305 0 305 -850 0 -850 0 0 1465
0 1465 -365 0 -365 0 0 -1770z"
        fill={props.fill === undefined ? "#4faadf" : props.fill}
      />
      <path
        d="M3590 3235 l0 -305 535 0 535 0 0 -1465 0 -1465 365 0 365 0 0 1465
0 1465 515 0 515 0 0 305 0 305 -1415 0 -1415 0 0 -305z"
        fill={props.fill === undefined ? "#4faadf" : props.fill}
      />
      <path
        d="M6960 1770 l0 -1770 1320 0 1320 0 0 305 0 305 -955 0 -955 0 0 455
0 455 845 0 845 0 0 305 0 305 -845 0 -845 0 0 400 0 400 915 0 915 0 0 305 0
305 -1280 0 -1280 0 0 -1770z"
        fill={props.fill === undefined ? "#4faadf" : props.fill}
      />
    </g>
  </svg>
);
export default SvgLteIcon;
