import { VerticalAlignBottomOutlined } from "@ant-design/icons";
import { Badge, Card, Tooltip } from "antd";
import { isNil, isEmpty } from "lodash-es";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  FlyoutProps,
  VictoryGroup,
  VictoryScatter,
  VictoryScatterProps,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer
} from "victory";
import { EventPropTypeInterface } from "victory-core";
import { iconColors } from "../../constants/colors";
import { DeviceHealth } from "../../helpers/datasetHelper";
import { dateToUnix } from "../../helpers/dateHelper";
import {
  commonPrimaryGraphProps,
  getCanvasOffset,
  isItemWithinDomain
} from "../../helpers/graphHelper";
import { RunningStatusTypes } from "../../models/DTTypes";
import { VMRecordingParameters } from "../../models/ViewModelRecordingParameters/VMRecordingParameters";
import {
  DataFilterStates,
  setNewCursorPosition
} from "../../state/openDatxSlice";
import { Optional } from "../../utils/utilTypes";

export interface StatusGraphData {
  recordingParameters?: VMRecordingParameters;
  dataFilters: DataFilterStates;
  angleData?: AngleGraphPoint[];
  startupData?: ExtraStatusSymbol[];
  runningStatusData?: ExtraStatusSymbol[];
  deviceHealthData?: DeviceHealthItem[];
}

/** Data-type for graph */
export interface AngleGraphPoint {
  x: Date;
  y: number;
  data: {
    xAngle: number;
    yAngle: number;
    zAngle: number;
  };
  /** Used when 1 data point describes more than 1 value */
  occurrences?: number;
  /** If one or more values is above alarm level... */
  isWarning: boolean;
}

/** Extra symbols in graph (right now restart and btn-press) */
export interface ExtraStatusSymbol {
  type: "startup" | RunningStatusTypes;
  x: Date;
  y: number;
}

export interface DeviceHealthItem {
  x: Date;
  y: number;
  data: DeviceHealth;
}

/** Mini interface that can be combined with Victory component props to get
 * correct datum type when angle data is expected */
interface TypedAngleDatumProps {
  datum?: AngleGraphPoint;
  graphData: StatusGraphData;
}

/** Mini interface that can be combined with Victory component props to get
 * correct datum type for tooltip datum */
interface TypedTooltipDatumProps {
  datum?: AngleGraphPoint | DeviceHealth;
  graphData: StatusGraphData;
}

const setHeight = (data: StatusGraphData) => {
  const dotHeightConst = 12;
  const angleHeightConst = 34;

  const angleHeight = data.dataFilters.angle.dataToggle.isActive
    ? angleHeightConst
    : 0;
  const runningStatusHeight = data.dataFilters.runningStatus.isActive
    ? dotHeightConst
    : 0;
  const deviceHealthHeight = data.dataFilters.deviceHealth.isActive
    ? dotHeightConst
    : 0;
  const startupHeight = !isEmpty(data.startupData) ? dotHeightConst : 0;

  const totalHeight =
    angleHeight + runningStatusHeight + deviceHealthHeight + startupHeight;

  return totalHeight < 36 ? dotHeightConst * 3 : totalHeight;
};

const setDomain = (data: StatusGraphData): [number, number] => {
  const angleDomain = data.dataFilters.angle.dataToggle.isActive ? 5 : 0;
  const runningStatusDomain = data.dataFilters.runningStatus.isActive ? 2 : 0;
  const deviceHealthDomain = data.dataFilters.deviceHealth.isActive ? 2 : 0;
  const startupDomain = !isEmpty(data.startupData) ? 2 : 0;

  const totalDomain =
    angleDomain + runningStatusDomain + deviceHealthDomain + startupDomain;

  return [-1, totalDomain - 1];
};

/** Padding for angle data point icon */
const angleDataPointWidthPadding = 8;

/** Determine if obj is of type AngleGraphPoint*/
const isAngleGraphPoint = (obj: any): obj is AngleGraphPoint => {
  const maybeAngleGraphPoint = obj as AngleGraphPoint;

  return (
    !isNil(maybeAngleGraphPoint.data) &&
    !isNil(maybeAngleGraphPoint.isWarning) &&
    !isNil(maybeAngleGraphPoint.x) &&
    !isNil(maybeAngleGraphPoint.y)
  );
};

/** Determine if obj is of type DeviceHealth*/
const isDeviceHealthItem = (obj: any): obj is DeviceHealthItem => {
  const maybeAngleGraphPoint = obj as DeviceHealthItem;

  return (
    !isNil(maybeAngleGraphPoint.data) &&
    !isNil(maybeAngleGraphPoint.data.memoryUsed) &&
    !isNil(maybeAngleGraphPoint.data.mainBatteryStatus) &&
    !isNil(maybeAngleGraphPoint.data.backupBatteryStatus) &&
    !isNil(maybeAngleGraphPoint.x) &&
    !isNil(maybeAngleGraphPoint.y)
  );
};

/** Determine if obj is of a certain type */
const isOfType = (obj: any, type: string): obj is ExtraStatusSymbol => {
  const maybeType = obj as ExtraStatusSymbol;
  return (
    !isNil(maybeType.type) &&
    maybeType.type === type &&
    !isNil(maybeType.x) &&
    !isNil(maybeType.y)
  );
};
/** Local component used to render an angle data point in graph */
const RenderAngleDataPoint: React.FC<
  VictoryScatterProps & TypedAngleDatumProps
> = (props) => {
  const { x, y, datum, graphData } = props;

  //if any of the used values from props is undefined, we can't continue
  if (isNil(x) || isNil(y) || isNil(datum) || isNil(datum.data)) {
    return null;
  }
  const { occurrences, isWarning } = datum;
  const { xAngle, yAngle, zAngle } = datum.data;
  let maxAngle = 0;
  if (Math.abs(xAngle) > Math.abs(maxAngle)) maxAngle = xAngle;
  if (Math.abs(yAngle) > Math.abs(maxAngle)) maxAngle = yAngle;
  if (Math.abs(zAngle) > Math.abs(maxAngle)) maxAngle = zAngle;

  const badgeColor = isWarning ? "red" : "#D1D100";

  /** slighly wider icons to fit badge with occurrences */
  const width = 50 + angleDataPointWidthPadding;
  const height = setHeight(graphData);

  const midX = Number(x) - 25;

  return (
    <g style={{ pointerEvents: "none" }} x={midX} y={height}>
      <foreignObject x={midX} y={Number(y)} width={width} height={height}>
        <Badge
          count={occurrences ?? 0}
          style={{ backgroundColor: badgeColor }}
          size="small"
          offset={[8, 8]}
        >
          <VerticalAlignBottomOutlined
            rotate={maxAngle}
            style={{
              fontSize: "2em",
              color: iconColors.angle
            }}
          />
        </Badge>
      </foreignObject>
    </g>
  );
};

/** Local component used to render graph tooltips */
const RenderTooltip: React.FC<FlyoutProps & TypedTooltipDatumProps> = (
  props
) => {
  const { x, y, datum, graphData } = props;
  const { t } = useTranslation();

  const compHeight = setHeight(graphData);

  //if any of the used values from props is undefined, we can't continue
  if (isNil(x) || isNil(y) || isNil(datum)) {
    return null;
  }

  if (isAngleGraphPoint(datum)) {
    return renderAngleTooltip(datum, x, y, compHeight);
  }

  if (isDeviceHealthItem(datum)) {
    return renderDeviceHealthTooltip(
      datum,
      x,
      y,
      t("MemoryUsed"),
      t("MainBattery"),
      t("BackupBattery"),
      compHeight
    );
  }

  if (isOfType(datum, "startup")) {
    return renderGenericTooltip(x, y, t("DeviceReset"), compHeight);
  }
  if (isOfType(datum, "btnPress")) {
    return renderGenericTooltip(x, y, t("ButtonPressed"), compHeight);
  }
  if (isOfType(datum, "tampering")) {
    return renderGenericTooltip(x, y, t("TamperingDetected"), compHeight);
  }
  if (isOfType(datum, "recStart")) {
    return renderGenericTooltip(x, y, t("RecordingStarted"), compHeight);
  }
  if (isOfType(datum, "recEndNormal")) {
    return renderGenericTooltip(x, y, t("RecordingEnded"), compHeight);
  }
  if (isOfType(datum, "recEndMemoryFull")) {
    return renderGenericTooltip(x, y, t("MemoryFull"), compHeight);
  }
  if (isOfType(datum, "recEndWriteError")) {
    return renderGenericTooltip(x, y, t("WriteError"), compHeight);
  }

  return null;
};

/** Render angle data tooltip as a component */
const renderAngleTooltip = (
  datum: AngleGraphPoint,
  x: number,
  y: number,
  compHeight: number
) => {
  const { xAngle, yAngle, zAngle } = datum.data;

  return (
    <g style={{ pointerEvents: "none" }} y={0} x={x}>
      <foreignObject x={x} y={y} width={compHeight} height={compHeight}>
        <Tooltip
          getPopupContainer={(triggerNode: HTMLElement) =>
            document.getElementById("primarygraph") ||
            (triggerNode.parentNode as HTMLElement)
          }
          placement="bottom"
          align={{ offset: [0, 35] }}
          open={true}
          title={
            <>
              x: {xAngle}°
              <br />
              y: {yAngle}°
              <br />
              z: {zAngle}°
            </>
          }
        />
      </foreignObject>
    </g>
  );
};

const renderDeviceHealthTooltip = (
  datum: DeviceHealthItem,
  x: number,
  y: number,
  memoryUsedTranslation: string,
  mainBatteryTranslation: string,
  backupBatteryTranslation: string,
  compHeight: number
) => {
  const { memoryUsed, mainBatteryStatus, backupBatteryStatus } = datum.data;
  const midX = x;

  return (
    <g style={{ pointerEvents: "none" }} y={0} x={x}>
      <foreignObject x={midX} y={y} width={compHeight} height={compHeight}>
        <Tooltip
          getPopupContainer={(triggerNode: HTMLElement) =>
            document.getElementById("primarygraph") ||
            (triggerNode.parentNode as HTMLElement)
          }
          placement="bottom"
          open={true}
          title={
            <>
              {memoryUsedTranslation}: {memoryUsed}b
              <br />
              {mainBatteryTranslation}: {mainBatteryStatus}%
              <br />
              {backupBatteryTranslation}: {backupBatteryStatus}%
            </>
          }
        />
      </foreignObject>
    </g>
  );
};

const renderGenericTooltip = (
  x: number,
  y: number,
  event: string,
  compHeight: number
) => {
  return (
    <g style={{ pointerEvents: "none" }} y={0} x={x}>
      <foreignObject x={x} y={y} width={compHeight} height={compHeight}>
        <Tooltip
          getPopupContainer={(triggerNode: HTMLElement) =>
            document.getElementById("primarygraph") ||
            (triggerNode.parentNode as HTMLElement)
          }
          placement="bottom"
          open={true}
          title={event}
        />
      </foreignObject>
    </g>
  );
};

/**
 * Helper function that makes sure that angle data don't overlap in graph and
 * don't include data points outside the domain
 * @param data
 * @param domain
 * @param maxDataPoints Max amount of data points in the returned array
 */
export const prepareAngleDataForGraph = (
  data: Optional<AngleGraphPoint[]>,
  domain: [Date, Date],
  maxDataPoints: number
) => {
  if (isNil(data)) {
    return undefined;
  }

  /** total amount of seconds for domain */
  const diff = (domain[1].getTime() - domain[0].getTime()) / 1e3;

  /** how many seconds space 1 data unit takes */
  const unit = diff / maxDataPoints;

  return data.reduce((arr: AngleGraphPoint[], item, index) => {
    //outside of domain
    if (!isItemWithinDomain(item, domain)) {
      return arr;
    }

    /** Previously added value */
    const prev = arr.pop();

    if (isNil(prev)) {
      return [item];
    }

    /** amount of seconds between 2 data points */

    const innerDiff = dateToUnix(item.x) - dateToUnix(prev.x);

    /** if 2 angles is too close group them together */
    if (innerDiff < unit / 2) {
      const highest =
        Math.abs(prev.data.zAngle) > Math.abs(item.data.zAngle) ? prev : item;

      /** this data point now includes more than one */
      const occurrences = prev?.occurrences ? prev.occurrences + 1 : 2;
      /** If one is warning, it counts as a warning */
      const isWarning = prev.isWarning || item.isWarning ? true : false;

      arr.push({ ...highest, occurrences, isWarning });
    } else {
      arr.push(prev, item);
    }

    return arr;
  }, []);
};

interface IProps {
  data: StatusGraphData;
  zoomDomain: { x: [Date, Date] };
  width: number;
  height: number;
}
const StatusGraph: React.FC<IProps> = (props) => {
  const { data, zoomDomain } = props;
  const dispatch = useDispatch();

  /** Max ammount of data points in the graph */
  const maxDataPoints = props.width > 1500 ? 20 : props.height > 1000 ? 15 : 8;

  // Data to render in graph

  const angleData = !data.dataFilters.angle.dataToggle.isActive
    ? []
    : prepareAngleDataForGraph(data.angleData, zoomDomain.x, maxDataPoints);

  const { runningStatusData, startupData, deviceHealthData, dataFilters } =
    data;

  const cleanRunningStatusData = (data: ExtraStatusSymbol[]) => {
    const firstRecStart = data.find((item) => item.type === "recStart");

    const filteredData = data.filter((item) => item.type !== "recStart");

    if (firstRecStart) {
      filteredData.push(firstRecStart);
    }

    return filteredData.sort((a, b) => a.x.getTime() - b.x.getTime());
  };

  const runningStatusCleaned = runningStatusData
    ? cleanRunningStatusData(runningStatusData)
    : [];

  //Variables determining which data the graph should render
  const shouldRenderAngleData = !isEmpty(angleData);
  const shouldRenderRunningStatusData =
    !isEmpty(runningStatusData) && dataFilters.runningStatus.isActive;
  const shouldRenderStartupData = !isEmpty(startupData);
  const shouldRenderDeviceHealthData =
    !isEmpty(deviceHealthData) && dataFilters.deviceHealth.isActive;

  const leftOffset = getCanvasOffset(props.data.dataFilters);

  const statusEvents: EventPropTypeInterface<"parent", ""> = {
    target: "parent",
    eventHandlers: {
      onMouseDown: () => ({
        target: "parent",
        mutation: (targetProps) => {
          const { activePoints } = targetProps;
          if (activePoints.length > 0) {
            dispatch(setNewCursorPosition(dateToUnix(activePoints[0].x)));
          }
        }
      })
    }
  };
  return (
    <Card
      style={{
        width: "100%",
        height: `${setHeight(data)}px`,
        padding: "0px 0px 0px 0px",
        boxShadow: "rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset"
      }}
      styles={{
        body: {
          padding: "0 0 0 0",
          height: "100%",
          marginLeft: `${leftOffset - commonPrimaryGraphProps.chartPadding.right}px`
        }
      }}
    >
      <VictoryGroup
        standalone={true}
        events={[statusEvents]}
        width={props.width}
        height={setHeight(data)}
        scale="time"
        domain={{ x: props.zoomDomain.x, y: setDomain(data) }}
        //same padding/domain padding as primary graph
        domainPadding={commonPrimaryGraphProps.domainPadding}
        padding={{
          left: commonPrimaryGraphProps.chartPadding.right,
          right: commonPrimaryGraphProps.chartPadding.right
        }}
        containerComponent={
          <VictoryVoronoiContainer
            labels={() => " "}
            name="tooltipContainer"
            labelComponent={
              <VictoryTooltip
                flyoutComponent={<RenderTooltip graphData={data} />}
              />
            }
          />
        }
        theme={VictoryTheme.material}
      >
        {/* angle data */}
        {shouldRenderAngleData && (
          <VictoryScatter
            name="angleData"
            data={angleData}
            dataComponent={<RenderAngleDataPoint graphData={data} />}
            size={8}
          />
        )}

        {/* running status data */}
        {shouldRenderRunningStatusData && (
          <VictoryScatter
            name="runningStatusData"
            data={runningStatusCleaned}
            size={5}
            style={{ data: { fill: "black" } }}
          />
        )}

        {/* device health data */}
        {shouldRenderDeviceHealthData && (
          <VictoryScatter
            name="deviceHealthData"
            data={deviceHealthData}
            size={5}
            style={{ data: { fill: "red" } }}
          />
        )}

        {/* startup data */}
        {shouldRenderStartupData && (
          <VictoryScatter
            name="startupData"
            data={startupData}
            size={5}
            style={{ data: { fill: "orange" } }}
          />
        )}
      </VictoryGroup>
    </Card>
  );
};

export default React.memo(StatusGraph);
