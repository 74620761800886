import React from "react";
import {
  BankOutlined,
  LineChartOutlined,
  SettingOutlined,
  ShoppingOutlined,
  UserOutlined,
  EditOutlined
} from "@ant-design/icons";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  Card,
  Col,
  Divider,
  Layout,
  Menu,
  Row,
  Select,
  Space,
  Switch,
  Tabs,
  Typography
} from "antd";
import { isNil, isUndefined } from "lodash-es";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { size } from "../../helpers/pageHelper";
import { emptyUser } from "../../helpers/sessionHelper";
import {
  TemperatureScale,
  useGetCompanyDetailsQuery,
  useGetCurrentUserQuery,
  useGetLicensesQuery,
  useGetUserRightsQuery
} from "../../state/cargologRestApi";
import {
  openChangeEmailModal,
  openChangePasswordModal,
  openEditCompanyModal,
  openEditInvoiceInfoModal,
  openEditUserNameModal,
  openEditUserPhoneModal,
  openGraphAxisScaleModal,
  openTimezoneModal,
  openUserRequestModal,
  openVectorSettingsModal,
  setCompany,
  setUser
} from "../../state/modalsSlice";
import { setCurrentAdminTab } from "../../state/navigationSlice";
import {
  selectGlobalGraphScaleToggle,
  selectGraphAxisTickCountTime,
  setGraphAxisTickCountTime,
  toggleGlobalGraphAxisScale
} from "../../state/persistantStateSlice";
import {
  getUser,
  selectCsvFormat,
  selectGlobalTimezone,
  selectGlobalTimezoneToggle,
  selectShowNearValuesToggle,
  selectTemperatureScale,
  setCsvFormat,
  setTemperatureScale,
  toggleGlobalTimezoneState,
  toggleShowNearValues
} from "../../state/sessionSlice";
import {
  closeSettingsModal,
  selectSettings,
  setCurrentPage
} from "../../state/settingsSlice";
import UserRequestModal from "../AdminPage/UserRequestModal";
import { InfoAlert } from "../Common/CommonAlerts";
import { NormalButton, NormalButtonSmall } from "../Common/CommonButtons";
import { SmallText } from "../Common/CommonFonts";
import {
  CheckUserRightsAccess,
  LicenseAccess
} from "../MicroComponents/LicenseAccess";
import Online from "../MicroComponents/Online";
import CurrencyCard from "./CurrencyCard";
import GpsSpeedCard from "./GpsSpeedCard";
import LanguageCard from "./LanguageCard";
import MapProviderCard from "./MapProviderCard";
import TimezoneModal from "./TimezoneModal";
import DefaultBatteryCard from "./DefaultBatteryCard";
import StandardModal from "../Common/StandardModal";
import {
  addToRenewalCart,
  openRenewalModal
} from "../../state/licenseStoreSlice";
const { Option } = Select;
const { Text } = Typography;

// ===== Types and interfaces

/** Available pages in this component */
export type SettingsModalPage = "general" | "graph" | "account" | "company";

/** A page in this component shall always consist of content */
type PageContent = {
  [key in SettingsModalPage]: {
    content: JSX.Element;
  };
};

interface GeneralContentProps {
  isLoading: boolean;
}

interface GraphContentProps {
  isLoading: boolean;
}

interface AccountContentProps {
  isLoading: boolean;
}

interface CompanyContentProps {
  isLoading: boolean;
}

// ===== Local components used by the main component

/** Local component genreal page content */
const GeneralPageContent = (props: GeneralContentProps) => {
  const { t } = useTranslation();

  /** Render short info about device if connected, otherwise display info to the
   *user that no device is connected */
  const generalContent = (
    <>
      <Row justify="start">
        <Space direction="horizontal" align="start">
          <SettingOutlined />
          <Col>
            <Space direction="vertical" size={0}>
              <SmallText strong>{t("GeneralSettings")}</SmallText>
              <SmallText type="secondary">
                {t("GeneralSettingsDescription")}
              </SmallText>
            </Space>
          </Col>
        </Space>
      </Row>
      <Divider />

      <LanguageCard />
      <DefaultBatteryCard />
      {LicenseAccess("AFB", <CurrencyCard />)}
      <GpsSpeedCard />
      <MapProviderCard />
    </>
  );

  return <Card style={{ minHeight: "100%" }}>{generalContent}</Card>;
};

/** Local component graph page content */
const GraphPageContent = (props: GraphContentProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const showNearValues = useSelector(selectShowNearValuesToggle) ?? false;
  const timezoneState = useSelector(selectGlobalTimezone);
  const timezoneToggle = useSelector(selectGlobalTimezoneToggle);

  /** Get default csv format and set to swe if undefined (first run) */
  const csvFormat = useSelector(selectCsvFormat);
  if (isUndefined(csvFormat)) {
    dispatch(setCsvFormat("swe"));
  }

  const timeScaleSteps = useSelector(selectGraphAxisTickCountTime);
  const graphScaleToggle = useSelector(selectGlobalGraphScaleToggle);

  const tempScale = useSelector(selectTemperatureScale);

  /** When temperature scale is set user details is updated with the selected scale */
  const onChangeTemp = (newTempScale: TemperatureScale) => {
    if (newTempScale === "C" || newTempScale === "F") {
      dispatch(setTemperatureScale(newTempScale));
    }
  };

  const selectAndButtonWidth = 190;

  const generalContent = (
    <>
      <Row justify="start">
        <Space direction="horizontal" align="start">
          <LineChartOutlined />
          <Col>
            <Space direction="vertical" size={0}>
              <SmallText strong>{t("GraphSettings")}</SmallText>
              <SmallText type="secondary">
                {t("GraphSettingsDescription")}
              </SmallText>
            </Space>
          </Col>
        </Space>
      </Row>

      <Divider />

      {/* Near Values */}
      <Card style={{ marginBottom: size.m1 }}>
        <Row>
          <Col>
            <SmallText strong>{t("NearValues")}</SmallText>
          </Col>
        </Row>
        <Row align="middle" gutter={size.m1} style={{ padding: size.m1 }}>
          <Col>
            <Row align="middle">
              <Switch
                size="small"
                defaultChecked={showNearValues}
                onChange={() => dispatch(toggleShowNearValues())}
              />
            </Row>
          </Col>
          <Col>
            <Text strong> {t("ShowNearValues")}</Text>
          </Col>
        </Row>
        <Row style={{ paddingLeft: size.m1 }}>
          <Col>
            <SmallText type={showNearValues ? undefined : "secondary"}>
              {t("DescriptionGlobalNearValuesLine1")}
              <br />
              {t("DescriptionGlobalNearValuesLine2")}
            </SmallText>
          </Col>
        </Row>
      </Card>

      {/* Temperature */}
      <Card style={{ marginBottom: size.m1 }}>
        <Row>
          <SmallText strong>{t("Temperature")}</SmallText>
        </Row>
        <Row align="middle" style={{ padding: size.m1 }}>
          <Col span={12}>
            <SmallText>{t("SelectTemperatureScale")}:</SmallText>
          </Col>
          <Col>
            <Select
              style={{ minWidth: selectAndButtonWidth, textAlign: "center" }}
              // Keeps Dropdown menu in place when scrolling the page
              getPopupContainer={(triggerNode: HTMLElement) =>
                triggerNode.parentNode as HTMLElement
              }
              defaultValue={tempScale}
              onChange={(tempScaleValue) => onChangeTemp(tempScaleValue)}
            >
              <Option value="C">Celsius</Option>
              <Option value="F">Fahrenheit</Option>
            </Select>
          </Col>
        </Row>
      </Card>

      {/* Time Scale Axis Steps */}
      <Card style={{ marginBottom: size.m1 }}>
        <Row style={{ marginBottom: size.m1 }}>
          <SmallText strong>{t("TimeScaleAxisSteps")}</SmallText>
        </Row>
        <Row style={{ padding: size.m1 }}>
          <Col span={12}>
            <SmallText>{t("SetXAxisScale")}:</SmallText>
          </Col>
          <Col>
            <Select
              style={{ minWidth: selectAndButtonWidth, textAlign: "center" }}
              // Keeps Dropdown menu in place when scrolling the page
              getPopupContainer={(triggerNode: HTMLElement) =>
                triggerNode.parentNode as HTMLElement
              }
              defaultValue={timeScaleSteps ?? 0}
              onChange={(e: number) => dispatch(setGraphAxisTickCountTime(e))}
            >
              <Option value={0}>
                <>
                  <SmallText>
                    <SmallText strong>{t("Auto")} </SmallText>
                  </SmallText>
                  <SmallText type="secondary">({t("Default")})</SmallText>
                </>
              </Option>
              <Option value={3}>
                <SmallText strong>{t("Sparse")}</SmallText>
              </Option>
              <Option value={8}>
                <SmallText strong>{t("Medium")}</SmallText>
              </Option>
              <Option value={50}>
                <SmallText strong>{t("Dense")}</SmallText>
              </Option>
            </Select>
          </Col>
        </Row>
      </Card>

      {/* CSV Export */}
      <Card style={{ marginBottom: size.m1 }}>
        <Row>
          <SmallText strong>{t("CSVExport")}</SmallText>
        </Row>
        <Row align="middle" style={{ padding: size.m1 }}>
          <Col span={12}>
            <SmallText>{t("CSVFormatStandard")}:</SmallText>
          </Col>
          <Col>
            <Select
              style={{ minWidth: selectAndButtonWidth, textAlign: "center" }}
              // Keeps Dropdown menu in place when scrolling the page
              getPopupContainer={(triggerNode: HTMLElement) =>
                triggerNode.parentNode as HTMLElement
              }
              defaultValue={csvFormat}
              onChange={(csvValue) => dispatch(setCsvFormat(csvValue))}
            >
              <Option value="swe">
                <Text strong>{t("SwedishStandard")}</Text>
              </Option>
              <Option value="eng">
                <Text strong>{t("EnglishStandard")}</Text>
              </Option>
            </Select>
          </Col>
        </Row>
        <Row>
          <Col offset={12} style={{ paddingInlineStart: size.m1 }}>
            {csvFormat === "swe" && (
              <>
                <Text type="secondary">{t("DecimalSeparator")}: ","</Text>
                <br />
                <Text type="secondary">{t("CellSeparator")}: ";"</Text>
              </>
            )}
            {csvFormat === "eng" && (
              <>
                <Text type="secondary">{t("DecimalSeparator")}: "."</Text>
                <br />
                <Text type="secondary">{t("CellSeparator")}: ","</Text>
              </>
            )}
          </Col>
        </Row>
      </Card>

      {/* DVA vector settings */}
      <Card style={{ marginBottom: size.m1 }}>
        <Row>
          <Col>
            <SmallText strong>{t("VectorLeapSettings")}</SmallText>
          </Col>
        </Row>
        <Row align="middle" style={{ padding: size.m1 }}>
          <Col span={12}>
            <Text>{t("GlobalSettingsForVectorLeaps")}.</Text>
          </Col>
          <Col>
            <NormalButton
              style={{ minWidth: selectAndButtonWidth }}
              onClick={() => dispatch(openVectorSettingsModal())}
            >
              <SmallText strong>{t("VectorLeapSettings")}</SmallText>
            </NormalButton>
          </Col>
        </Row>
      </Card>

      {/* Global Graph Scale */}
      <Card style={{ marginBottom: size.m1 }}>
        <Row>
          <SmallText strong>{t("GlobalGraphScale")}</SmallText>
        </Row>
        <Row align="middle" style={{ padding: size.m1 }}>
          <Col span={12}>
            <Switch
              size="small"
              defaultChecked={graphScaleToggle}
              onChange={() => dispatch(toggleGlobalGraphAxisScale())}
            />
          </Col>
          <Col>
            <NormalButton
              disabled={!graphScaleToggle}
              style={{ minWidth: selectAndButtonWidth }}
              onClick={() => dispatch(openGraphAxisScaleModal())}
            >
              <SmallText
                strong
                type={graphScaleToggle ? undefined : "secondary"}
              >
                {t("SetScale")}
              </SmallText>
            </NormalButton>
          </Col>
        </Row>
        <Row style={{ padding: size.m1, paddingBottom: 0 }}>
          <Col>
            <SmallText type={graphScaleToggle ? undefined : "secondary"}>
              {t("DescriptionGlobalScaleSettings")}
            </SmallText>
          </Col>
        </Row>
      </Card>

      {/* Global Time Zone */}
      <Card>
        <Row>
          <Col>
            <SmallText strong>{t("GlobalTimeZone")}</SmallText>
          </Col>
        </Row>
        <Row align="middle" style={{ padding: size.m1 }}>
          <Col span={12}>
            <Switch
              size="small"
              defaultChecked={timezoneToggle}
              onChange={() => dispatch(toggleGlobalTimezoneState())}
            />
          </Col>
          <Col>
            <NormalButton
              disabled={!timezoneToggle}
              style={{ minWidth: selectAndButtonWidth }}
              onClick={() => dispatch(openTimezoneModal())}
            >
              <SmallText strong type={timezoneToggle ? undefined : "secondary"}>
                {timezoneState}
              </SmallText>
            </NormalButton>
          </Col>
        </Row>
        <Row style={{ padding: size.m1, paddingBottom: 0 }}>
          <Col>
            <SmallText type={timezoneToggle ? undefined : "secondary"}>
              {t("DescriptionGlobalTimezone")}
            </SmallText>
          </Col>
        </Row>
      </Card>

      <TimezoneModal />
    </>
  );

  return <Card style={{ minHeight: "100%" }}>{generalContent}</Card>;
};

/** Local component account page content */
const AccountPageContent = (props: AccountContentProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Load User from API
  const { data: userData } = useGetCurrentUserQuery();

  const { data: userRightsData } = useGetUserRightsQuery();
  const { data: companyLicenses } = useGetLicensesQuery(
    userData?.userId ? { userId: userData.userId } : skipToken
  );
  const userLicense = companyLicenses?.filter((license) =>
    isNil(license.projectId)
  );
  const expiredLicenses = userLicense?.filter(
    (license) =>
      dayjs().diff(license.endDate, "days") >= 0 && license.renewAuto === false
  );
  const user = userData ?? emptyUser;

  const isAdmin = CheckUserRightsAccess("AFA");

  // Load User Company from API
  const { data: companyData } = useGetCompanyDetailsQuery(
    user.companyId === "" ? skipToken : { companyId: user.companyId }
  );

  const generalContent = (
    <>
      <Row justify="start">
        <Space direction="horizontal" align="start">
          <UserOutlined />
          <Col span={24}>
            <Space direction="vertical" size={0}>
              <SmallText strong>{t("AccountSettings")}</SmallText>
              <SmallText type="secondary">
                {t("SettingsForUserAccount")}
              </SmallText>
            </Space>
          </Col>
        </Space>
      </Row>
      <Divider />
      <Online>
        <Row style={{ marginBottom: size.m1 }}>
          <Col span={8}>{t("genName")}</Col>
          <Col span={12}>
            <SmallText strong>{user.firstName + " " + user.lastName}</SmallText>
          </Col>
          <Col span={4}>
            <NormalButtonSmall
              icon={<EditOutlined />}
              onClick={() => {
                dispatch(setUser(user));
                dispatch(openEditUserNameModal());
              }}
            >
              {t("genEdit")}
            </NormalButtonSmall>
          </Col>
        </Row>
        <Row style={{ marginBottom: size.m1 }}>
          <Col span={8}>{t("Email")}</Col>
          <Col span={12}>
            <SmallText strong>{user.email}</SmallText>
          </Col>
          <Col span={4}>
            <NormalButtonSmall
              icon={<EditOutlined />}
              onClick={() => {
                dispatch(setUser(user));
                dispatch(openChangeEmailModal());
              }}
            >
              {t("genEdit")}
            </NormalButtonSmall>
          </Col>
        </Row>
        <Row style={{ marginBottom: size.m1 }}>
          <Col span={8}>{t("PhoneNumber")}</Col>
          <Col span={12}>
            <SmallText strong>{user.phoneNumber}</SmallText>
          </Col>
          <Col span={4}>
            <NormalButtonSmall
              icon={<EditOutlined />}
              onClick={() => {
                dispatch(setUser(user));
                dispatch(openEditUserPhoneModal());
              }}
            >
              {t("genEdit")}
            </NormalButtonSmall>
          </Col>
        </Row>
        <Row style={{ marginBottom: size.m1 }}>
          <Col span={8}>{t("genCompanies")}</Col>
          <Col span={12}>
            <SmallText strong key={companyData?.name}>
              {companyData?.name}{" "}
            </SmallText>
          </Col>
          <Col span={4}>
            <NormalButtonSmall
              icon={<EditOutlined />}
              onClick={() => {
                dispatch(setUser(user));
                dispatch(openUserRequestModal());
              }}
            >
              {t("genEdit")}
            </NormalButtonSmall>
          </Col>
        </Row>
        <Row style={{ marginBottom: size.m1 }}>
          <Col span={8}>{t("Password")}</Col>
          <Col span={12}>
            <SmallText>· · · ·</SmallText>
          </Col>
          <Col span={4}>
            <NormalButtonSmall
              icon={<EditOutlined />}
              onClick={() => {
                dispatch(setUser(user));
                dispatch(openChangePasswordModal());
              }}
            >
              {t("genEdit")}
            </NormalButtonSmall>
          </Col>
        </Row>
        <Row style={{ marginBottom: size.m2 }}>
          <Col span={8}>
            <SmallText>{t("UserId")}</SmallText>
          </Col>
          <Col span={12}>
            <SmallText strong type="secondary">
              {user.userId}
            </SmallText>
          </Col>
        </Row>
        {expiredLicenses && expiredLicenses.length > 0 && (
          <Row style={{ marginBottom: size.m2 }}>
            <Col span={8}>
              <SmallText strong type="danger">
                {t("ExpiredLicenses")}
              </SmallText>
            </Col>
            <Col span={16}>
              <Space direction="vertical">
                {expiredLicenses.map((license) => {
                  return (
                    <>
                      <SmallText strong>
                        {license.text}
                        <SmallText type="secondary"> ({license.id})</SmallText>
                      </SmallText>
                      {license.text !== "Mobitron" ? (
                        <SmallText
                          type="danger"
                          style={{
                            paddingLeft: size.m1,
                            cursor: isAdmin ? "pointer" : "auto"
                          }}
                          onClick={() => {
                            if (isAdmin) {
                              dispatch(closeSettingsModal());
                              dispatch(
                                addToRenewalCart({
                                  licenseId: license.id,
                                  licensePriceId: license.licensePriceId
                                })
                              );
                              dispatch(openRenewalModal());
                              navigate("/admin");
                            }
                          }}
                        >
                          {t("Expired")}
                          {": "}
                          {dayjs(license.endDate).format("YYYY-MM-DD")}
                        </SmallText>
                      ) : undefined}
                    </>
                  );
                })}
              </Space>
            </Col>
          </Row>
        )}
        <Row style={{ paddingTop: 0, marginBottom: size.m1 }}>
          <Col span={8}>
            <SmallText strong>{t("ActiveLicenses")}</SmallText>
          </Col>
          <Col span={16}>
            <Space direction="vertical">
              {userLicense
                ?.filter(
                  (license) =>
                    dayjs().diff(license.endDate, "days") < 0 ||
                    license.renewAuto === true
                )
                .map((license, index) => {
                  const daysDiff = dayjs().diff(license.endDate, "days");
                  const within7Days = daysDiff >= -7 && daysDiff < 0;

                  return (
                    <React.Fragment key={index}>
                      <SmallText strong>
                        {license.text}
                        <SmallText type="secondary"> ({license.id})</SmallText>
                      </SmallText>
                      {license.text !== "Mobitron" ? (
                        <SmallText
                          type={within7Days ? "warning" : "secondary"}
                          style={{ paddingLeft: size.m1 }}
                        >
                          {license.renewAuto ? t("Renews") : t("Expires")}
                          {": "}
                          {dayjs(license.endDate).format("YYYY-MM-DD")}
                          {license.renewAuto && ` (${t("AutoRenewal")})`}
                        </SmallText>
                      ) : undefined}
                    </React.Fragment>
                  );
                })}
              {userLicense && userLicense.length < 1 && (
                <SmallText strong type="danger">
                  {t("NoActiveLicenses")}
                </SmallText>
              )}
            </Space>
          </Col>
        </Row>
        {!userRightsData?.includes("ACA") &&
          LicenseAccess(
            "AFB",
            <Row justify="center" style={{ paddingTop: size.m1 }}>
              <Col span={24}>
                <InfoAlert
                  style={{ textAlign: "center" }}
                  message={
                    <>
                      <SmallText>{t("UpgradeLicenseToHandleDevice")}</SmallText>
                      <br />
                      <NormalButtonSmall
                        icon={<ShoppingOutlined />}
                        style={{ marginTop: size.s1 }}
                        onClick={() => {
                          dispatch(setCurrentAdminTab("store"));
                          dispatch(closeSettingsModal());
                          navigate("/admin");
                        }}
                      >
                        <SmallText strong>{t("HeadToLicenseStore")}</SmallText>
                      </NormalButtonSmall>
                    </>
                  }
                />
              </Col>
            </Row>
          )}
      </Online>
    </>
  );

  return <Card style={{ minHeight: "100%" }}>{generalContent}</Card>;
};

/** Local component avvount page content */
const CompanyPageContent = (props: CompanyContentProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { TabPane } = Tabs;

  const changeTab = (key: string) => {};

  // Load User Company from API
  const currentUser = useSelector(getUser);
  const { data: companyData } = useGetCompanyDetailsQuery(
    currentUser.companyId ? { companyId: currentUser.companyId } : skipToken
  );

  const generalContent = (
    <>
      <Row justify="start">
        <Space direction="horizontal" align="start">
          <BankOutlined />
          <Col span={24}>
            <Space direction="vertical" size={0}>
              <SmallText strong>{t("CompanySettings")}</SmallText>
              <SmallText type="secondary">{t("SettingsForCompany")}</SmallText>
            </Space>
          </Col>
        </Space>
      </Row>
      <Divider style={{ marginBottom: 0, marginTop: size.m1 }} />
      <Online>
        <Tabs defaultActiveKey="1" onChange={changeTab}>
          <TabPane tab={"Company Details"} key={"1"}>
            <Row style={{ marginBottom: size.m1 }}>
              <Col span={8}>{t("genName")}</Col>
              <Col span={12}>
                <SmallText strong key={companyData?.name}>
                  {companyData?.name}
                </SmallText>
              </Col>
            </Row>
            <Row style={{ marginBottom: size.m1 }}>
              <Col span={8}>{t("Address")}</Col>
              <Col span={12}>
                <SmallText strong key={companyData?.address}>
                  {companyData?.address}
                </SmallText>
              </Col>
            </Row>
            <Row style={{ marginBottom: size.m1 }}>
              <Col span={8}>{t("Address2")}</Col>
              <Col span={12}>
                <SmallText strong key={companyData?.address2}>
                  {companyData?.address2}
                </SmallText>
              </Col>
            </Row>
            <Row style={{ marginBottom: size.m1 }}>
              <Col span={8}>{t("PostalCode")}</Col>
              <Col span={12}>
                <SmallText strong key={companyData?.postalCode}>
                  {companyData?.postalCode}
                </SmallText>
              </Col>
            </Row>
            <Row style={{ marginBottom: size.m1 }}>
              <Col span={8}>{t("City")}</Col>
              <Col span={12}>
                <SmallText strong key={companyData?.city}>
                  {companyData?.city}
                </SmallText>
              </Col>
            </Row>
            <Row style={{ marginBottom: size.m1 }}>
              <Col span={8}>{t("Country")}</Col>
              <Col span={12}>
                <SmallText strong key={companyData?.countryCode}>
                  {companyData?.countryCode}
                </SmallText>
              </Col>
            </Row>
            <Row style={{ marginBottom: size.m1 }}>
              <Col span={8}>{t("OrgNumber")}</Col>
              <Col span={12}>
                <SmallText strong key={companyData?.orgNumber}>
                  {companyData?.orgNumber}
                </SmallText>
              </Col>
            </Row>
            <Row style={{ marginBottom: size.m1 }}>
              <Col span={8}>{t("Currency")}</Col>
              <Col span={12}>
                <SmallText strong key={companyData?.currency}>
                  {companyData?.currency}
                </SmallText>
              </Col>
            </Row>
            <Row style={{ marginBottom: size.m1 }}>
              <Col span={8}>{t("AdminEmail")}</Col>
              <Col span={12}>
                <SmallText strong key={companyData?.adminEmail}>
                  {companyData?.adminEmail}
                </SmallText>
              </Col>
            </Row>
            <Row style={{ marginBottom: size.m1 }}>
              <Col span={8}>
                <SmallText type="secondary">{t("CompanyId")}</SmallText>
              </Col>
              <Col span={12}>
                <SmallText strong type="secondary">
                  {companyData?.id}
                </SmallText>
              </Col>
            </Row>
            <Row>
              <Col>
                <NormalButton
                  icon={<EditOutlined />}
                  onClick={() => {
                    dispatch(setCompany(companyData));
                    dispatch(openEditCompanyModal());
                  }}
                >
                  {t("genEdit")}
                </NormalButton>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab={"Invoice Details"} key={"2"}>
            <Row style={{ marginBottom: size.m1 }}>
              <Col span={8}>{t("InvoiceEmail")}</Col>
              <Col span={12}>
                <SmallText strong key={companyData?.invoiceEmail}>
                  {companyData?.invoiceEmail}
                </SmallText>
              </Col>
            </Row>
            <Row style={{ marginBottom: size.m1 }}>
              <Col span={8}>{t("InvoiceAddress")}</Col>
              <Col span={12}>
                <SmallText strong key={companyData?.invoiceAddress}>
                  {companyData?.invoiceAddress}
                </SmallText>
              </Col>
            </Row>
            <Row style={{ marginBottom: size.m1 }}>
              <Col span={8}>{t("InvoicePostalCode")}</Col>
              <Col span={12}>
                <SmallText strong key={companyData?.invoicePostalCode}>
                  {companyData?.invoicePostalCode}
                </SmallText>
              </Col>
            </Row>
            <Row style={{ marginBottom: size.m1 }}>
              <Col span={8}>{t("InvoiceCity")}</Col>
              <Col span={12}>
                <SmallText strong key={companyData?.invoiceCity}>
                  {companyData?.invoiceCity}
                </SmallText>
              </Col>
            </Row>
            {companyData?.countryCode !== "SE" && (
              <Row style={{ marginBottom: size.m1 }}>
                <Col span={8}>{t("VATNumber")}</Col>
                <Col span={12}>
                  <SmallText strong key={companyData?.vatNumber}>
                    {companyData?.vatNumber}
                  </SmallText>
                </Col>
              </Row>
            )}
            {companyData?.countryCode === "SE" && (
              <Row style={{ marginBottom: size.m1 }}>
                <Col span={8}>{t("OrgNumber")}</Col>
                <Col span={12}>
                  <SmallText strong key={companyData?.orgNumber}>
                    {companyData?.orgNumber}
                  </SmallText>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <NormalButton
                  icon={<EditOutlined />}
                  onClick={() => {
                    dispatch(setCompany(companyData));
                    dispatch(openEditInvoiceInfoModal());
                  }}
                >
                  {t("genEdit")}
                </NormalButton>
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Online>
    </>
  );

  return <Card style={{ minHeight: "100%" }}>{generalContent}</Card>;
};

// ===== Main component
const SettingsModal = () => {
  const settings = useSelector(selectSettings);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  /** All avaible pages in this component */
  const pages: PageContent = {
    general: {
      content: <GeneralPageContent isLoading={false} />
    },
    graph: {
      content: <GraphPageContent isLoading={false} />
    },

    account: {
      content: <AccountPageContent isLoading={false} />
    },

    company: {
      content: <CompanyPageContent isLoading={false} />
    }
  };

  // Render functions
  const renderContent = (active: SettingsModalPage) => {
    return (
      <div
        style={{
          width: "100%",
          height: "950px",
          maxWidth: "95vh",
          maxHeight: "60vh",
          overflow: "auto",
          outline: "none"
        }}
      >
        {pages[active].content}
      </div>
    );
  };

  const { Sider, Content } = Layout;

  return (
    <>
      {/* Settings modal */}
      <StandardModal
        title={t("Settings")}
        open={settings.isSettingsModalOpen}
        onCancel={() => dispatch(closeSettingsModal())}
        closable={true}
        width={900}
        footer={null}
        destroyOnClose={true}
        style={{
          top: "72px",
          paddingBottom: 0
        }}
        styles={{
          content: {
            padding: 0
          },
          body: {
            padding: 0
          }
        }}
        zIndex={1040}
      >
        <Layout>
          <Sider theme="light">
            <Menu
              theme="light"
              mode="inline"
              defaultSelectedKeys={[settings.currentPage]}
            >
              <Menu.Item
                key="general"
                onClick={() => dispatch(setCurrentPage("general"))}
                icon={<SettingOutlined />}
              >
                {t("General")}
              </Menu.Item>
              {LicenseAccess(
                "ACD",
                <Menu.Item
                  key="graph"
                  onClick={() => dispatch(setCurrentPage("graph"))}
                  icon={<LineChartOutlined />}
                >
                  {t("Graph")}
                </Menu.Item>
              )}
              <Menu.Item
                key="account"
                onClick={() => dispatch(setCurrentPage("account"))}
                icon={<UserOutlined />}
              >
                {t("Account")}
              </Menu.Item>
              {LicenseAccess(
                "AFC",
                <Menu.Item
                  key="company"
                  onClick={() => dispatch(setCurrentPage("company"))}
                  icon={<BankOutlined />}
                >
                  {t("Company")}
                </Menu.Item>
              )}
            </Menu>
          </Sider>
          <Content>{renderContent(settings.currentPage)}</Content>
        </Layout>
      </StandardModal>
      <UserRequestModal />
    </>
  );
};

export default SettingsModal;
