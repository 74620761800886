import React from "react";
import { Row, DatePicker, Card, Select, Typography } from "antd";
import { isNil } from "lodash-es";
import dayjs, { Dayjs } from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  selectRecordingParameters,
  setStartDate,
  setStartRecordingType
} from "../../state/openParxSlice";
import { size, hideBlock } from "../../helpers/pageHelper";
import { useTranslation } from "react-i18next";
import { SmallTitle } from "../Common/CommonFonts";
import { GeneralSystemInfo } from "../../models/ISystemInfo";
import ParamsBuilderAdvanced from "./ParamsBuilderAdvanced";
import { interpretSystemInfoHasFeatures } from "../../helpers/parsers/parseSystemInfoHelper";
import ParamsBuilderScheduler from "./ParamsBuilderScheduler";
import mobitronColors from "../../styles/mobitronColors";
import { NormalButtonSmall } from "../Common/CommonButtons";
const { Option } = Select;
const { Text } = Typography;

interface IProps {
  systemInfo: GeneralSystemInfo;
}
const ParamsBuilderDateAndTime: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { RecParams } = useSelector(selectRecordingParameters);
  const dispatch = useDispatch();
  const timestampFormat = "YYYY-MM-DD, HH:mm";

  const deviceFeatures = interpretSystemInfoHasFeatures(
    props.systemInfo.hasFeatures
  );

  const handleChangeStartDate = (value: Dayjs) => {
    const unixDate = value.set("second", 0).utc().unix();
    dispatch(setStartDate(unixDate));
  };

  const getStartDate = () => {
    if (isNil(RecParams.startTimestamp)) {
      return undefined;
    }
    const startDate = dayjs.unix(RecParams.startTimestamp!);
    return startDate;
  };

  // Check if the start date is defined and efter the current date
  const startDateStatus = (): "error" | undefined => {
    if (RecParams.startRecordingType === "date") {
      if (
        !RecParams.startTimestamp ||
        (RecParams.startTimestamp &&
          dayjs.unix(RecParams.startTimestamp).isBefore(dayjs()))
      ) {
        return "error";
      }
    }
    return undefined;
  };

  const getEndDate = () => {
    if (RecParams.startRecordingType === "date" && RecParams.startTimestamp) {
      return RecParams.stopTimestamp
        ? dayjs.unix(RecParams.stopTimestamp!).format(timestampFormat)
        : t("NotSet");
    } else {
      return `${RecParams.stopAfterDuration.days}d ${RecParams.stopAfterDuration.hours}h`;
    }
  };

  const RecPeriodExplanation = {
    date: t("RecordingPeriodWillBeginSelectedDate"),
    button: t("RecordingPeriodWillBeginHardwareBtn"),
    directly: t("RecordingPeriodWillBeginDirectly")
  };

  const newLine: React.CSSProperties = {
    width: "100%",
    textAlign: "start"
  };

  const cardStyle: React.CSSProperties = {
    width: "100%",
    marginBottom: size.m1,
    borderColor: mobitronColors.altGray,
    textAlign: "start"
  };

  const startText = (): string => {
    const startType = RecParams.startRecordingType;
    if (startType === "date") {
      const startDate = getStartDate();
      return (
        t("StartOnDate") +
        ": " +
        (startDate ? startDate.format(timestampFormat) : t("NotSet"))
      );
    } else if (startType === "button") {
      return t("StartByButton");
    } else {
      return t("StartDirectly");
    }
  };

  return (
    <>
      <SmallTitle style={newLine}>{t("StartRecording")}</SmallTitle>
      <Card style={cardStyle} styles={{ body: { padding: size.m1 } }}>
        <Row style={newLine}>
          <Select
            value={RecParams.startRecordingType}
            size="small"
            style={{ minWidth: 140, marginRight: size.s1 }}
            onChange={(value) => dispatch(setStartRecordingType(value))}
          >
            <Option value="date">{t("StartOnDate")}</Option>
            <Option value="button">{t("StartByButton")}</Option>
            <Option value="directly">{t("StartDirectly")}</Option>
          </Select>
          <div
            style={
              RecParams.startRecordingType === "date" ? undefined : hideBlock
            }
          >
            <DatePicker
              size="small"
              value={getStartDate()}
              showNow={false}
              showTime
              onChange={(dates) =>
                isNil(dates) ? undefined : handleChangeStartDate(dates)
              }
              format={timestampFormat}
              allowClear={false}
              minDate={dayjs()}
              status={startDateStatus()}
              panelRender={(panel) => {
                return (
                  <div style={{ padding: size.s1 }}>
                    {panel}
                    <NormalButtonSmall
                      style={{
                        position: "absolute",
                        bottom: 15,
                        left: 17
                      }}
                      onClick={() => handleChangeStartDate(dayjs())}
                    >
                      {t("Now")}
                    </NormalButtonSmall>
                  </div>
                );
              }}
            />
          </div>
        </Row>
        <Text style={newLine}>
          {RecPeriodExplanation[RecParams.startRecordingType]}
        </Text>
      </Card>
      <SmallTitle style={newLine}>{t("RecordingBlocks")}</SmallTitle>
      <ParamsBuilderScheduler
        features={{
          hasGps: deviceFeatures.DF_GPS,
          hasLte: deviceFeatures.DF_LTE
        }}
      />
      <SmallTitle style={newLine}>{t("SummaryOfMeasurementPeriod")}</SmallTitle>
      <Card style={cardStyle} styles={{ body: { padding: size.m1 } }}>
        <Text strong>{t("RecordStart")}</Text>
        <br />
        <Text style={newLine}>{startText()}</Text>
        <br />
        <Text strong>{t("RecordEnd")}</Text>
        <br />
        <Text>
          {RecParams.startRecordingType === "date" && RecParams.startTimestamp
            ? t("EndOnDate")
            : t("EndAfterDuration")}
          {": "}
          {getEndDate()}
        </Text>
      </Card>
      <ParamsBuilderAdvanced
        features={{
          hasGps: deviceFeatures.DF_GPS,
          hasLte: deviceFeatures.DF_LTE
        }}
      />
    </>
  );
};

export default ParamsBuilderDateAndTime;
