import React from "react";
import { Col, Modal, ModalProps, Row, Space, Typography } from "antd";
import { modalSize, size } from "../../helpers/pageHelper";
import { CloseOutlined } from "@ant-design/icons";
import { isUndefined } from "lodash-es";
const { Text } = Typography;

interface StandarModalProps extends ModalProps {
  children: React.ReactNode;
  extraButtons?: React.ReactElement[];
}
const StandardModal: React.FC<StandarModalProps> = (props) => {
  const { title, onCancel, children, extraButtons } = props;

  // Setting this value as width in modal doesn't work for large modals.
  // Setting the minimum width of modal to modalSize.s1, if string or number is provided
  // const width = !isUndefined(props.width)
  //   ? Math.max(modalSize.s1, Number(props.width))
  //   : undefined;

  // Modals with custom styles={{...}}:
  // * PrintMultiblePreviewer
  // * ClassicGraphDashboardCard
  // * HelpModal
  // * OrderDetailsModal
  // * ParamsPreviewModal
  // * SettingsModal

  return (
    <Modal
      {...props}
      width={props.width}
      styles={{
        content: {
          padding: 0
        },
        header: {
          paddingTop: size.m1,
          paddingRight: size.m1,
          paddingBottom: size.m1,
          paddingLeft: size.l1,
          marginBottom: 0
        },
        body: {
          paddingInline: size.l1,
          paddingBottom: props.footer ? 0 : size.l1
        },
        footer: {
          paddingTop: size.m1,
          paddingInline: size.l1,
          paddingBottom: size.l1,
          marginTop: 0
        },
        ...props.styles
      }}
      title={
        <Row align="middle" justify="space-between" wrap={false}>
          <Col flex="auto">
            <Text ellipsis={true} style={{ fontSize: 20 }}>
              {title}
            </Text>
          </Col>
          <Col>
            <Space>
              {!isUndefined(extraButtons) ? extraButtons : <></>}
              {!isUndefined(onCancel) ? (
                <button
                  onClick={(e) => onCancel(e)}
                  className="closeModalButton"
                >
                  <CloseOutlined style={{ padding: size.s2 }} />
                </button>
              ) : (
                <></>
              )}
            </Space>
          </Col>
        </Row>
      }
      closable={false}
    >
      {children}
    </Modal>
  );
};

export default StandardModal;
