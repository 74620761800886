import { isUndefined } from "lodash-es";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  cargologRestApi,
  UserSettings,
  useSaveUserSettingsMutation
} from "../state/cargologRestApi";
import {
  defaultUserSettings,
  getTokens,
  sessionState,
  setCurrency,
  setUserSettingsFromApi
} from "../state/sessionSlice";
import { selectSettings, setPreferedCurrency } from "../state/settingsSlice";

// Only compare the settings that are relevant for the current application
const filterSettings = (settings: UserSettings): UserSettings => ({
  language: settings.language ?? defaultUserSettings.language,
  currency: settings.currency ?? defaultUserSettings.currency,
  defaultBattery: settings.defaultBattery ?? defaultUserSettings.defaultBattery,
  temperatureScale:
    settings.temperatureScale ?? defaultUserSettings.temperatureScale,
  speedUnit: settings.speedUnit ?? defaultUserSettings.speedUnit,
  mapProvider: settings.mapProvider ?? defaultUserSettings.mapProvider,
  csvFormat: settings.csvFormat ?? defaultUserSettings.csvFormat,
  useGlobalTimezone:
    settings.useGlobalTimezone ?? defaultUserSettings.useGlobalTimezone,
  globalTimezone: settings.globalTimezone ?? defaultUserSettings.globalTimezone,
  showNearValues: settings.showNearValues ?? defaultUserSettings.showNearValues,
  autoUploadDatx: settings.autoUploadDatx ?? defaultUserSettings.autoUploadDatx,
  showQuickTourAtStartup:
    settings.showQuickTourAtStartup ??
    defaultUserSettings.showQuickTourAtStartup
});

const settingsString = (settings?: UserSettings): string => {
  return settings
    ? JSON.stringify(filterSettings(settings))
    : JSON.stringify(defaultUserSettings);
};

// Sync user settings with the server
const useSyncUserSettings = () => {
  const dispatch = useDispatch();
  const { networkStatus, userSettings } = useSelector(sessionState);
  const { preferedCurrency } = useSelector(selectSettings);

  const newUserSettings: UserSettings = {
    ...userSettings,
    currency: !isUndefined(preferedCurrency)
      ? preferedCurrency
      : userSettings.currency
  };

  const localSettingsString = settingsString(newUserSettings);
  const [remoteSettingsString, setRemoteSettingsString] =
    useState(settingsString());

  // Sync up to the server on settings change
  const [saveUserSettings] = useSaveUserSettingsMutation();
  useEffect(() => {
    if (
      networkStatus === "online" &&
      localSettingsString !== remoteSettingsString
    ) {
      // console.log("↑ To server:", newUserSettings);
      setRemoteSettingsString(localSettingsString);
      saveUserSettings(newUserSettings); // Sends the settings to the server

      // Set currency if preferedCurrency is set. Reset state after setCurrency
      if (!isUndefined(preferedCurrency)) {
        dispatch(setCurrency(preferedCurrency));
        dispatch(setPreferedCurrency(undefined));
      }
    }
  }, [userSettings]);

  // Sync down from the server
  const { accessToken } = useSelector(getTokens);
  const [trigger, result] =
    cargologRestApi.endpoints.getUserSettings.useLazyQuery();
  useEffect(() => {
    if (result.data === null) return; // If the server returns null, do nothing
    const newRemoteSettingsString = settingsString(result.data);

    if (
      !isUndefined(accessToken) && // user is signed in
      !isUndefined(result.data) && // server has responded
      newRemoteSettingsString !== localSettingsString
    ) {
      // server has new data
      // console.log("↓ From server:", result.data);
      setRemoteSettingsString(newRemoteSettingsString);
      dispatch(setUserSettingsFromApi(result.data));

      // Reset state preferedCurrency
      dispatch(setPreferedCurrency(undefined));
    }
  }, [result]);

  // Fetch user settings when accessToken changes (login, logout, impersonate)
  useEffect(() => {
    if (!isUndefined(accessToken)) {
      // console.log("accesToken changed → refetching");
      trigger();
    }
  }, [accessToken]);
};

export default useSyncUserSettings;
