import React from "react";
import { Collapse, Spin, Tree, TreeDataNode, TreeProps } from "antd";
import { useTranslation } from "react-i18next";
import { useGetOnlineFilesListQuery } from "../../state/cargologRestApi";
import { CheckProjectRightsAccess } from "./LicenseAccess";
const { DirectoryTree } = Tree;

interface IProps {
  openFile: (filePath: string, fileName: string, parameterId: string) => void;
}
const OnlineFiles: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { data: onlineFiles, isLoading: loadingParameters } =
    useGetOnlineFilesListQuery();

  let datxTreeData: TreeDataNode[] = [];

  onlineFiles?.forEach((item) => {
    const project = datxTreeData.find((node) => node.key === item.projectId);
    if (project) {
      project.children?.push({
        title: item.fileName,
        key: item.id,
        isLeaf: true,
        disabled: !CheckProjectRightsAccess(item.userRights, "ADJ")
      });
    } else {
      datxTreeData.push({
        title: item.projectName,
        key: item.projectId,
        selectable: false,
        children: [
          {
            title: item.fileName,
            key: item.id,
            isLeaf: true,
            disabled: !CheckProjectRightsAccess(item.userRights, "ADJ")
          }
        ]
      });
    }
  });

  const onSelect: TreeProps["onSelect"] = (selectedKeys, info) => {
    // Find the selected file in the datxList
    const selectedFile = onlineFiles?.find(
      (item) => item.id === selectedKeys[0]
    );
    if (selectedFile) {
      props.openFile(
        selectedFile.fileId,
        selectedFile.fileName ?? "Online File",
        selectedFile.id
      );
    }
  };

  return (
    <>
      <Collapse expandIconPosition="end" ghost defaultActiveKey="onlineFiles">
        <Collapse.Panel
          header={t("OnlineFiles")}
          key="onlineFiles"
          className="custom"
        >
          <Spin spinning={loadingParameters} style={{ width: "100%" }} />
          <DirectoryTree
            treeData={datxTreeData}
            onSelect={onSelect}
            showIcon={false}
            showLine={true}
          />
        </Collapse.Panel>
      </Collapse>
    </>
  );
};

export default OnlineFiles;
