import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Row, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import StandardModal from "../Common/StandardModal";
import { size } from "../../helpers/pageHelper";
import { selectVectorData } from "../../state/persistantStateSlice";
import { useSelector } from "react-redux";
import { dvaSampleToMsTickFormaterWithPostfix } from "../../helpers/graphHelper";
import { VectorData, VectorList } from "../../helpers/vectorHelper";
import { Atable, Ath, Athead } from "../Common/CommonTables";
import classNames from "classnames";
import { VariableSizeGrid as Grid } from "react-window";
import { isUndefined } from "lodash-es";
import { useResizeDetector } from "react-resize-detector";
const { Text } = Typography;

const timespanWidth = 0.5;
const currentDifferenceWidth = 0.25;

interface VirtualTableProps {
  dataSource?: VectorData[];
  columns: ColumnsType<VectorData>;
  height: number;
  width: number;
  rowHeights?: number[];
}
const VirtualTable = (props: VirtualTableProps) => {
  const { dataSource, columns, height, width } = props;
  const gridRef = useRef<any>();

  const resetVirtualGrid = () => {
    gridRef.current?.resetAfterIndices({
      columnIndex: 0,
      rowIndex: 0,
      shouldForceUpdate: true
    });
  };

  useEffect(() => resetVirtualGrid, [width, dataSource]);

  if (!dataSource || !columns) return null;

  const columnSection = (width - 5) / 3;

  const mergedColumns = columns.map((column) => {
    return {
      ...column,
      width:
        column.title === "Timespan"
          ? columnSection * timespanWidth
          : columnSection * currentDifferenceWidth - 2 // -2 to avoid overflow
    };
  });

  const rowHeights = props.rowHeights ? [...props.rowHeights] : undefined;
  const getRowHeight = (index: number) => {
    return rowHeights ? rowHeights[index] : 55;
  };

  return (
    <div className="virtual-table">
      <Grid
        ref={gridRef}
        className="virtual-grid"
        height={height}
        width={width}
        columnCount={mergedColumns.length}
        rowCount={dataSource.length}
        columnWidth={(index: number) => mergedColumns[index].width}
        rowHeight={getRowHeight}
        style={{ textAlign: "left", outline: "none" }}
      >
        {({
          columnIndex,
          rowIndex,
          style
        }: {
          columnIndex: number;
          rowIndex: number;
          style: React.CSSProperties;
        }) => {
          const renderer = mergedColumns[columnIndex].render!;
          const cellData = dataSource[rowIndex];
          return (
            <div
              className={classNames("virtual-table-cell", {
                "virtual-table-cell-last":
                  columnIndex === mergedColumns.length - 1
              })}
              style={{
                ...style,
                borderBottom: "1px solid #f0f0f0",
                borderRight:
                  columnIndex === 2 || columnIndex === 5
                    ? "1px solid #f0f0f0"
                    : "none"
              }}
            >
              <div style={{ padding: size.m2, paddingRight: "0px" }}>
                {renderer(cellData, cellData, rowIndex) as React.ReactNode}
              </div>
            </div>
          );
        }}
      </Grid>
    </div>
  );
};

interface IProps {
  data?: VectorList;
  isModalOpen: boolean;
  closeModal: () => void;
}
const DvaVectorsModal: React.FC<IProps> = (props) => {
  const { data, isModalOpen, closeModal } = props;
  const { t } = useTranslation();

  const { vectorTimespan, vectorSize } = useSelector(selectVectorData);

  const columns: ColumnsType<VectorData> = [
    {
      title: t("Timespan"),
      dataIndex: "xData",
      key: "xStartEnd",
      render: (data?: VectorData) => {
        const { xData } = data ?? {};
        return (
          <>
            {!isUndefined(xData) && (
              <Text>
                {dvaSampleToMsTickFormaterWithPostfix(xData.startEnd[0]) +
                  " - " +
                  dvaSampleToMsTickFormaterWithPostfix(xData.startEnd[1])}
              </Text>
            )}
          </>
        );
      }
    },
    {
      title: t("headerCurrentValue"),
      dataIndex: "xData",
      key: "xAlarm",
      render: (data?: VectorData) => (
        <>
          {!isUndefined(data?.xData) && (
            <Text>
              {data.xData.alarm}
              {"g"}
            </Text>
          )}
        </>
      )
    },
    {
      title: t("Difference"),
      dataIndex: "xData",
      key: "xDifference",
      render: (data?: VectorData) => (
        <>
          {!isUndefined(data?.xData) && (
            <Text>
              {data.xData.alarmDiff}
              {"g"}
            </Text>
          )}
        </>
      )
    },
    {
      title: t("Timespan"),
      dataIndex: "yData",
      key: "yStartEnd",
      render: (data?: VectorData) => (
        <>
          {!isUndefined(data?.yData) && (
            <Text>
              {dvaSampleToMsTickFormaterWithPostfix(data.yData.startEnd[0]) +
                " - " +
                dvaSampleToMsTickFormaterWithPostfix(data.yData.startEnd[1])}
            </Text>
          )}
        </>
      )
    },
    {
      title: t("headerCurrentValue"),
      dataIndex: "yData",
      key: "yAlarm",
      render: (data?: VectorData) => (
        <>
          {data?.yData && (
            <Text>
              {data.yData.alarm}
              {"g"}
            </Text>
          )}
        </>
      )
    },
    {
      title: t("Difference"),
      dataIndex: "yData",
      key: "yDifference",
      render: (data?: VectorData) => (
        <>
          {data?.yData && (
            <Text>
              {data.yData.alarmDiff}
              {"g"}
            </Text>
          )}
        </>
      )
    },
    {
      title: t("Timespan"),
      dataIndex: "zData",
      key: "zStartEnd",
      render: (data?: VectorData) => (
        <>
          {!isUndefined(data?.zData) && (
            <Text>
              {dvaSampleToMsTickFormaterWithPostfix(data.zData.startEnd[0]) +
                " - " +
                dvaSampleToMsTickFormaterWithPostfix(data.zData.startEnd[1])}
            </Text>
          )}
        </>
      )
    },
    {
      title: t("headerCurrentValue"),
      dataIndex: "zData",
      key: "zAlarm",
      render: (data?: VectorData) => (
        <>
          {data?.zData && (
            <Text>
              {data.zData.alarm}
              {"g"}
            </Text>
          )}
        </>
      )
    },
    {
      title: t("Difference"),
      dataIndex: "zData",
      key: "zDifference",
      render: (data?: VectorData) => (
        <>
          {data?.zData && (
            <Text>
              {data.zData.alarmDiff}
              {"g"}
            </Text>
          )}
        </>
      )
    }
  ];

  const { width, ref } = useResizeDetector();
  const tableWidth = !isUndefined(width) ? width : 900;
  const tableHeight = 650;

  /** Table content has a minHeight of 120px or else the same px-height as its row - 87 px*/
  const scrollHeight = () => {
    if (tableHeight < 200) {
      return 120;
    } else {
      return tableHeight - 55;
    }
  };

  const windowWidth = window.innerWidth;

  const rowHeights = data?.data.map(() => {
    if (windowWidth < 1033) {
      return 77;
    }
    return 55;
  });

  const columnSection = (tableWidth - 16) / 3;
  const timespanHeaderStyle: React.CSSProperties = {
    width: columnSection * timespanWidth,
    paddingRight: 0,
    fontWeight: 600,
    textAlign: "start"
  };
  const currentDifferenceHeaderStyle: React.CSSProperties = {
    width: columnSection * currentDifferenceWidth,
    paddingRight: 0,
    fontWeight: 600,
    textAlign: "start"
  };

  const modalWidth = windowWidth > 1600 ? 1550 : "calc(100vw - 50px)";

  return (
    <>
      <StandardModal
        title={t("VectorsList")}
        open={isModalOpen}
        onCancel={() => closeModal()}
        footer={null}
        width={modalWidth}
        zIndex={1045}
        styles={{ body: { padding: 0 } }}
        destroyOnClose={true}
      >
        <Row ref={ref} style={{}}>
          <Row
            justify="space-between"
            align="middle"
            style={{ marginBottom: size.m2, paddingLeft: size.l1 }}
          >
            {`${t("CurrentVectorSettings")}: ${t("Timespan")}: ${vectorTimespan} ms, ${t("VectorSize")}: ${vectorSize}g`}
          </Row>

          <Atable style={{ width: "calc(100% - 16px)" }}>
            <Athead>
              <tr>
                <Ath style={{ textAlign: "center" }}>{t("xAcc_tooltip")}</Ath>
                <Ath style={{ textAlign: "center" }}>{t("yAcc_tooltip")}</Ath>
                <Ath style={{ textAlign: "center" }}>{t("zAcc_tooltip")}</Ath>
              </tr>
            </Athead>
          </Atable>
          <Atable style={{ width: "calc(100% - 16px)" }}>
            <Athead>
              <tr>
                <Ath style={{ padding: 0 }}>
                  <Ath style={timespanHeaderStyle}>{t("Timespan")}</Ath>
                  <Ath style={currentDifferenceHeaderStyle}>
                    {t("headerCurrentValue")}
                  </Ath>
                  <Ath style={currentDifferenceHeaderStyle}>
                    {t("Difference")}
                  </Ath>
                </Ath>
                <Ath style={{ padding: 0 }}>
                  <Ath style={timespanHeaderStyle}>{t("Timespan")}</Ath>
                  <Ath style={currentDifferenceHeaderStyle}>
                    {t("headerCurrentValue")}
                  </Ath>
                  <Ath style={currentDifferenceHeaderStyle}>
                    {t("Difference")}
                  </Ath>
                </Ath>
                <Ath style={{ padding: 0 }}>
                  <Ath style={timespanHeaderStyle}>{t("Timespan")}</Ath>
                  <Ath style={currentDifferenceHeaderStyle}>
                    {t("headerCurrentValue")}
                  </Ath>
                  <Ath style={currentDifferenceHeaderStyle}>
                    {t("Difference")}
                  </Ath>
                </Ath>
              </tr>
            </Athead>
          </Atable>
          <VirtualTable
            dataSource={data?.data}
            columns={columns}
            height={scrollHeight()}
            width={tableWidth}
            rowHeights={rowHeights}
          />
        </Row>
      </StandardModal>
    </>
  );
};

export default DvaVectorsModal;
