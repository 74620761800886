import React, { useState } from "react";
import {
  AuditOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
  UserSwitchOutlined
} from "@ant-design/icons";
import { Row, Col, Space, Typography, Input, Popover, Menu, Table } from "antd";
import { LiftedCard } from "../Common/CommonCards";
import { LargeHeaderTitle } from "../Common/CommonFonts";
import { useDispatch, useSelector } from "react-redux";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { DangerButtonSmall, NormalButtonSmall } from "../Common/CommonButtons";
import {
  LicenseWithCost,
  Tokens,
  useAssignLicenseToUserMutation,
  useImpersonateMutation,
  useMobitronGetCompaniesQuery,
  useMobitronGetLicensesQuery,
  useMobitronGetUsersQuery,
  useRemoveLicenseFromUserMutation
} from "../../state/cargologRestApi";
import { isNil, isUndefined } from "lodash-es";
import CreateUserModal from "../Modals/CreateUserModal";
import {
  openEditUserModal,
  openRemoveUserModal,
  setUserToRemove,
  setUser,
  openViewUserStatisticsModal,
  setUserToViewStatistics
} from "../../state/modalsSlice";
import RemoveUserModal from "../Modals/RemoveUserModal";
import EditUserModal from "../Modals/EditUserModal";
import dayjs from "dayjs";
import {
  selectImpersonate,
  setImpersonatedUserId,
  setIsImpersonating,
  setSessionTokens
} from "../../state/persistantStateSlice";
import { useNavigate } from "react-router";
import {
  impersonatingHeight143,
  impersonatingHeight320,
  size
} from "../../helpers/pageHelper";
import { getUser } from "../../state/sessionSlice";
import { clearSessionAndDataImp } from "../../App";
import { UserStatistic } from "../../state/cargologRestApi";
import ViewUserStatisticsModal from "../Modals/ViewUserStatisticsModal";

const { Text, Title } = Typography;

// User with statistic and licenses for statistics modal
export interface UserWithStatisticAndLicenses {
  userId: string;
  firstName: string;
  lastName: string;
  userStatistic: UserStatistic;
  userLicenses: LicenseWithCost[];
}

/** All the fields for table used in this component */
export interface ITableData {
  key: string;
  companyId: string;
  company: string;
  name: string;
  email: string;
  userdata: IUserData;
  licenses?: {
    current?: LicenseWithCost[];
    userId: string;
  };
  lastSeen: string;
  actions?: IUserActions;
}

/** user data fields in the table */
interface IUserData {
  name: string;
  email: string;
  userId: string;
}

/** all available actions for a field in the table */
interface IUserActions {
  statistics: () => void;
  edit: () => void;
  remove: () => void;
  impersonate: () => void;
  userId: string;
}

const MobitronAdminUsers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isImpersonating, impersonatedUserId } =
    useSelector(selectImpersonate);
  const { userId } = useSelector(getUser);

  // Load from API
  const { data: usersData, isLoading: usersIsLoading } =
    useMobitronGetUsersQuery();
  const { data: allCompanies, isLoading: companyIsLoading } =
    useMobitronGetCompaniesQuery();
  const { data: allLicenses, isLoading: licensesIsLoading } =
    useMobitronGetLicensesQuery();

  const [removeLicenseFromUser] = useRemoveLicenseFromUserMutation();
  const [assignLicenseToUser] = useAssignLicenseToUserMutation();
  const [impersonate, requestStatus] = useImpersonateMutation();
  const { isLoading: isLoadingImpersonate } = requestStatus;

  const timezoneOffset = new Date().getTimezoneOffset();
  const licenseInfo = (license: LicenseWithCost) => {
    const expires = dayjs
      .utc(license.endDate)
      .subtract(timezoneOffset, "minutes")
      .format("YYYY-MM-DD");
    return (
      <>
        <Text strong>{license.text}</Text>{" "}
        <Text type="secondary">({license.id})</Text>{" "}
        <Text>
          {license.renewAuto ? t("tableRenews") : t("tableExpires")}: {expires}
        </Text>
      </>
    );
  };

  const availableLicensesMenu = (userId: string, companyId: string) => {
    const availableLicenses = allLicenses?.filter(
      (license) =>
        !license.userId &&
        license.companyId === companyId &&
        license.isActivated
    );

    return (
      <Menu style={{ maxHeight: 200, overflowY: "auto", border: 0 }}>
        {availableLicenses && availableLicenses?.length > 0 ? (
          availableLicenses?.map((license, index) => (
            <Menu.Item
              key={index}
              onClick={() => addLicense(license.id, userId)}
              style={{
                margin: 0,
                padding: 0,
                height: "24px",
                lineHeight: "24px"
              }}
            >
              {licenseInfo(license)}
            </Menu.Item>
          ))
        ) : (
          <Menu.Item disabled>
            <Text type="secondary">
              {t("ThereAreNoAvailableLicensesForThisCompany")}
            </Text>
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const removeLicense = (licenseId: string) => {
    removeLicenseFromUser({ licenseId: licenseId });
  };

  const addLicense = (licenseId: string, userId: string) => {
    assignLicenseToUser({
      userId: userId,
      licenseId: licenseId
    });
  };

  const impersonateUser = (userId: string) => {
    impersonate({ userId }).then((result) => {
      if ("data" in result) {
        const sessionToken: Tokens = {
          accessToken: result.data.accessToken,
          refreshToken: result.data.refreshToken,
          refreshTokenExpiry: result.data.refreshTokenExpiry
        };
        clearSessionAndDataImp(dispatch);

        dispatch(setSessionTokens(sessionToken));
        dispatch(setIsImpersonating(true));

        navigate("/landing");
      } else {
        dispatch(setImpersonatedUserId(undefined));
        console.log("Impersonate error:", result);
      }
    });
  };

  const columns: ColumnsType<ITableData> = [
    {
      title: t("Company"),
      dataIndex: "company",
      key: "company",
      ellipsis: true,
      sorter: (a, b) => {
        if (a.company < b.company) {
          return -1;
        }
        if (a.company > b.company) {
          return 1;
        }
        return 0;
      },
      render: (company) => <Text strong>{company}</Text>
    },
    {
      title: t("tableName"),
      dataIndex: "userdata",
      key: "userdata",
      ellipsis: true,
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      },
      render: (userdata: IUserData) => (
        <>
          <Popover
            content={<>{userdata.userId}</>}
            trigger="hover"
            getPopupContainer={(triggerNode: HTMLElement) =>
              triggerNode.parentNode as HTMLElement
            }
          >
            <Title level={5} style={{ marginBlock: 0 }}>
              {userdata.name}
            </Title>
            <Text type="secondary" style={{ whiteSpace: "break-spaces" }}>
              {userdata.email}
            </Text>
          </Popover>
        </>
      )
    },
    {
      title: t("Licenses"),
      dataIndex: "licenses",
      key: "licenses",
      width: 400,
      render: (licenses) => {
        let buffer: any[] = [];
        let index: number = 0;

        // Available licenses
        buffer.push(
          <React.Fragment key={index++}>
            <Popover
              trigger={["click"]}
              // Keeps Dropdown menu in place when scrolling the table
              getPopupContainer={(triggerNode: HTMLElement) =>
                tableData && tableData.length > 4
                  ? (triggerNode.parentNode as HTMLElement)
                  : document.body
              }
              destroyTooltipOnHide={{ keepParent: false }}
              content={() =>
                availableLicensesMenu(licenses?.userId, licenses.companyId)
              }
              placement="bottomLeft"
              overlayStyle={{ maxHeight: 200, padding: 0 }}
            >
              <div style={{ width: 280 }}>
                <NormalButtonSmall icon={<PlusOutlined />}>
                  {t("btnAddALicense")}
                </NormalButtonSmall>
              </div>
            </Popover>
          </React.Fragment>
        );

        //Assign licenses
        if (licenses && licenses.current && licenses.current.length > 0) {
          buffer.push(
            licenses.current.map((license: LicenseWithCost) => {
              const expired = dayjs().diff(license.endDate, "days") >= 0;
              return (
                <React.Fragment key={index++}>
                  <div style={{ width: "100%", paddingLeft: 30 }}>
                    <Text strong>{license.text} </Text>
                    {expired && <Text type="danger">({t("Expired")}) </Text>}
                    <Text type="secondary">{license.id} </Text>
                    <DangerButtonSmall
                      onClick={() => removeLicense(license.id)}
                    >
                      {t("Remove")}
                    </DangerButtonSmall>
                  </div>
                </React.Fragment>
              );
            })
          );
        }
        return buffer;
      }
    },
    {
      title: t("LastSeen"),
      dataIndex: "lastSeen",
      key: "lastSeen",
      ellipsis: true,
      sorter: (a, b) => {
        const lastSeenA = a.lastSeen === "Invalid Date" ? "1" : a.lastSeen;
        const lastSeenB = b.lastSeen === "Invalid Date" ? "1" : b.lastSeen;

        if (lastSeenA < lastSeenB) {
          return -1;
        }
        if (lastSeenA > lastSeenB) {
          return 1;
        }
        return 0;
      }
    },
    {
      title: t("tableActions"),
      dataIndex: "actions",
      key: "actions",
      width: 110,
      ellipsis: true,
      render: (actions: IUserActions) => {
        if (isUndefined(actions)) {
          return <></>;
        } else {
          return (
            <>
              <Row justify="start">
                <NormalButtonSmall
                  icon={<EditOutlined />}
                  onClick={actions.edit}
                >
                  {t("genEdit")}
                </NormalButtonSmall>
              </Row>

              <Row style={{ paddingTop: size.s1 }}>
                <NormalButtonSmall
                  icon={<AuditOutlined />}
                  onClick={actions.statistics}
                >
                  {t("Statistics")}
                </NormalButtonSmall>
              </Row>

              <Row style={{ paddingTop: size.s1 }}>
                <DangerButtonSmall
                  icon={<DeleteOutlined />}
                  disabled={actions.userId === userId}
                  onClick={actions.remove}
                >
                  {t("genDelete")}
                </DangerButtonSmall>
              </Row>

              <Row style={{ paddingTop: size.s1 }}>
                <NormalButtonSmall
                  icon={<UserSwitchOutlined />}
                  disabled={
                    isImpersonating ||
                    actions.userId === userId ||
                    (isLoadingImpersonate &&
                      actions.userId !== impersonatedUserId)
                  }
                  loading={
                    isLoadingImpersonate &&
                    actions.userId === impersonatedUserId
                  }
                  onClick={actions.impersonate}
                >
                  {t("Impersonate")}
                </NormalButtonSmall>
              </Row>
            </>
          );
        }
      }
    }
  ];

  const [stringValue, setStringValue] = useState("");

  const tableData: ITableData[] = usersData
    ? usersData
        .filter(
          (user) =>
            stringValue === "" ||
            user.firstName.toLowerCase().includes(stringValue.toLowerCase()) ||
            user.lastName.toLowerCase().includes(stringValue.toLowerCase()) ||
            user.email.toLowerCase().includes(stringValue.toLowerCase()) ||
            user.company.name.toLowerCase().includes(stringValue.toLowerCase())
        )
        .map((user) => {
          const company = allCompanies?.filter(
            (company) => company.id === user.companyId
          )[0];
          const userLicenses = allLicenses?.filter(
            (license) =>
              license.userId === user.userId && isNil(license.projectId)
          );
          const companyName = company?.name ?? "";
          const fullname = user.firstName + " " + user.lastName;
          const lastSeen =
            isUndefined(user.lastActivity) ||
            user.lastActivity === "0001-01-01T00:00:00"
              ? t("Never")
              : dayjs
                  .utc(user.lastActivity)
                  .subtract(timezoneOffset, "minutes")
                  .format("YYYY-MM-DD, HH:mm");
          return {
            key: user.userId,
            companyId: user.companyId,
            company: companyName,
            name: fullname,
            email: user.email,
            userdata: {
              name: fullname,
              email: user.email,
              userId: user.userId
            },
            licenses: {
              companyId: user.companyId,
              current: userLicenses,
              userId: user.userId
            },
            lastSeen: lastSeen,
            actions: {
              statistics: () => {
                const userWithLicenses: UserWithStatisticAndLicenses = {
                  userId: user.userId,
                  firstName: user.firstName,
                  lastName: user.lastName,
                  userStatistic: user.userStatistic,
                  userLicenses:
                    allLicenses?.filter(
                      (license) => license.userId === user.userId
                    ) ?? []
                };

                dispatch(setUserToViewStatistics(userWithLicenses));
                dispatch(openViewUserStatisticsModal());
              },
              edit: () => {
                dispatch(setUser(user));
                dispatch(openEditUserModal());
              },
              remove: () => {
                dispatch(setUserToRemove(user));
                dispatch(openRemoveUserModal());
              },
              impersonate: () => {
                dispatch(setImpersonatedUserId(user.userId));
                impersonateUser(user.userId);
              },
              userId: user.userId
            }
          };
        })
    : [];

  return (
    <>
      <LiftedCard
        style={{
          margin: size.m1,
          height: impersonatingHeight143(isImpersonating)
        }}
      >
        <Row justify="space-between" style={{ marginBottom: size.l2 }}>
          <Col>
            <Space>
              <LargeHeaderTitle>{t("AllUsers")}</LargeHeaderTitle>
            </Space>
          </Col>
          <Col>
            <Input
              placeholder={t("FilterUsers")}
              prefix={<SearchOutlined />}
              onChange={(e) => setStringValue(e.target.value)}
            />
          </Col>
        </Row>
        <Row style={{}}>
          <Table
            columns={columns}
            dataSource={tableData}
            scroll={{
              x: "calc(100vw - 300px)",
              y: impersonatingHeight320(isImpersonating)
            }}
            style={{ width: "100%" }}
            size="small"
            tableLayout="auto"
            loading={usersIsLoading || companyIsLoading || licensesIsLoading}
            pagination={{
              defaultPageSize: 20,
              hideOnSinglePage: false,
              showSizeChanger: true
            }}
          />
        </Row>
      </LiftedCard>
      <EditUserModal />
      <RemoveUserModal />
      <ViewUserStatisticsModal />
    </>
  );
};

export default MobitronAdminUsers;
