import React from "react";
import { Typography, Statistic, Progress, Table } from "antd";
import { size, impersonateBannerHeight } from "../../helpers/pageHelper";
import { ColumnsType } from "antd/lib/table";
import {
  tableDataType,
  openProjectModal,
  setSelectedProjectId,
  setProjectModalTab
} from "../../state/projectSlice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Project, useGetUsersQuery } from "../../state/cargologRestApi";
import {
  FileOutlined,
  FormOutlined,
  TeamOutlined,
  WarningOutlined,
  AimOutlined,
  CheckCircleOutlined
} from "@ant-design/icons";
import {
  warningColor,
  renderStatus,
  calcTimeDiff
} from "../../pages/ProjectsPage";
import { LiftedCard } from "../Common/CommonCards";
import dayjs from "dayjs";
import { isUndefined } from "lodash-es";
import { selectImpersonate } from "../../state/persistantStateSlice";

const { Text, Link, Paragraph } = Typography;

interface IProps {
  projects?: Project[];
  searchQuery: string;
  filterCompany: string[];
  filterUsers: string[];
  filterStatus: number[];
}

const ProjectsTable = (props: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isImpersonating } = useSelector(selectImpersonate);

  const { searchQuery, projects, filterStatus, filterCompany, filterUsers } =
    props;

  const { data: companyUsers } = useGetUsersQuery();

  /** Categories and how they should be rendered */
  const columns: ColumnsType<tableDataType> = [
    {
      title: t("Name"),
      dataIndex: "project",
      key: "project.project",
      width: "15%",
      onCell: () => {
        return {
          style: { cursor: "pointer" },
          onClick: () => {
            dispatch(openProjectModal());
          }
        };
      },
      render: (value: Project) => (
        <>
          <Link strong>{value.title}</Link>
        </>
      ),
      sorter: (a, b) => a.project.title.localeCompare(b.project.title, "sv")
    },
    {
      title: t("Description"),
      dataIndex: "description",
      key: "description",
      width: "20%",
      render: (value: string) => (
        <Paragraph style={{ margin: 0 }} ellipsis={{ rows: 2 }}>
          {value}
        </Paragraph>
      ),
      sorter: (a, b) =>
        (a.description ?? "").localeCompare(b.description ?? "", "sv")
    },
    {
      title: t("Time"),
      dataIndex: "time",
      key: "time",
      width: "15%",
      render: (time: { timePercent: number | null; status: number }) => {
        return !isUndefined(time.timePercent) ? (
          <Progress
            percent={time.timePercent ?? 0}
            type="line"
            size={["100%", 8]}
            strokeColor={{
              "0%": "#108ee9",
              "100%": "#74b7e8"
            }}
            status={time.status === 1 ? "active" : "normal"}
          />
        ) : (
          <Text type="secondary">{t("NoActiveLoggers")}</Text>
        );
      },
      sorter: (a, b) => (a.time?.timePercent ?? 0) - (b.time?.timePercent ?? 0)
    },
    {
      title: t("Alarms"),
      dataIndex: "alarmsCount",
      width: "10%",
      key: "alarmsCount",
      onCell: () => {
        return {
          style: { cursor: "pointer" },
          onClick: () => {
            dispatch(setProjectModalTab("alarms"));
            dispatch(openProjectModal());
          }
        };
      },
      render: (alarmsCount: number) => (
        <Statistic
          value={alarmsCount}
          prefix={
            alarmsCount > 0 ? <WarningOutlined /> : <CheckCircleOutlined />
          }
          valueStyle={{ color: warningColor(alarmsCount > 0) }}
        />
      ),
      sorter: (a, b) => a.alarmsCount - b.alarmsCount
    },
    {
      title: t("Files"),
      dataIndex: "filesCount",
      width: "7%",
      key: "filesCount",
      onCell: () => {
        return {
          style: { cursor: "pointer" },
          onClick: () => {
            dispatch(setProjectModalTab("recordings"));
            dispatch(openProjectModal());
          }
        };
      },
      render: (filesCount: number) => (
        <Statistic value={filesCount} prefix={<FileOutlined />} />
      ),
      sorter: (a, b) => a.filesCount - b.filesCount
    },
    {
      title: t("Notes"),
      dataIndex: "notesCount",
      key: "notesCount",
      width: "7%",
      onCell: () => {
        return {
          style: { cursor: "pointer" },
          onClick: () => {
            dispatch(setProjectModalTab("notes"));
            dispatch(openProjectModal());
          }
        };
      },
      render: (notesCount: number) => (
        <Statistic value={notesCount} prefix={<FormOutlined />} />
      ),
      sorter: (a, b) => a.notesCount - b.notesCount
    },
    {
      title: t("Recordings"),
      dataIndex: "deviceCount",
      width: "7%",
      key: "deviceCount",
      onCell: () => {
        return {
          style: { cursor: "pointer" },
          onClick: () => {
            dispatch(setProjectModalTab("recordings"));
            dispatch(openProjectModal());
          }
        };
      },
      render: (deviceCount: number) => (
        <Statistic value={deviceCount} prefix={<AimOutlined />} />
      ),
      sorter: (a, b) => a.deviceCount - b.deviceCount
    },
    {
      title: t("People"),
      dataIndex: "people",
      width: "7%",
      key: "people",
      onCell: () => {
        return {
          style: { cursor: "pointer" },
          onClick: () => {
            dispatch(setProjectModalTab("people"));
            dispatch(openProjectModal());
          }
        };
      },
      render: (people: number) => (
        <Statistic value={people} prefix={<TeamOutlined />} />
      ),
      sorter: (a, b) => a.people - b.people
    },
    {
      title: t("ProjectStatus"),
      dataIndex: "status",
      key: "files",
      width: "10%",
      onCell: () => {
        return {
          style: { cursor: "pointer" },
          onClick: () => {
            dispatch(setProjectModalTab("status"));
            dispatch(openProjectModal());
          }
        };
      },
      render: (status: number) => (
        <Text strong style={{ color: renderStatus(t, status).color }}>
          {renderStatus(t, status)?.string}
        </Text>
      ),
      sorter: (a, b) =>
        renderStatus(t, a.status).string.localeCompare(
          renderStatus(t, b.status).string
        )
    },
    {
      title: t("LatestUpload"),
      dataIndex: "latestUpload",
      width: "11%",
      render: (latestUpload: string) => <Text>{latestUpload}</Text>,
      sorter: (a, b) => {
        const dateA = dayjs(a.latestUpload);
        const dateB = dayjs(b.latestUpload);

        // If the date is invalid, set it to the smallest possible number to sort it last when sorting in descending order
        const timestampA = dateA.isValid()
          ? dateA.unix()
          : Number.MIN_SAFE_INTEGER;
        const timestampB = dateB.isValid()
          ? dateB.unix()
          : Number.MIN_SAFE_INTEGER;

        return timestampA - timestampB;
      }
    }
  ];

  const formatLatestUploadDate = (project: Project) => {
    if (project.latestUpload) {
      return dayjs(project.latestUpload).format("YYYY-MM-DD HH:mm:ss");
    } else {
      return t("NoDataUploaded");
    }
  };

  const tableData: tableDataType[] = projects
    ? projects
        .filter(
          (project) =>
            filterStatus.length === 0 || filterStatus.includes(project.status)
        )
        .filter(
          (project) =>
            filterCompany.length === 0 ||
            (project.companyName && filterCompany.includes(project.companyName))
        )
        .filter((project) => {
          const createdByUserEmail = companyUsers?.find(
            (user) => user.userId === project?.createdByUserId
          )?.email;

          return (
            filterUsers.length === 0 ||
            (project.projectUsers &&
              project.projectUsers.length > 0 &&
              project.projectUsers.find(
                (e) =>
                  e.user.email ===
                  filterUsers.find((filter) => filter === e.user.email)
              )) ||
            (createdByUserEmail &&
              filterUsers.find((filter) => filter === createdByUserEmail))
          );
        })
        .filter(
          (project) =>
            searchQuery === "" ||
            (project.description &&
              project.description
                .toLowerCase()
                .includes(searchQuery.toLowerCase())) ||
            project.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            renderStatus(t, project.status)
              .string?.toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            project.companyName
              ?.toLowerCase()
              .includes(searchQuery.toLocaleLowerCase())
        )
        .map((project) => {
          const timezoneOffset = new Date().getTimezoneOffset();
          const startTime = dayjs
            .utc(project?.start)
            .subtract(timezoneOffset, "minutes")
            .format("YYYY-MM-DD");
          const endTime = dayjs
            .utc(project?.end)
            .subtract(timezoneOffset, "minutes")
            .format("YYYY-MM-DD");

          const timePercent =
            startTime !== "Invalid Date" || endTime !== "Invalid Date"
              ? calcTimeDiff(startTime, endTime)
              : undefined;

          return {
            project: project,
            description: project.description,
            distance: undefined,
            time: {
              timePercent: timePercent,
              status: project.status
            },
            alarmsCount: project.alarmCount,
            filesCount: project.datXFileCount,
            notesCount: project.projectNotesCount,
            deviceCount: project.deviceCount,
            people: project.projectUsers?.length ?? 0,
            status: project.status,
            companyName: project.companyName,
            latestUpload: formatLatestUploadDate(project)
          };
        })
        .reverse()
    : [];

  return (
    <>
      <LiftedCard
        style={{
          marginInline: size.m1
        }}
      >
        <Table
          columns={columns}
          dataSource={tableData}
          scroll={{
            x: "calc(100vw - 300px)",
            y: `calc(100vh - (343px ${isImpersonating ? `+ ${impersonateBannerHeight}px` : ""}))`
          }}
          style={{ width: "100%" }}
          pagination={{
            hideOnSinglePage: true,
            pageSizeOptions: ["10", "25", "50"]
          }}
          onRow={(val) => ({
            onClick: () => {
              dispatch(setSelectedProjectId(val.project.id));
            }
          })}
        />
      </LiftedCard>
    </>
  );
};

export default ProjectsTable;
