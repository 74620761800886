import React, { useEffect, useState } from "react";
import { Col, Dropdown, MenuProps, Row, Space } from "antd";
import {
  CloseOutlined,
  DeliveredProcedureOutlined,
  SaveOutlined
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { saveAsParx } from "../../helpers/paramsHelper";
import { HeaderTitle } from "../Common/CommonFonts";
import { MenuButton, NormalButtonSmall } from "../Common/CommonButtons";
import {
  selectTargetDevice,
  setUseDefaultTargetDevice
} from "../../state/paramsPageSlice";
import { selectRecordingParameters } from "../../state/openParxSlice";
import { Help } from "../MicroComponents/Help";
import ParamsBuilderStats from "./ParamsBuilderStats";
import { insertIf } from "../../utils/generalUtils";
import { CheckUserRightsAccess } from "../MicroComponents/LicenseAccess";
import { openSaveParxTemplateModal } from "../../state/modalsSlice";
import { selectHelp } from "../../state/helpSlice";

interface IProps {
  contentWidth: number;
}
const ParamsPageHeader: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const vmRecParams = useSelector(selectRecordingParameters);
  const { isHelpModeActive } = useSelector(selectHelp);
  const targetDevice = useSelector(selectTargetDevice);
  const hasSerial = targetDevice.serial && targetDevice.serial !== "default";

  const dispatch = useDispatch();

  const handleSaveParx = () => {
    saveAsParx(vmRecParams, targetDevice);
  };

  let serial = targetDevice.serial;
  if (serial === "default") {
    serial = t("NoDeviceSelected");
  } else if (serial === undefined) {
    serial = t("none");
  }

  const items: MenuProps["items"] = [
    {
      label: (
        <>
          <SaveOutlined /> {t("LocalFile")}
        </>
      ),
      key: "local",
      onClick: handleSaveParx
    },
    ...insertIf(CheckUserRightsAccess("ADB"), {
      label: (
        <>
          <DeliveredProcedureOutlined /> {t("Template")}
        </>
      ),
      key: "template",
      onClick: () => {
        dispatch(openSaveParxTemplateModal());
      }
    })
  ];

  return (
    <>
      {/* outer container, makes sure that the inner conatiner is centered */}
      <Row
        justify="center"
        align="middle"
        style={{
          width: "100%",
          height: "100%"
        }}
      >
        {/* inner container with a max width */}
        <Row
          justify="space-between"
          style={{
            width: "100%",
            maxWidth: props.contentWidth
          }}
        >
          {/* left column of content */}
          <Col>
            <Space align="center" style={{ height: "100%" }}>
              <HeaderTitle strong>{`${t("serialIdTitle")}: `} </HeaderTitle>
              <HeaderTitle type="secondary">{serial}</HeaderTitle>
              {hasSerial && (
                <NormalButtonSmall
                  icon={<CloseOutlined />}
                  onClick={() => dispatch(setUseDefaultTargetDevice())}
                />
              )}
            </Space>
          </Col>
          <ParamsBuilderStats></ParamsBuilderStats>
          {/* right column of content */}
          <Col>
            <Space align="center" style={{ height: "100%" }}>
              <Dropdown menu={{ items }} trigger={["click"]}>
                {Help(
                  <MenuButton icon={<SaveOutlined />}>
                    {t("SaveAs")}
                  </MenuButton>,
                  t("ClickHereToSaveParamsAsAFile"),
                  "left",
                  isHelpModeActive
                )}
              </Dropdown>
            </Space>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default ParamsPageHeader;
