import React from "react";
import { isUndefined } from "lodash-es";
import { useTranslation } from "react-i18next";
import { size } from "../../helpers/pageHelper";
import { QuickReportData } from "./QuickReportDashboardCard";
import { useSelector } from "react-redux";
import {
  selectActiveDatxFile,
  selectExternalFilters
} from "../../state/openDatxSlice";
import { RecordingDataBlockUnique } from "../../helpers/datasetHelper";
import {
  RHTemp1M,
  RHTemp1S,
  RHTemp2M,
  RHTemp3M,
  RHTemp4M,
  RHTemp5M,
  RHTemp6M,
  RHTemp7M,
  RHTemp8M
} from "../../models/FAT100DataTypes";
import { getSensorNrFromId } from "../../helpers/paramsHelper";
import _ from "lodash";
import QuickReportCircleIcon from "../../icons/QuickReportCircleIcon";
import QuickReportWarningIcon from "../../icons/QuickReportWarningIcon";

type SensorType =
  | "acc"
  | "temp"
  | "rh"
  | "angle"
  | "pressure"
  | "extTemp"
  | "extRh"
  | "";

interface Params {
  sensorTypeId?: number;
  highAlarm?: number;
  lowAlarm?: number;
  xAlarm?: number;
  yAlarm?: number;
  zAlarm?: number;
}

interface Sensor {
  sensorName: string;
  sensorType: SensorType;
  measurements?: (number | undefined)[];
  check?: boolean;
  params?: Params;
  warning?: boolean;
}

interface IProps {
  data: QuickReportData;
  fileId: string;
}

const QuickReport = (props: IProps) => {
  const { t } = useTranslation();
  const { fileId, data } = props;
  const datXData = useSelector(selectActiveDatxFile);
  const filter = useSelector(selectExternalFilters(fileId));

  const angleParams = data.recParams?.AngleParams;
  const accParams = data.recParams?.AccParams;
  const tempParams = data.recParams?.TempParams;
  const rhParams = data.recParams?.RhParams;
  const pressureParams = data.recParams?.PressureParams;
  const extSensorParams = data.recParams?.ExternalSensorParams;

  type dataBlock = RecordingDataBlockUnique[] | undefined;
  const calculateNumOfAcc = () => {
    const acc: dataBlock[] = [];
    acc.push(
      datXData?.datxContent.data.filter(
        (t) =>
          t.timestamp >= datXData.activeDomain[0] &&
          t.timestamp <= datXData.activeDomain[1] &&
          !isUndefined(t.xAcc) &&
          !isUndefined(t.yAcc) &&
          !isUndefined(t.zAcc)
      )
    );
    return acc.map((e) => e?.length);
  };

  const calculateNumOfTemp = () => {
    const temp: dataBlock[] = [];
    temp.push(
      datXData?.datxContent.data.filter(
        (t) =>
          t.timestamp >= datXData.activeDomain[0] &&
          t.timestamp <= datXData.activeDomain[1] &&
          !isUndefined(t.temp)
      )
    );
    return temp.map((e) => e?.length);
  };

  const calculateNumOfRh = () => {
    const rh: dataBlock[] = [];
    rh.push(
      datXData?.datxContent.data.filter(
        (t) =>
          t.timestamp >= datXData.activeDomain[0] &&
          t.timestamp <= datXData.activeDomain[1] &&
          !isUndefined(t.rh)
      )
    );
    return rh.map((e) => e?.length);
  };

  const calculateNumOfAngle = () => {
    const angle: dataBlock[] = [];
    angle.push(
      datXData?.datxContent.data.filter(
        (t) =>
          t.timestamp >= datXData.activeDomain[0] &&
          t.timestamp <= datXData.activeDomain[1] &&
          !isUndefined(t.angle)
      )
    );
    return angle.map((e) => e?.length);
  };

  const calculateNumOfPressure = () => {
    const pressure: dataBlock[] = [];
    pressure.push(
      datXData?.datxContent.data.filter(
        (t) =>
          t.timestamp >= datXData.activeDomain[0] &&
          t.timestamp <= datXData.activeDomain[1] &&
          !isUndefined(t.pressureRaw)
      )
    );
    return pressure.map((e) => e?.length);
  };

  const calculateNumOfExtTemp = (esti: number) => {
    const extTemp: dataBlock[] = [];
    extTemp.push(
      datXData?.datxContent.data.filter(
        (t) =>
          t.timestamp >= datXData.activeDomain[0] &&
          t.timestamp <= datXData.activeDomain[1] &&
          esti === t.externalTemp?.sensorId &&
          !isUndefined(t.externalTemp?.temp)
      )
    );
    return extTemp.map((e) => e?.length);
  };

  const calculateNumOfExtRh = (esti: number) => {
    const extRh: dataBlock[] = [];
    extRh.push(
      datXData?.datxContent.data.filter(
        (t) =>
          t.timestamp >= datXData.activeDomain[0] &&
          t.timestamp <= datXData.activeDomain[1] &&
          esti === t.externalRh?.sensorId &&
          !isUndefined(t.externalRh?.rh)
      )
    );
    return extRh.map((e) => e?.length);
  };

  const createInternalSensorData = (
    sensorType: SensorType,
    check?: boolean,
    warning?: boolean
  ): Sensor => {
    switch (sensorType) {
      case "acc":
        return {
          sensorName: t("Acceleration"),
          sensorType: sensorType,
          measurements: calculateNumOfAcc(),
          check: check,
          params: {
            xAlarm: accParams?.params.Xalarm,
            yAlarm: accParams?.params.Yalarm,
            zAlarm: accParams?.params.Zalarm
          },
          warning: warning
        };
      case "temp":
        return {
          sensorName: t("Temperature"),
          sensorType: sensorType,
          measurements: calculateNumOfTemp(),
          check: check,
          params: {
            highAlarm: tempParams?.params.highAlarm,
            lowAlarm: tempParams?.params.lowAlarm
          },
          warning: warning
        };
      case "rh":
        return {
          sensorName: t("Humidity"),
          sensorType: sensorType,
          measurements: calculateNumOfRh(),
          check: check,
          params: {
            highAlarm: rhParams?.params.highAlarm,
            lowAlarm: rhParams?.params.lowAlarm
          },
          warning: warning
        };
      case "angle":
        return {
          sensorName: t("Angle"),
          sensorType: sensorType,
          measurements: calculateNumOfAngle(),
          check: check,
          params: {
            xAlarm: angleParams?.params.xAlarmLevel,
            yAlarm: angleParams?.params.yAlarmLevel,
            zAlarm: angleParams?.params.zAlarmLevel
          },
          warning: warning
        };
      case "pressure":
        return {
          sensorName: t("Pressure"),
          sensorType: sensorType,
          measurements: calculateNumOfPressure(),
          check: check,
          params: {
            highAlarm: pressureParams?.params.highAlarm,
            lowAlarm: pressureParams?.params.lowAlarm
          },
          warning: warning
        };
      default:
        return {
          sensorName: "",
          sensorType: ""
        };
    }
  };

  /* 
    Sometimes useAcc, useTemp etc. returns true when it doesn't contain any data.
    Checking if warning is undefined as well solves that issue.
  */
  const accCheck = accParams?.useAcc && !isUndefined(data.accWarning);
  const tempCheck = tempParams?.useTemp && !isUndefined(data.tempWarning);
  const rhCheck = rhParams?.useRh && !isUndefined(data.rhWarning);
  // Todo: Angle is not showing as expected in some files.
  // useAngle seems to be false even if it is showing in Primary graph.
  const angleCheck = angleParams?.useAngle && !isUndefined(data.angleWarning);
  const pressureCheck =
    pressureParams?.usePressure && !isUndefined(data.pressureWarning);

  const internalSensors: Sensor[] = [
    createInternalSensorData("acc", accCheck, data.accWarning),
    createInternalSensorData("temp", tempCheck, data.tempWarning),
    createInternalSensorData("rh", rhCheck, data.rhWarning),
    createInternalSensorData("angle", angleCheck, data.angleWarning),
    createInternalSensorData("pressure", pressureCheck, data.pressureWarning)
  ];

  let extSensors: Sensor[] = [];

  extSensorParams?.forEach((extSensor) => {
    const tempWarnings = data.extSensorTempWarnings;
    const rhWarnings = data.extSensorRhWarnings;
    const sensorName = extSensor.params.sensorName;
    const sensorTypeId = extSensor.params.sensorTypeId;

    const getIndexNr = () => {
      switch (sensorTypeId) {
        case RHTemp1S:
          return 0;
        case RHTemp1M:
          return 0;
        case RHTemp2M:
          return 1;
        case RHTemp3M:
          return 2;
        case RHTemp4M:
          return 3;
        case RHTemp5M:
          return 4;
        case RHTemp6M:
          return 5;
        case RHTemp7M:
          return 6;
        case RHTemp8M:
          return 7;
        default:
          return 0;
      }
    };

    // Ext TEMP
    extSensors.push({
      sensorName: sensorName
        ? sensorName
        : "External Temp " + getSensorNrFromId(sensorTypeId),
      sensorType: "extTemp",
      params: {
        sensorTypeId: sensorTypeId,
        highAlarm: extSensor.params.sensorConfig?.tempMax,
        lowAlarm: extSensor.params.sensorConfig?.tempMin
      },
      check:
        !isUndefined(tempWarnings?.[getIndexNr()].warning) &&
        filter.extTemp[sensorTypeId].dataToggle.isActive,
      measurements: calculateNumOfExtTemp(sensorTypeId),
      warning: tempWarnings[getIndexNr()].warning
    });

    // Ext RH
    extSensors.push({
      sensorName: sensorName
        ? sensorName
        : "External Humidity " + getSensorNrFromId(sensorTypeId),
      sensorType: "extRh",
      params: {
        sensorTypeId: sensorTypeId,
        highAlarm: extSensor.params.sensorConfig?.rhMax,
        lowAlarm: extSensor.params.sensorConfig?.rhMin
      },
      check:
        !isUndefined(rhWarnings?.[getIndexNr()].warning) &&
        filter.extRh[sensorTypeId].dataToggle.isActive,
      measurements: calculateNumOfExtRh(sensorTypeId),
      warning: rhWarnings[getIndexNr()].warning
    });
  });

  const allSensorsList = [...internalSensors, ...extSensors];

  /** Only show the sensors that are shown in Primary Graph */
  const filteredSensors = allSensorsList.filter((sensor) => sensor.check);

  /** Split them up in arrays of 5 in each array. Show each set in seperate table/row */
  const sensorsChunk = _.chunk(filteredSensors, 5);

  /** Calulating width of table based on number of columns present in table */
  const calcTableWidth = (quantity: number) => {
    switch (quantity) {
      case 2:
        return "40%";
      case 3:
        return "60%";
      case 4:
        return "80%";
      case 5:
        return "100%";
      default:
        return "20%";
    }
  };

  /**
   * Setting a fixed width on each cell, otherwise the cells have different widths
   * depending on the length of its text
   */
  const calcCellWidth = (quantity: number) => {
    switch (quantity) {
      case 2:
        return "50%";
      case 3:
        return "33%";
      case 4:
        return "25%";
      case 5:
        return "20%";
      default:
        return "100%";
    }
  };

  const headingStyle: React.CSSProperties = {
    padding: size.s1
  };

  const standardStyle: React.CSSProperties = {
    fontSize: "small",
    color: "gray",
    paddingInline: size.s1
  };

  const trStyle: React.CSSProperties = {
    lineHeight: "normal"
  };

  const iconStyle: React.CSSProperties = {
    height: "32px"
  };

  interface TableData {
    quantity: number;
    sensors: Sensor[];
  }
  const tableComponent = (props: TableData) => {
    const { quantity, sensors } = props;

    const setMeasurementType = (
      type: SensorType,
      alarmType: "x" | "y" | "z" | "high" | "low",
      alarmLevel?: number
    ) => {
      const setDirection = (xyz: string) => {
        switch (xyz) {
          case "x":
            return t("XAlarm");
          case "y":
            return t("YAlarm");
          case "z":
            return t("ZAlarm");
          default:
            return "";
        }
      };

      const highLow = alarmType === "high" ? t("HighAlarm") : t("LowAlarm");

      switch (type) {
        case "temp":
          return `${highLow}: ${alarmLevel}°C`;
        case "rh":
          return `${highLow}: ${alarmLevel}%`;
        case "acc":
          return `${setDirection(alarmType)}: ${alarmLevel}g`;
        case "angle":
          return `${setDirection(alarmType)}: ${alarmLevel}°`;
        case "pressure":
          return `${highLow}: ${alarmLevel}mBar`;
        case "extTemp":
          return `${highLow}: ${alarmLevel}°C`;
        case "extRh":
          return `${highLow}: ${alarmLevel}%`;
        default:
          return "";
      }
    };

    return (
      <table
        style={{
          width: calcTableWidth(quantity),
          marginTop: size.xl1,
          textAlign: "center"
        }}
      >
        <thead>
          <tr>
            {sensors.map((sensorParam, index) => {
              const hasMeasurements =
                !isUndefined(sensorParam.measurements) &&
                !isUndefined(sensorParam.measurements[0]) &&
                sensorParam.measurements[0] > 0;

              return (
                <React.Fragment key={index}>
                  {sensorParam.check && (
                    <td style={iconStyle}>
                      {sensorParam.warning && hasMeasurements ? (
                        <QuickReportWarningIcon />
                      ) : (
                        <QuickReportCircleIcon />
                      )}
                    </td>
                  )}
                </React.Fragment>
              );
            })}
          </tr>

          {/* Table header */}
          <tr>
            {sensors.map((sensorParam, index) => {
              const sensorName = sensorParam.sensorName;

              return (
                <React.Fragment key={index}>
                  {sensorParam.check && (
                    <th style={headingStyle}>{sensorName}</th>
                  )}
                </React.Fragment>
              );
            })}
          </tr>

          {/* Table row 1 */}
          <tr style={trStyle}>
            {sensors.map((sensorParam, index) => (
              <React.Fragment key={index}>
                {sensorParam.check && (
                  <td
                    style={{ ...standardStyle, width: calcCellWidth(quantity) }}
                  >
                    {!isUndefined(sensorParam.params?.highAlarm) &&
                      setMeasurementType(
                        sensorParam.sensorType,
                        "high",
                        sensorParam.params.highAlarm
                      )}
                    {!isUndefined(sensorParam.params?.xAlarm) &&
                      setMeasurementType(
                        sensorParam.sensorType,
                        "x",
                        sensorParam.params.xAlarm
                      )}
                  </td>
                )}
              </React.Fragment>
            ))}
          </tr>

          {/* Table row 2 */}
          <tr style={trStyle}>
            {sensors.map((sensorParam, index) => (
              <React.Fragment key={index}>
                {sensorParam.check && (
                  <td
                    style={{ ...standardStyle, width: calcCellWidth(quantity) }}
                  >
                    {!isUndefined(sensorParam.params?.lowAlarm) &&
                      setMeasurementType(
                        sensorParam.sensorType,
                        "low",
                        sensorParam.params.lowAlarm
                      )}
                    {!isUndefined(sensorParam.params?.xAlarm) &&
                      setMeasurementType(
                        sensorParam.sensorType,
                        "y",
                        sensorParam.params.yAlarm
                      )}
                  </td>
                )}
              </React.Fragment>
            ))}
          </tr>

          {/* Table row 3 */}
          <tr style={trStyle}>
            {sensors.map((sensorParam, index) => (
              <React.Fragment key={index}>
                {sensorParam.check && (
                  <td
                    style={{ ...standardStyle, width: calcCellWidth(quantity) }}
                  >
                    {!isUndefined(sensorParam.params?.zAlarm) &&
                      setMeasurementType(
                        sensorParam.sensorType,
                        "z",
                        sensorParam.params.zAlarm
                      )}
                  </td>
                )}
              </React.Fragment>
            ))}
          </tr>

          {/* Table row 4 */}
          <tr style={trStyle}>
            {sensors.map((sensorParam, index) => (
              <React.Fragment key={index}>
                {sensorParam.check && (
                  <td
                    style={{ ...standardStyle, width: calcCellWidth(quantity) }}
                  >
                    {t("Measurements")}: {sensorParam.measurements}
                  </td>
                )}
              </React.Fragment>
            ))}
          </tr>
        </thead>
      </table>
    );
  };

  return (
    <>
      {/* Without a max width the sensors get too spread out on bigger screens */}
      <div style={{ maxWidth: 1100 }}>
        {/* Sensor tables. One table = one row */}
        {sensorsChunk.map((extSensor, index) => (
          <div key={index}>
            {tableComponent({
              quantity: sensorsChunk[index].length,
              sensors: extSensor
            })}
          </div>
        ))}
      </div>
    </>
  );
};

export default QuickReport;
