import React from "react";
import { Card, Tooltip, Typography } from "antd";
import {
  FlyoutProps,
  VictoryGroup,
  VictoryScatter,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer
} from "victory";
import { iconColors } from "../../constants/colors";
import { StateZoomDomain } from "../../state/openDatxSlice";
import { isNil, isUndefined } from "lodash-es";
import { size } from "../../helpers/pageHelper";
import { dvaSampleToMsTickFormaterWithPostfix } from "../../helpers/graphHelper";
import { useTranslation } from "react-i18next";
import { VectorList } from "../../helpers/vectorHelper";

/** The height of the component. Can be used for various calculations in the component */
const compHeight = 45;

const chartPaddingLeft = 50;

interface IProps {
  data: VectorList;
  zoomDomain?: StateZoomDomain;
  width: number;
  height: number;
}
const DvaStatusGraph: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { data, zoomDomain, height, width } = props;

  interface AccData {
    data: {
      channel: "x" | "y" | "z";
      startEnd: [number, number];
      currentValue: number;
      diff: number;
    };
    x: number;
    y: number;
  }
  let xAccData: AccData[] = [];
  let yAccData: AccData[] = [];
  let zAccData: AccData[] = [];

  const createStatusGraphObject = (
    channelName: "x" | "y" | "z",
    array: AccData[],
    channelData: any,
    index: number
  ) => {
    array.push({
      data: {
        channel: channelName,
        startEnd: !isUndefined(channelData) ? channelData.startEnd : [0, 0],
        currentValue: !isUndefined(channelData) ? channelData.alarm : 0,
        diff: !isUndefined(channelData) ? channelData.alarmDiff : 0
      },
      x: index,
      y: { x: 0, y: 3, z: 6 }[channelName]
    });
  };

  data.data.forEach((vector) => {
    if (vector.vectorChannels.includes("x")) {
      createStatusGraphObject("x", xAccData, vector.xData, vector.index);
    }

    if (vector.vectorChannels.includes("y")) {
      createStatusGraphObject("y", yAccData, vector.yData, vector.index);
    }

    if (vector.vectorChannels.includes("z")) {
      createStatusGraphObject("z", zAccData, vector.zData, vector.index);
    }
  });

  const renderGenericTooltip = (x: number, y: number, data: AccData) => {
    const renderAxisName = () => {
      switch (data.data.channel) {
        case "x":
          return t("genXAxis");
        case "y":
          return t("genYAxis");
        case "z":
          return t("genZAxis");
        default:
          return "Unknown axis";
      }
    };
    const renderVectorLeapRow = () => {
      return (
        <>
          <Typography.Text strong style={{ color: "white" }}>
            {t("VectorLeapOn")} {renderAxisName()}
          </Typography.Text>
          <br />
          {t("Timespan")}
          {": "}
          {dvaSampleToMsTickFormaterWithPostfix(data.data.startEnd[0]) +
            " - " +
            dvaSampleToMsTickFormaterWithPostfix(data.data.startEnd[1])}
          <br />
          {t("textCurrentValue")}
          {": "}
          {data.data.currentValue}
          {"g"}
          <br />
          {t("Difference")}
          {": "}
          {data.data.diff}
          {"g"}
        </>
      );
    };

    return (
      <g style={{ pointerEvents: "none" }} y={0} x={x}>
        <foreignObject x={x} y={y} width={compHeight} height={compHeight}>
          <Tooltip
            getPopupContainer={(triggerNode: HTMLElement) =>
              document.getElementById("detailedvibrationanalysis") ||
              (triggerNode.parentNode as HTMLElement)
            }
            placement="bottom"
            open={true}
            title={<>{renderVectorLeapRow()}</>}
          />
        </foreignObject>
      </g>
    );
  };

  /** Determine if obj is of a certain type */
  const isOfAccType = (obj: any, type: "x" | "y" | "z"): obj is AccData => {
    const maybeType = obj as AccData;
    return (
      !isNil(maybeType.data) &&
      maybeType.data.channel === type &&
      !isNil(maybeType.x) &&
      !isNil(maybeType.y)
    );
  };

  /** Local component used to render graph tooltips */
  const RenderTooltip: React.FC<FlyoutProps> = (props) => {
    const { x, y, datum } = props;

    //if any of the used values from props is undefined, we can't continue
    if (isNil(x) || isNil(y) || isNil(datum)) {
      return null;
    }

    if (isOfAccType(datum, "x")) {
      return renderGenericTooltip(x, y, datum);
    }
    if (isOfAccType(datum, "y")) {
      return renderGenericTooltip(x, y, datum);
    }
    if (isOfAccType(datum, "z")) {
      return renderGenericTooltip(x, y, datum);
    }

    return null;
  };

  return (
    <Card
      style={{
        width: "100%",
        height: `${compHeight}px`,
        padding: "0px 0px 0px 0px",
        boxShadow: "rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset"
      }}
      styles={{
        body: {
          padding: "0 0 0 0",
          height: "100%"
        }
      }}
    >
      <VictoryGroup
        standalone={true}
        // events={[statusEvents]}
        width={width}
        height={height}
        scale="linear"
        domain={{
          x: zoomDomain ? zoomDomain?.x : [data.startIndex, data.endIndex],
          y: [-2, 8]
        }}
        //same padding/domain padding as dva graph
        domainPadding={{ x: [0, 5], y: [0, 0] }}
        padding={{
          right: size.m1,
          left: chartPaddingLeft
        }}
        containerComponent={
          <VictoryVoronoiContainer
            labels={() => " "}
            name="tooltipContainer"
            labelComponent={
              <VictoryTooltip flyoutComponent={<RenderTooltip />} />
            }
          />
        }
        theme={VictoryTheme.material}
      >
        {/* X-acc data */}
        {xAccData.length > 0 && (
          <VictoryScatter
            name="xAccData"
            data={xAccData}
            size={5}
            style={{ data: { fill: iconColors.xAcc } }}
          />
        )}

        {/* Y-acc data */}
        {yAccData.length > 0 && (
          <VictoryScatter
            name="yAccData"
            data={yAccData}
            size={5}
            style={{ data: { fill: iconColors.yAcc } }}
          />
        )}

        {/* Z-acc data */}
        {zAccData.length > 0 && (
          <VictoryScatter
            name="zAccData"
            data={zAccData}
            size={5}
            style={{ data: { fill: iconColors.zAcc } }}
          />
        )}
      </VictoryGroup>
    </Card>
  );
};

export default React.memo(DvaStatusGraph);
