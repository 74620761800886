import { CloseOutlined, VerticalAlignBottomOutlined } from "@ant-design/icons";
import { Card, Col, Divider, Row, Space, Typography } from "antd";
import { isUndefined, isNil } from "lodash-es";
import dayjs from "dayjs";
import { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { timestampFormat } from "../../constants/defaultComponentsProps";
import { DeviceHealth, DtValues, GPSData } from "../../helpers/datasetHelper";
import { getUtcOffset } from "../../helpers/dateHelper";
import { size } from "../../helpers/pageHelper";
import {
  AccGeneral,
  AngleIcon,
  ExtInputIcon,
  ExtOutputIcon,
  ExtRhIcon,
  ExtSensMsgIcon,
  ExtTempIcon,
  ExtTimerIcon,
  GpsIcon,
  HealthIcon,
  LteIcon,
  PressureIcon,
  RecStatusIcon,
  RhIcon,
  TempIcon
} from "../../icons";
import {
  DTExternalIO,
  DTExternalRh,
  DTExternalTemp,
  DTExternalTimer,
  RunningStatusTypes
} from "../../models/DTTypes";
import {
  removeCursorPosition,
  removeDvaCursorPosition,
  selectActiveDashboardKeyForDatx,
  selectActiveDatxFile,
  selectDataForStatusGraph,
  selectScoreValuesData,
  setActiveDvaBlock,
  toggleScoreValueSide
} from "../../state/openDatxSlice";
import { addCardToDashboardLayouts } from "../../state/persistantStateSlice";
import {
  selectMySpeed,
  selectShowNearValuesToggle,
  selectTemperatureScale
} from "../../state/sessionSlice";
import { Optional } from "../../utils/utilTypes";
import {
  IconLinkButton,
  NormalButton,
  NormalButtonSmall
} from "../Common/CommonButtons";
import { SmallText } from "../Common/CommonFonts";
import { License } from "../MicroComponents/LicenseAccess";
import { MapProviderLink } from "../MicroComponents/MapProviderLink";
import { SpeedConverter } from "../MicroComponents/SpeedConverter";
import {
  TempSymbol,
  TemperatureToScale
} from "../MicroComponents/TemperatureConverter";
import { getSensorNrFromId } from "../../helpers/paramsHelper";
import {
  accuracyColorSelector,
  accuracyTextSelector
} from "../../helpers/dataModelHelper";
import { DictionaryTransKeys } from "../../lib/i18n";
import { getError, getStatus, getTrigger } from "./LteDashboardCard";
import { createLteRows, ILteDataRows } from "./LteTable";

export interface VMScoreValues {
  cursorPos?: Dayjs;
  nearestXAcc?: GenericTimestampValue<[number, number]>;
  nearestYAcc?: GenericTimestampValue<[number, number]>;
  nearestZAcc?: GenericTimestampValue<[number, number]>;
  nearestAccComponent?: GenericTimestampValue<[number, number]>;
  nearestTemp?: GenericTimestampValue<number>;
  nearestRh?: GenericTimestampValue<number>;
  nearestPressureRaw?: GenericTimestampValue<number>;
  nearestPressureComp?: GenericTimestampValue<number>;
  nearestAngle?: GenericTimestampValue<[number, number, number]>;
  nearestDeviceHealth?: GenericTimestampValue<DeviceHealth>;
  nearestGPS?: GenericTimestampValue<GPSData>;
  nearestLTEStatus?: GenericTimestampValue<number[]>;
  nearestExternalTimer?: GenericTimestampValue<DTExternalTimer>;
  nearestExternalTemps?: Optional<GenericTimestampValue<DTExternalTemp>>[];
  nearestExternalRhs?: Optional<GenericTimestampValue<DTExternalRh>>[];
  nearestExternalInput?: GenericTimestampValue<DTExternalIO>;
  nearestExternalOutput?: GenericTimestampValue<DTExternalIO>;
  nearestRunningStatus?: GenericTimestampValue<RunningStatusTypes[]>;
  nearestExtSensMsg?: GenericTimestampValue<string>;
  closeDvaDataKey?: number;
}

export interface GenericTimestampValue<T extends DtValues> {
  timestamp: Dayjs;
  value: T;
}

interface GeneralEntryHeaderProps {
  title: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  timestamp: Dayjs;
  gpsLink?: JSX.Element;
  accuracy?: number;
  t?: (s: DictionaryTransKeys) => string;
}
/** General re-usable header for score value channel entries */
const GeneralScoreValuesEntryHeader = (props: GeneralEntryHeaderProps) => {
  const Icon = props.icon;

  return (
    <>
      <Row
        justify="space-between"
        align="middle"
        style={{ width: "100%", paddingBottom: 0 }}
      >
        <Col>
          <Row justify="start" align="middle">
            <div
              title={
                props.t ? accuracyTextSelector(props.t, props.accuracy) : ""
              }
            >
              <Icon
                width="20px"
                height="20px"
                style={{ marginRight: size.s1 }}
              />
            </div>
            <SmallText strong>{props.title}</SmallText>
          </Row>
        </Col>
        {props.gpsLink && <Col>{props.gpsLink}</Col>}
      </Row>
      <Row style={{ width: "100%", paddingBottom: size.s1 }}>
        <Col>
          <SmallText type="secondary">
            {props.timestamp.format(timestampFormat)}
          </SmallText>
        </Col>
      </Row>
    </>
  );
};

interface GeneralScoreValuesEntryMultiDataProps {
  entries: string[][];
}
/** General re-usable component used to render multiple data spaced evenly. The
 * outer array will be rendered horizontaly and the inner array will be rendered
 * vertically */
const GeneralScoreValuesEntryMultiData = (
  props: GeneralScoreValuesEntryMultiDataProps
) => {
  return (
    <Row justify="space-evenly" style={{ width: "100%" }}>
      {props.entries.map((group) => (
        <Col style={{ minWidth: "45px" }}>
          {group.map((item) => (
            <Row justify="start">
              <SmallText>{item}</SmallText>
            </Row>
          ))}
        </Col>
      ))}
    </Row>
  );
};

interface IProps {
  id: string;
  jumpToDvaCallback?: () => boolean;
  scoreValueHeight: number;
}
const ScoreValues: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const speedUnit = useSelector(selectMySpeed);

  const { jumpToDvaCallback } = props;

  const { runningStatusData } = useSelector(selectDataForStatusGraph(props.id));
  const firstRecStart = runningStatusData?.find(
    (status) => status.type === "recStart"
  );

  const scoreValues = useSelector(selectScoreValuesData(props.id));
  const activeDashboardKey = useSelector(
    selectActiveDashboardKeyForDatx(props.id)
  );

  const tempScale = useSelector(selectTemperatureScale);

  const activeFile = useSelector(selectActiveDatxFile);
  /** Used as a check for changing different style attributes depending on if
   * Score Values is on the right or left side of graph */
  const isScoreValuesOnRightSide = activeFile?.scoreValuesSideToggle ?? true;
  const filters = activeFile?.filters;
  const xAccActive = filters?.xAcc?.dataToggle.isActive;
  const yAccActive = filters?.yAcc?.dataToggle.isActive;
  const zAccActive = filters?.zAcc?.dataToggle.isActive;

  const allExternalSensorParams =
    activeFile?.datxContent.recordingParameters.ExternalSensorParams;

  // External input and output parameters
  const allExternalInputParams =
    activeFile?.datxContent.recordingParameters.ExternalInputParams;
  const usedInputParams = allExternalInputParams?.filter((param) => param.used);
  const externalInputParams =
    usedInputParams && usedInputParams.length > 0
      ? usedInputParams
      : allExternalInputParams;

  const allExternalOutputParams =
    activeFile?.datxContent.recordingParameters.ExternalOutputParams;
  const usedOutputParams = allExternalOutputParams?.filter(
    (param) => param.used
  );
  const externalOutputParams =
    usedOutputParams && usedOutputParams.length > 0
      ? usedOutputParams
      : allExternalOutputParams;

  const [shouldJumpToDvaWhenAvailable, setShouldJumpToDvaWhenAvailable] =
    useState(false);

  /** Transforms a number to a string with the postfix "g". If number is undefined, "0g" will be returned */
  const toGStr = (maybeNumber: Optional<number>) =>
    maybeNumber ? maybeNumber.toFixed(2) + "g" : "0g";

  /** Transforms a number to a string with the postfix "ms". If number is undefined, "-" will be returned */
  const toMsStr = (maybeNumber: Optional<number>) =>
    maybeNumber ? maybeNumber + "ms" : "-";

  /** Renders the acceleration section of score values */
  const renderAcc = () => {
    // Note this function won't be called unless at least one of the below values exist
    const { nearestXAcc, nearestYAcc, nearestZAcc, nearestAccComponent } =
      scoreValues!;

    /** Any avilable timestamp from an acceleration. They will all be the same if all the channels are active */
    const timestamp =
      nearestXAcc?.timestamp ||
      nearestYAcc?.timestamp ||
      nearestZAcc?.timestamp;

    const [xAcc, xDur] = nearestXAcc?.value ?? [];
    const [yAcc, yDur] = nearestYAcc?.value ?? [];
    const [zAcc, zDur] = nearestZAcc?.value ?? [];

    let entries: any[] = [];

    if (xAccActive) {
      entries.push(["X", toGStr(xAcc), toMsStr(xDur)]);
    }
    if (yAccActive) {
      entries.push(["Y", toGStr(yAcc), toMsStr(yDur)]);
    }
    if (zAccActive) {
      entries.push(["Z", toGStr(zAcc), toMsStr(zDur)]);
    }

    if (
      xAccActive &&
      yAccActive &&
      zAccActive &&
      !isUndefined(nearestAccComponent) &&
      !isUndefined(timestamp) &&
      nearestAccComponent.timestamp.toString() === timestamp.toString()
    ) {
      entries.push(["Comp", toGStr(nearestAccComponent.value[0])]);
    }

    const clickDva = () => {
      dispatch(
        setActiveDvaBlock({
          fileId: props.id,
          blockKey: scoreValues!.closeDvaDataKey!
        })
      );

      // Make sure that the dva dashboard card is visible
      dispatch(
        addCardToDashboardLayouts({
          toAdd: "dvaGraph",
          dashboardId: activeDashboardKey
        })
      );

      // Resets the remove cursor button in DVA graph
      dispatch(removeDvaCursorPosition());

      //add to layout if not already included

      if (isNil(jumpToDvaCallback)) {
        return;
      }

      const didScroll = jumpToDvaCallback();

      if (!didScroll) {
        setShouldJumpToDvaWhenAvailable(true);
      }
    };

    return (
      <div>
        <GeneralScoreValuesEntryHeader
          title={t("generalAccTitle")}
          icon={AccGeneral}
          timestamp={timestamp!}
        />
        <GeneralScoreValuesEntryMultiData entries={entries} />
        <License right="ACE">
          <NormalButton
            style={{ marginTop: size.m1 }}
            disabled={scoreValues!.closeDvaDataKey === undefined}
            onClick={clickDva}
          >
            {t("DVADataAvailable")}
          </NormalButton>
        </License>
      </div>
    );
  };

  /** Renders the temperature section of score values */
  const renderTemp = () => {
    //Note: This function wont be called unless the below value exists

    const { nearestTemp } = scoreValues!;
    const round2Decimals = (v: number) => Math.round(v * 100) / 100;
    return (
      <div>
        <GeneralScoreValuesEntryHeader
          title={t("generalTempTitle")}
          icon={TempIcon}
          timestamp={nearestTemp?.timestamp!}
        />
        <Row>
          <Typography.Text>
            {`${round2Decimals(TemperatureToScale(nearestTemp!.value, tempScale))}`}
            <TempSymbol />
          </Typography.Text>
        </Row>
      </div>
    );
  };

  /** Renders the humidity section of score values */
  const renderRh = () => {
    //Note: This function wont be called unless the below value exists
    const { nearestRh } = scoreValues!;

    return (
      <div>
        <GeneralScoreValuesEntryHeader
          title={t("generalRhTitle")}
          icon={RhIcon}
          timestamp={nearestRh?.timestamp!}
        />
        <Row>
          <Typography.Text>{`${nearestRh!.value}%`}</Typography.Text>
        </Row>
      </div>
    );
  };

  /** Renders the pressure section of score values */
  const renderPressure = () => {
    let timestamp: Dayjs | undefined;
    let entries: string[][] = [];
    if (scoreValues && scoreValues.nearestPressureRaw) {
      const { nearestPressureRaw } = scoreValues;
      timestamp = nearestPressureRaw.timestamp;
      entries.push([t("RawPress"), nearestPressureRaw.value + "mbar"]);
    }

    if (scoreValues && scoreValues.nearestPressureComp) {
      const { nearestPressureComp } = scoreValues;
      timestamp = nearestPressureComp.timestamp;
      entries.push([t("TempComp"), nearestPressureComp.value + "mbar"]);
    }

    return (
      <div>
        <GeneralScoreValuesEntryHeader
          title={t("Pressure")}
          icon={PressureIcon}
          timestamp={timestamp!}
        />
        <GeneralScoreValuesEntryMultiData entries={entries} />
      </div>
    );
  };

  /** Renders the angle section of score values */
  const renderAngle = () => {
    //Note: This function wont be called unless the below value exists
    const { nearestAngle } = scoreValues!;
    const [x, y, z] = nearestAngle?.value ?? [];

    const entries = [
      ["X", `${x ?? "N/A"}°`],
      ["Y", `${y ?? "N/A"}°`],
      ["Z", `${z ?? "N/A"}°`]
    ];

    return (
      <div>
        <GeneralScoreValuesEntryHeader
          title={t("generalAngleTitle")}
          icon={AngleIcon}
          timestamp={nearestAngle?.timestamp!}
        />
        <GeneralScoreValuesEntryMultiData entries={entries} />
      </div>
    );
  };

  /** Rendersthe device health section of score values */
  const renderDeviceHealth = () => {
    //Note: This function wont be called unless the below value exists
    const { nearestDeviceHealth } = scoreValues!;

    const { memoryUsed, mainBatteryStatus, backupBatteryStatus } =
      nearestDeviceHealth!.value;

    const entries = [
      [t("MemUsed"), `${memoryUsed}B`],
      [t("MainBat"), `${mainBatteryStatus}%`],
      [t("BackupBat"), `${backupBatteryStatus}%`]
    ];

    return (
      <div>
        <GeneralScoreValuesEntryHeader
          title={t("generalDeviceHealthTitle")}
          icon={HealthIcon}
          timestamp={nearestDeviceHealth?.timestamp!}
        />
        <GeneralScoreValuesEntryMultiData entries={entries} />
      </div>
    );
  };

  /** Renders the recording status section of score values */
  const renderRunningStatus = () => {
    const { nearestRunningStatus } = scoreValues!;

    if (isUndefined(nearestRunningStatus)) return null;

    const stringJoin = (runningStatusArray: string[]) => {
      return runningStatusArray
        .map((status) => {
          switch (status) {
            case "btnPress":
              return t("ButtonPressed");
            case "tampering":
              return t("TamperingDetected");
            case "recStart":
              return t("RecordingStarted");
            case "recEndNormal":
              return t("RecordingEnded");
            case "recEndMemoryFull":
              return t("MemoryFull");
            case "recEndWriteError":
              return t("WriteError");
          }
          return "";
        })
        .join(", ");
    };

    let status = "";

    if (
      nearestRunningStatus.timestamp.toDate().getTime() ===
      firstRecStart?.x.getTime()
    ) {
      status = stringJoin(nearestRunningStatus.value);
    } else {
      const filteredStatus = nearestRunningStatus.value.filter(
        (s) => s !== "recStart"
      );
      status = stringJoin(filteredStatus);
    }

    return (
      <div>
        <GeneralScoreValuesEntryHeader
          title={t("RecordingStatus")}
          icon={RecStatusIcon}
          timestamp={nearestRunningStatus.timestamp}
        />
        <Row style={{ paddingBottom: size.s1 }}>
          <Typography.Text strong>{status}</Typography.Text>
        </Row>
      </div>
    );
  };

  /** Renders the GPS section of score values */
  const renderGPS = () => {
    //Note: This function wont be called unless the below value exists
    const { nearestGPS } = scoreValues!;
    const { latitude, longitude, velocity, gpsTimestamp, accuracy } =
      nearestGPS!.value;

    const lat = latitude / 10000000;
    const lon = longitude / 10000000;

    const entries = [
      [t("Latitude"), `${lat}`],
      [t("Longitude"), `${lon}`],
      [t("Speed"), `${SpeedConverter(velocity, speedUnit)} ${t(speedUnit)}`],
      [
        t("GPStime"),
        `${dayjs.unix(gpsTimestamp).utc().format("YYYY-MM-DD, HH:mm:ss")} (UTC)`
      ]
    ];

    return (
      <div>
        <GeneralScoreValuesEntryHeader
          title={t("Location")}
          icon={() => (
            <GpsIcon
              fill={accuracyColorSelector(accuracy)}
              width={20}
              height={20}
              style={{ marginRight: size.s1 }}
            />
          )}
          accuracy={accuracy}
          timestamp={nearestGPS?.timestamp!}
          gpsLink={
            <IconLinkButton style={{ marginRight: size.s1 }}>
              <MapProviderLink
                lat={lat}
                lon={lon}
                text={false}
                color={"#006600"}
              />
            </IconLinkButton>
          }
          t={t}
        />
        <GeneralScoreValuesEntryMultiData entries={entries} />
      </div>
    );
  };

  /** Renders the LTE Status section of score values */
  const renderLTEStatus = () => {
    //Note: This function wont be called unless the below value exists
    const { nearestLTEStatus } = scoreValues!;

    let statusRows: ILteDataRows[] = [];

    nearestLTEStatus?.value.forEach((s, index) => {
      const errorCode = getError(s, t);
      const statusCode = getStatus(s, t);
      const trigger = getTrigger(s, t);

      if (errorCode && trigger) {
        statusRows.push({
          key: index,
          rowType: [errorCode.rowType, trigger.rowType],
          message: [errorCode.message, trigger.message],
          color: [errorCode.color, trigger.color]
        });
      }
      if (statusCode && trigger) {
        statusRows.push({
          key: index,
          rowType: [statusCode.rowType, trigger.rowType],
          message: [statusCode.message, trigger.message],
          color: [statusCode.color, trigger.color]
        });
      }
    });

    const entries = statusRows.map((statusRow, index) => {
      return (
        <div key={index} style={{ marginTop: index === 1 ? size.s2 : 0 }}>
          {createLteRows(statusRow)}
        </div>
      );
    });

    return (
      <div>
        <GeneralScoreValuesEntryHeader
          title={t("LTE")}
          icon={LteIcon}
          timestamp={nearestLTEStatus?.timestamp!}
          t={t}
        />
        {entries}
      </div>
    );
  };

  /** Renders the external timer section of score values */
  const renderExternalTimer = () => {
    //Note: This function wont be called unless the below value exists
    const { nearestExternalTimer } = scoreValues!;
    const { timerTime } = nearestExternalTimer!.value;

    return (
      <div>
        <GeneralScoreValuesEntryHeader
          title={t("ExternalTimer")}
          icon={ExtTimerIcon}
          timestamp={nearestExternalTimer?.timestamp!}
        />
        <Row>
          <Typography.Text>{`${timerTime}ms`}</Typography.Text>
        </Row>
      </div>
    );
  };

  /** Renders the external temp section of score values */
  const renderExternalTemp = () => {
    //Note: This function wont be called unless the below value exists
    const { nearestExternalTemps } = scoreValues!;
    if (isUndefined(nearestExternalTemps)) return null;

    const round2Decimals = (v: number) => Math.round(v * 100) / 100;
    return nearestExternalTemps.map((nearestTemp) => {
      if (isUndefined(nearestTemp)) return null;
      const esti = nearestTemp.value.sensorId;

      const sensorName: string =
        allExternalSensorParams?.find(
          (param) => param.params.sensorTypeId === esti
        )?.params.sensorName ||
        t("ExternalTemperature") + " nr " + getSensorNrFromId(esti);

      return (
        <div>
          <GeneralScoreValuesEntryHeader
            title={sensorName}
            icon={ExtTempIcon}
            timestamp={nearestTemp.timestamp}
          />
          <Row>
            <Typography.Text>
              {`${round2Decimals(TemperatureToScale(nearestTemp.value.temp, tempScale))}°C`}
            </Typography.Text>
          </Row>
        </div>
      );
    });
  };

  /** Renders the external RH section of score values */
  const renderExternalRh = () => {
    //Note: This function wont be called unless the below value exists
    const { nearestExternalRhs } = scoreValues!;
    if (isUndefined(nearestExternalRhs)) return null;

    return nearestExternalRhs.map((nearestRh) => {
      if (isUndefined(nearestRh)) return null;
      const esti = nearestRh.value.sensorId;
      const sensorName: string =
        allExternalSensorParams?.find(
          (param) => param.params.sensorTypeId === esti
        )?.params.sensorName ||
        t("ExternalHumidity") + " nr " + +getSensorNrFromId(esti);

      return (
        <div>
          <GeneralScoreValuesEntryHeader
            title={sensorName}
            icon={ExtRhIcon}
            timestamp={nearestRh.timestamp}
          />
          <Row>
            <Typography.Text>{`${nearestRh.value.rh}%`}</Typography.Text>
          </Row>
        </div>
      );
    });
  };

  /** Renders the external input section of score values */
  const renderExternalInput = () => {
    //Note: This function wont be called unless the below value exists
    const { nearestExternalInput } = scoreValues!;
    const { changed, state } = nearestExternalInput!.value;
    const entries: string[] = externalInputParams
      ? externalInputParams.map((param) => {
          return (
            param.description +
            " " +
            (state[param.inputNumber] ? "ON" : "OFF") +
            (changed[param.inputNumber] ? "*" : "")
          );
        })
      : [];

    return (
      <div>
        <GeneralScoreValuesEntryHeader
          title={t("ExternalInput")}
          icon={ExtInputIcon}
          timestamp={nearestExternalInput?.timestamp!}
        />
        {entries.map((entry) => (
          <Row>
            <Typography.Text>{entry}</Typography.Text>
          </Row>
        ))}
      </div>
    );
  };

  /** Renders the external output section of score values */
  const renderExternalOutput = () => {
    //Note: This function wont be called unless the below value exists
    const { nearestExternalOutput } = scoreValues!;
    const { changed, state } = nearestExternalOutput!.value;
    const entries: string[] = externalOutputParams
      ? externalOutputParams.map((param) => {
          return (
            param.description +
            " " +
            (state[param.outputNumber] ? "ON" : "OFF") +
            (changed[param.outputNumber] ? "*" : "")
          );
        })
      : [];

    return (
      <div>
        <GeneralScoreValuesEntryHeader
          title={t("ExternalOutput")}
          icon={ExtOutputIcon}
          timestamp={nearestExternalOutput?.timestamp!}
        />
        {entries.map((entry) => (
          <Row>
            <Typography.Text>{entry}</Typography.Text>
          </Row>
        ))}
      </div>
    );
  };

  /** Renders the external sensor message section of score values */
  const renderExtSensMsg = () => {
    //Note: This function wont be called unless the below value exists
    const { nearestExtSensMsg } = scoreValues!;
    return (
      <div>
        <GeneralScoreValuesEntryHeader
          title={t("ExtSensMsg")}
          icon={ExtSensMsgIcon}
          timestamp={nearestExtSensMsg?.timestamp!}
        />
        <Row>
          <Typography.Text>{nearestExtSensMsg?.value}</Typography.Text>
        </Row>
      </div>
    );
  };

  const showNearValue = useSelector(selectShowNearValuesToggle);
  const cursorPos = scoreValues?.cursorPos?.toString();
  const nearestTemp = scoreValues?.nearestTemp?.timestamp.toString();
  const nearestRh = scoreValues?.nearestRh?.timestamp.toString();
  const nearestPressureRaw =
    scoreValues?.nearestPressureRaw?.timestamp.toString();
  const nearestPressureComp =
    scoreValues?.nearestPressureComp?.timestamp.toString();
  const nearestAngle = scoreValues?.nearestAngle?.timestamp.toString();
  const nearestLteStatus = scoreValues?.nearestLTEStatus?.timestamp.toString();
  const nearestGPS = scoreValues?.nearestGPS?.timestamp.toString();
  const nearestDeviceHealth =
    scoreValues?.nearestDeviceHealth?.timestamp.toString();
  const nearestExternalTimer =
    scoreValues?.nearestExternalTimer?.timestamp.toString();
  const nearestExternalInput =
    scoreValues?.nearestExternalInput?.timestamp.toString();
  const nearestExternalOutput =
    scoreValues?.nearestExternalOutput?.timestamp.toString();
  const nearestRunningStatus =
    scoreValues?.nearestRunningStatus?.timestamp.toString();
  const nearestExtSensMsg =
    scoreValues?.nearestExtSensMsg?.timestamp.toString();

  /** Checks if nearest value is equal to the cursor position. */
  const showOnlyExactValue = (nearest: string | undefined) => {
    if (!showNearValue && nearest === cursorPos) {
      return true;
    } else {
      return false;
    }
  };

  /** Checks if the cursor is within the nearest acc value. */
  const showOnlyExactAccValue = (
    timestamp: number | undefined,
    duration: number | undefined
  ) => {
    if (showNearValue || !timestamp || !duration) {
      return false;
    }
    const cursor = scoreValues?.cursorPos?.unix();
    const nearest = timestamp;
    const nearestEnd = timestamp + duration / 1000;
    if (cursor && cursor >= nearest && cursor <= nearestEnd) {
      return true;
    }
    return false;
  };

  /**Render all active content */
  const renderActiveChannels = () => {
    const content: any[] = [];

    if (
      scoreValues?.nearestXAcc?.value ||
      scoreValues?.nearestYAcc?.value ||
      scoreValues?.nearestZAcc?.value
    ) {
      const xTimestamp = scoreValues?.nearestXAcc?.timestamp.unix();
      const xDuration = scoreValues?.nearestXAcc?.value[1];
      const yTimestamp = scoreValues?.nearestYAcc?.timestamp.unix();
      const yDuration = scoreValues?.nearestYAcc?.value[1];
      const zTimestamp = scoreValues?.nearestZAcc?.timestamp.unix();
      const zDuration = scoreValues?.nearestZAcc?.value[1];
      if (
        showOnlyExactAccValue(xTimestamp, xDuration) ||
        showOnlyExactAccValue(yTimestamp, yDuration) ||
        showOnlyExactAccValue(zTimestamp, zDuration) ||
        showNearValue
      ) {
        content.push(renderAcc);
      }
    }
    if (scoreValues?.nearestTemp?.value) {
      if (showOnlyExactValue(nearestTemp) || showNearValue) {
        content.push(renderTemp);
      }
    }
    if (scoreValues?.nearestRh?.value) {
      if (showOnlyExactValue(nearestRh) || showNearValue) {
        content.push(renderRh);
      }
    }
    if (
      scoreValues?.nearestPressureRaw?.value ||
      scoreValues?.nearestPressureComp?.value
    ) {
      if (
        showOnlyExactValue(nearestPressureRaw) ||
        showOnlyExactValue(nearestPressureComp) ||
        showNearValue
      ) {
        content.push(renderPressure);
      }
    }
    if (scoreValues?.nearestAngle?.value) {
      if (showOnlyExactValue(nearestAngle) || showNearValue) {
        content.push(renderAngle);
      }
    }
    if (scoreValues?.nearestDeviceHealth) {
      if (showOnlyExactValue(nearestDeviceHealth) || showNearValue) {
        content.push(renderDeviceHealth);
      }
    }
    if (scoreValues?.nearestLTEStatus) {
      if (showOnlyExactValue(nearestLteStatus) || showNearValue) {
        content.push(renderLTEStatus);
      }
    }
    if (scoreValues?.nearestGPS) {
      if (showOnlyExactValue(nearestGPS) || showNearValue) {
        content.push(renderGPS);
      }
    }
    if (scoreValues?.nearestExternalTimer) {
      if (showOnlyExactValue(nearestExternalTimer) || showNearValue) {
        content.push(renderExternalTimer);
      }
    }
    if (scoreValues?.nearestExternalTemps) {
      scoreValues.nearestExternalTemps.forEach((temp) => {
        if (
          temp &&
          (showOnlyExactValue(temp.timestamp.toString()) || showNearValue)
        ) {
          content.push(renderExternalTemp);
        }
      });
    }
    if (scoreValues?.nearestExternalRhs) {
      scoreValues.nearestExternalRhs.forEach((rh) => {
        if (
          rh &&
          (showOnlyExactValue(rh.timestamp.toString()) || showNearValue)
        ) {
          content.push(renderExternalRh);
        }
      });
    }
    if (scoreValues?.nearestExternalInput) {
      if (showOnlyExactValue(nearestExternalInput) || showNearValue) {
        content.push(renderExternalInput);
      }
    }
    if (scoreValues?.nearestExternalOutput) {
      if (showOnlyExactValue(nearestExternalOutput) || showNearValue) {
        content.push(renderExternalOutput);
      }
    }
    if (scoreValues?.nearestRunningStatus) {
      if (showOnlyExactValue(nearestRunningStatus) || showNearValue) {
        content.push(renderRunningStatus);
      }
    }

    if (scoreValues?.nearestExtSensMsg) {
      if (showOnlyExactValue(nearestExtSensMsg) || showNearValue) {
        content.push(renderExtSensMsg);
      }
    }

    //Render content + divider as long as it's not the last content. In that
    //case render just the content
    return content?.map((renderFunc, i) => {
      const isLastElement = i === content.length - 1;

      if (isLastElement)
        return (
          <div key={i} style={{ paddingTop: size.m1, paddingLeft: size.s2 }}>
            {renderFunc()}
          </div>
        );

      return (
        <>
          <div key={i} style={{ paddingTop: size.m1, paddingLeft: size.s2 }}>
            {renderFunc()}
          </div>
          <Divider style={{ marginTop: size.s2, marginBottom: 0 }} />
        </>
      );
    });
  };

  const renderCurrentTimeStamp = () => {
    const timestamp = scoreValues!.cursorPos;
    return timestamp!.format(`YYYY-MM-DD HH:mm:ss`);
  };

  const renderCurrentTimeZone = () => {
    const offsetInHours = getUtcOffset(scoreValues!.cursorPos!);
    const offsetInHoursStr =
      offsetInHours >= 0 ? `UTC +${offsetInHours}` : `UTC ${offsetInHours}`;
    return offsetInHoursStr;
  };

  /** Side effect: if the user has requested "jump to dva" and it's available,
   * jump to it. */
  useEffect(() => {
    if (jumpToDvaCallback && shouldJumpToDvaWhenAvailable) {
      setShouldJumpToDvaWhenAvailable(false);
      jumpToDvaCallback();
    }
  }, [jumpToDvaCallback, shouldJumpToDvaWhenAvailable]);

  const titleHeight = 95;
  const scoreValueHeight = props.scoreValueHeight - titleHeight;

  if (!scoreValues?.cursorPos) {
    return <></>;
  }

  return (
    <>
      <Card styles={{ body: { padding: 0 } }}>
        <div style={{ height: titleHeight }}>
          <Row
            align="middle"
            justify="space-between"
            style={{ paddingRight: size.s1, paddingLeft: size.s2 }}
          >
            <Col>
              <SmallText strong>{t("ScoreValues")}</SmallText>
            </Col>
            <Col>
              <Space>
                <NormalButtonSmall
                  icon={
                    <VerticalAlignBottomOutlined
                      rotate={isScoreValuesOnRightSide ? 90 : 270}
                    />
                  }
                  style={{ marginBlock: size.s1 }}
                  onClick={() => dispatch(toggleScoreValueSide())}
                  title={
                    t("MoveScoreValuesToThe") +
                    " " +
                    (isScoreValuesOnRightSide ? t("left") : t("right")) +
                    " " +
                    t("side")
                  }
                />
                <NormalButtonSmall
                  icon={<CloseOutlined style={{ fontSize: "12px" }} />}
                  style={{ marginBlock: size.s1 }}
                  onClick={() => dispatch(removeCursorPosition())}
                  title={t("CloseScoreValues")}
                />
              </Space>
            </Col>
          </Row>
          <Divider style={{ marginTop: 0, marginBottom: size.s1 }} />
          <Row style={{ paddingLeft: size.s2 }}>
            <Typography.Text strong>{t("CurrentPosition")}</Typography.Text>
          </Row>
          <Row style={{ paddingLeft: size.s2 }}>
            <Typography.Text ellipsis>
              {renderCurrentTimeStamp()} {renderCurrentTimeZone()}
            </Typography.Text>
          </Row>
          <Divider style={{ marginTop: size.s2, marginBottom: 0 }} />
        </div>
        <div
          style={{
            height: scoreValueHeight,
            overflowY: "auto"
          }}
        >
          {renderActiveChannels()}
        </div>
      </Card>
    </>
  );
};

export default React.memo(ScoreValues);
