import { Buffer } from "buffer";
import { KIND_RECPARAMS } from "../../constants/FAT100-prot-constants";
import {
  KIND_DVADATA,
  KIND_RECORDINGDATA,
  KIND_STARTUP,
  KIND_SYSTEMINFO
} from "../../models/FAT100Kinds";
import { GeneralSystemInfo } from "../../models/ISystemInfo";
import { VMRecordingParameters } from "../../models/ViewModelRecordingParameters/VMRecordingParameters";
import {
  array2IDate,
  iDate2Unix,
  recordingParameters2ViewModel
} from "../dataModelHelper";
import { RecordingDataBlock } from "../datasetHelper";
import { parseDtMessages, parseDtsToDataset } from "./parseDataTypesHelper";
import {
  DatxHeader,
  DatxVersion,
  parseDatxHeader
} from "./parseDatxHeaderHelper";
import { IDvaData, parseDvaData } from "./parseDvaHelper";
import { parseRecordingParameters } from "./parseRecordingParamsHelper";
import { parseSystemInfo } from "./parseSystemInfoHelper";
import { isUndefined } from "lodash-es";

type unpackDatxState =
  | "search"
  | "header"
  | "systemInfo"
  | "recParams"
  | "startup"
  | "recordingData"
  | "datxHeader"
  | "dva_data"
  | "other";

interface IParsedDatxResponse {
  header?: DatxHeader;
  systemInfo?: GeneralSystemInfo;
  recordingParameters?: Partial<VMRecordingParameters>;
  recordingData: Map<number, RecordingDataBlock>;
  dvaData: IDvaData;
  startup?: number[];
  parsingError?: string;
}

interface DatxParserObject {
  getRes: () => IParsedDatxResponse;
  setSystemInfo: (b: Buffer) => void;
  setRecParams: (b: Buffer) => void;
  setStartup: (b: Buffer) => void;
  setRecordingData: (b: Buffer) => void;
  setDvaData: (b: Buffer) => void;
}

/** All supported config versions */
type ConfigVersion = 5 | 6 | 7 | 8 | 9 | 10;

/** Validates if a number is a supported config version */
export function isValidConfigVersion(num: number): num is ConfigVersion {
  return (
    num === 5 || num === 6 || num === 7 || num === 8 || num === 9 || num === 10
  );
}

export interface ParsedDatxWithHeader
  extends Omit<IParsedDatxResponse, "header"> {
  header: DatxHeader;
}

/** Start of the DATX parsing */
export const parseDatx = (data: number[]): ParsedDatxWithHeader => {
  /** Buffer containg the DATX in byte form */
  const dataBuffer = Buffer.from(data);
  /** Current position in buffer */
  let bufferPos = 0;

  /** DATX header and pos to start the rest of the parsing */
  const { datxHeader, newPos, parseHeaderStatus } = parseDatxHeader(dataBuffer);

  const emptyData = {
    header: {} as DatxHeader,
    recordingData: new Map(),
    dvaData: [],
    startup: []
  };

  if (!isUndefined(parseHeaderStatus)) {
    return {
      ...emptyData,
      parsingError: parseHeaderStatus
    };
  }

  bufferPos += newPos;

  const configVersion = isValidConfigVersion(datxHeader.configVersion)
    ? datxHeader.configVersion
    : null;

  if (!configVersion) {
    return {
      ...emptyData,
      parsingError: `UNKNOWN CONFIG VERSION ${datxHeader.configVersion}`
    };
  }

  const dataContentBuf = dataBuffer.subarray(bufferPos);

  const datxVersion = datxHeader.fileVersion as DatxVersion;
  const parsed = parseDatxStateMachine(dataContentBuf, datxVersion);

  return { ...parsed, header: datxHeader };
};

/** DATX parser object with parsing functions and the resulting data */
const datxParserObject = (datxVersion: DatxVersion): DatxParserObject => {
  let systemInfo: GeneralSystemInfo | undefined = undefined;
  let recordingParameters: VMRecordingParameters | undefined = undefined;
  const recordingData = new Map();
  const dvaData: IDvaData = [];
  const startup: number[] = [];
  let parsingError: string | undefined = undefined;

  /** Returns the generated object */
  const getRes = () => ({
    systemInfo,
    recordingParameters,
    recordingData,
    dvaData,
    startup,
    parsingError
  });

  /** Parses System Info */
  const setSystemInfo = (buf: Buffer) => {
    systemInfo = parseSystemInfo(buf);
  };

  /** Parses Recording Parameters */
  const setRecParams = (buf: Buffer) => {
    const recParams = parseRecordingParameters(buf);
    const vmRecParams = recordingParameters2ViewModel(recParams);
    recordingParameters = vmRecParams;
  };

  /** Parses KIND Startup */
  const setStartup = (buf: Buffer) => {
    const startUp = parseKindStartup(buf);
    startup.push(startUp);
  };

  /** Parses DATX recording data types */
  const setRecordingData = (buf: Buffer) => {
    const data = parseDtMessages(buf);
    const dataRecord = parseDtsToDataset(
      data,
      datxVersion,
      parsingErrorReporter
    );

    //get earlier version, may be undefined
    const priorRecord = recordingData.get(dataRecord.timestamp);

    const updatedRecord: RecordingDataBlock = {
      timestamp: dataRecord.timestamp,
      xAcc: [...(priorRecord?.xAcc ?? []), ...(dataRecord?.xAcc ?? [])],
      yAcc: [...(priorRecord?.yAcc ?? []), ...(dataRecord?.yAcc ?? [])],
      zAcc: [...(priorRecord?.zAcc ?? []), ...(dataRecord?.zAcc ?? [])],
      temp: priorRecord?.temp ?? dataRecord?.temp,
      rh: priorRecord?.rh ?? dataRecord?.rh,
      pressureRaw: priorRecord?.pressureRaw ?? dataRecord?.pressureRaw,
      pressureComp: priorRecord?.pressureComp ?? dataRecord?.pressureComp,
      angle: [...(priorRecord?.angle ?? []), ...(dataRecord?.angle ?? [])],
      runningStatus: [
        ...(priorRecord?.runningStatus ?? []),
        ...(dataRecord?.runningStatus ?? [])
      ],
      extraValue: [
        ...(priorRecord?.extraValue ?? []),
        ...(dataRecord?.extraValue ?? [])
      ],
      batteryData: priorRecord?.batteryData ?? dataRecord.batteryData,
      text: priorRecord?.text ?? dataRecord.text,
      errorString: priorRecord?.errorString ?? dataRecord?.errorString,
      deviceHealth: priorRecord?.deviceHealth ?? dataRecord?.deviceHealth,
      gps: priorRecord?.gps ?? dataRecord?.gps,
      timestamp2: priorRecord?.timestamp2 ?? dataRecord?.timestamp2,
      lteStatus: [
        ...(priorRecord?.lteStatus ?? []),
        ...(dataRecord?.lteStatus ?? [])
      ],
      // Merges earlier record with the same timestamp
      gpsStatus: [
        ...(priorRecord?.gpsStatus ?? []),
        ...(dataRecord?.gpsStatus ?? [])
      ],
      alarmStatus: priorRecord?.alarmStatus ?? dataRecord?.alarmStatus,
      dataLink: priorRecord?.dataLink ?? dataRecord?.dataLink,
      statusLink: priorRecord?.statusLink ?? dataRecord?.statusLink,
      externalSensor: priorRecord?.externalSensor ?? dataRecord?.externalSensor,
      externalInputs: priorRecord?.externalInputs ?? dataRecord?.externalInputs,
      externalOutputs:
        priorRecord?.externalOutputs ?? dataRecord?.externalOutputs,
      externalTimers: priorRecord?.externalTimers ?? dataRecord?.externalTimers,
      externalTemp: priorRecord?.externalTemp ?? dataRecord?.externalTemp,
      externalRh: priorRecord?.externalRh ?? dataRecord?.externalRh,
      extSensMsg: priorRecord?.extSensMsg ?? dataRecord?.extSensMsg
    };
    recordingData.set(updatedRecord.timestamp, updatedRecord);
  };

  /** Parses DVA data */
  const setDvaData = (buf: Buffer) => {
    const d = parseDvaData(buf);
    dvaData.push(...d);
  };

  const parsingErrorReporter = (error: string) => (parsingError = error);

  // Callable functions
  return {
    getRes,
    setSystemInfo,
    setRecParams,
    setStartup,
    setRecordingData,
    setDvaData
  };
};

/**
 * @param dataBuffer buffer containing DATX data content (not DATX header)
 */
const parseDatxStateMachine = (
  dataBuffer: Buffer,
  datxVersion: DatxVersion
): IParsedDatxResponse => {
  const emptyData = {
    recordingData: new Map(),
    dvaData: [],
    startup: []
  };

  /** Current position in buffer */
  let bufferPos = 0;

  const parserObject = datxParserObject(datxVersion);
  /** Current state machine state */
  let state: unpackDatxState = "search";

  //needs to handle schedueler also
  while (bufferPos < dataBuffer.length) {
    switch (state) {
      case "search": {
        state = getNextState(dataBuffer.readUInt16LE(bufferPos));
        if (state !== "other") {
          bufferPos += 2;
        }
        break;
      }
      case "systemInfo": {
        const systemInfoLength = dataBuffer.readUInt16LE(bufferPos);
        bufferPos += 2;

        /** Including KIND and Length */
        const startOfMessage = bufferPos - 4;
        const endOfMessage = bufferPos + systemInfoLength;

        const systemInfoBuffer = dataBuffer.subarray(
          startOfMessage,
          endOfMessage
        );

        try {
          parserObject.setSystemInfo(systemInfoBuffer);
        } catch (e) {
          return {
            ...emptyData,
            parsingError: "Error parsing SystemInfo-block"
          };
        }

        bufferPos += systemInfoLength;

        state = "search";
        break;
      }
      case "recParams": {
        const len = dataBuffer.readUInt16LE(bufferPos);
        bufferPos += 2;

        const recParamsBuffer = dataBuffer.subarray(
          bufferPos - 4,
          bufferPos + len
        );
        bufferPos += len;

        try {
          parserObject.setRecParams(recParamsBuffer);
        } catch (e) {
          return {
            ...emptyData,
            parsingError: "Error parsing RecParams-block"
          };
        }

        state = "search";
        break;
      }
      case "startup": {
        const startupLength = dataBuffer.readUInt16LE(bufferPos);
        bufferPos += 2;

        const startOfMessage = bufferPos - 4;
        const endOfMessage = bufferPos + startupLength;

        const startupBuffer = dataBuffer.subarray(startOfMessage, endOfMessage);

        try {
          parserObject.setStartup(startupBuffer);
        } catch (e) {
          return {
            ...emptyData,
            parsingError: "Error parsing StartUp-block"
          };
        }

        bufferPos += startupLength;

        state = "search";
        break;
      }
      case "recordingData": {
        const len = dataBuffer.readUInt16LE(bufferPos);
        bufferPos += 2;

        if (len !== 0) {
          const dataChunk = dataBuffer.subarray(bufferPos, bufferPos + len);
          bufferPos += len;

          try {
            parserObject.setRecordingData(dataChunk);
          } catch (e) {
            const faultyBuffer = parseBufferToHexString(dataChunk);

            return {
              ...emptyData,
              parsingError:
                "Error parsing RecordingData-block. This is the faulty block (hex): " +
                faultyBuffer
            };
          }
        }

        state = "search";
        break;
      }
      case "dva_data": {
        const len = dataBuffer.readUInt16LE(bufferPos);
        bufferPos += 2;

        const dvaDataStartStop: [number, number] = [
          bufferPos - 4,
          bufferPos + len
        ];
        const dvaDataBuf = dataBuffer.subarray(...dvaDataStartStop);
        bufferPos += len;

        try {
          parserObject.setDvaData(dvaDataBuf);
        } catch (e) {
          return {
            ...emptyData,
            parsingError: "Error parsing DvaData-block: " + e
          };
        }

        state = "search";
        break;
      }

      // will take care of "other" case by not reading any longer
      default: {
        bufferPos = dataBuffer.length;
        console.error(
          "Error parsing DATX-file. Unknown KIND at position " +
            bufferPos +
            " of " +
            dataBuffer.length
        );
      }
    }
  }

  return parserObject.getRes();
};

/**
 * Takes a UInt16 byte, checks what that byte repressents and returns the
 * correct state to process the coming data
 * @param byte UInt16 byte
 * @returns next state for unpack-datx-state-machine
 */
const getNextState = (byte: number): unpackDatxState => {
  switch (byte) {
    case KIND_SYSTEMINFO:
      return "systemInfo";
    case KIND_RECPARAMS:
      return "recParams";
    case KIND_RECORDINGDATA:
      return "recordingData";
    case KIND_STARTUP:
      return "startup";
    case KIND_DVADATA:
      return "dva_data";
    default:
      return "other";
  }
};

const parseKindStartup = (buf: Buffer) => {
  let bufferPos = 0;

  const shouldBeKindStartup = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  if (shouldBeKindStartup !== KIND_STARTUP) {
    throw new Error(
      "Wrong kind. Should be KIND_STARTUP, received: " + shouldBeKindStartup
    );
  }

  const len = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  if (len !== 6) {
    throw new Error(
      `Wrong length for KIND_STARTUP. Expected 6 but got: ${len}`
    );
  }

  const timestamp: number[] = [];
  for (let i = 0; i < 6; i++) {
    timestamp.push(buf.readUInt8(bufferPos));
    bufferPos += 1;
  }

  const timestampDate = array2IDate(timestamp);
  const unixTimestamp = iDate2Unix(timestampDate);

  return unixTimestamp;
};

/**
 * Utility function used for parsing a Buffer to a string in hex format. If the
 * buffer is empty, an empty string will be returned
 * @param buf
 */
const parseBufferToHexString = (buf: Buffer) => {
  const length = buf.length;
  let i = 0;
  let result = "";

  while (i < length) {
    const byte = buf.readUInt8(i);
    result += byte.toString(16) + " ";

    i++;
  }

  return result;
};
