import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { notification } from "antd";
import "leaflet/dist/leaflet.css";
import dayjs from "dayjs";
import { useEffect } from "react";
import "react-grid-layout/css/styles.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "react-resizable/css/styles.css";
import { HashRouter } from "react-router-dom";
import AppLayout from "./AppLayout";
import { Tokens, validateToken } from "./helpers/sessionHelper";
import useNetworkStatus from "./hooks/useNetworkStatus";
import CreateAccountPage from "./pages/CreateAccountPage";
import LoginPage from "./pages/LoginPage";
import {
  cargologRestApi,
  useGetCompanyDetailsQuery,
  useGetLicensesQuery,
  useRemoveLicenseFromUserMutation,
  UpdateUserStatistic,
  useUpdateUserStatisticMutation
} from "./state/cargologRestApi";
import { closeAllOpenCompareFiles } from "./state/compareGraphsSlice";
import { setQuickTourProgress } from "./state/helpSlice";
import {
  addToRenewalCart,
  openRenewalModal,
  setCartState
} from "./state/licenseStoreSlice";
import {
  closeRenewLicenseModal,
  openRenewLicenseModal,
  resetModalsState
} from "./state/modalsSlice";
import {
  resetNavigationState,
  setCreateCompanySuccess,
  setCreateUserAccountProgress,
  setCreateUserSuccess,
  setCurrentAdminTab
} from "./state/navigationSlice";
import { closeAllOpenDatxFiles } from "./state/openDatxSlice";
import {
  changeDashboard,
  clearRecentDevices,
  clearSession,
  resetDashboardState,
  selectValidSetup,
  setValidSetup
} from "./state/persistantStateSlice";
import { resetProjectState } from "./state/projectSlice";
import {
  getInitalizeSwitcher,
  getTokens,
  getUser,
  setCurrency,
  setGlobalTimezoneState,
  setInitalizeSwitcher,
  setPendingRoute,
  setReauthRequired
} from "./state/sessionSlice";
import { closeSettingsModal, setPreferedCurrency } from "./state/settingsSlice";
import "./styles/App.css";
import "./styles/overrides.css";
import LicenseExpiredModal, {
  ILicenseTableData
} from "./components/Modals/LicenseExpiredModal";
import { skipToken } from "@reduxjs/toolkit/query";
import { isNil, isUndefined } from "lodash-es";
import { appType } from "./state/cargologRestApi";
import { getAppVersionString } from "./helpers/versionHelper";

notification.config({
  placement: "topRight",
  top: 60,
  duration: 10,
  maxCount: 1
});

export const clearSessionAndData = (dispatch: Dispatch<AnyAction>) => {
  // Clear cached data in API
  dispatch(cargologRestApi.util.resetApiState());
  // Clear global timezone state
  dispatch(setGlobalTimezoneState(undefined));
  // Clear cart
  dispatch(setCartState([]));
  // Clear recent devices
  dispatch(clearRecentDevices());
  // Clear login details
  dispatch(clearSession());
  dispatch(setValidSetup(false));
  // Reset modals state
  dispatch(resetModalsState());
  dispatch(resetProjectState());
  dispatch(closeSettingsModal());
  dispatch(resetNavigationState());
  // Change Report/Dashboard to Standard
  dispatch(changeDashboard({ dashboardKey: "Standard" }));
  // Reset Report/Dashboard to default layout
  dispatch(resetDashboardState());
  // Close all open DATX files
  dispatch(closeAllOpenDatxFiles());
  dispatch(closeAllOpenCompareFiles());
  dispatch(setCreateUserSuccess(false));
  dispatch(setCreateCompanySuccess(false));
  dispatch(setCreateUserAccountProgress(0));
  dispatch(setQuickTourProgress(0));
  dispatch(setInitalizeSwitcher("loginPage"));
  dispatch(setReauthRequired(false));
  dispatch(setPreferedCurrency(undefined));
  dispatch(setCurrency("SEK"));
};

// Clear session and data before and after impersonation
export const clearSessionAndDataImp = (dispatch: Dispatch<AnyAction>) => {
  // Clear cached data in API
  dispatch(cargologRestApi.util.resetApiState());
  // Clear global timezone state
  dispatch(setGlobalTimezoneState(undefined));
  // Clear cart
  dispatch(setCartState([]));
  // Clear recent devices
  dispatch(clearRecentDevices());
  // Clear login details
  // dispatch(clearSession());
  // dispatch(setValidSetup(false));
  // Reset modals state
  dispatch(resetModalsState());
  dispatch(resetProjectState());
  dispatch(closeSettingsModal());
  dispatch(resetNavigationState());
  // Change Report/Dashboard to Standard
  // dispatch(changeDashboard({ dashboardKey: "Standard" }));
  // Reset Report/Dashboard to default layout
  // dispatch(resetDashboardState());
  // Close all open DATX files
  dispatch(closeAllOpenDatxFiles());
  dispatch(closeAllOpenCompareFiles());
  // dispatch(setCreateUserSuccess(false));
  // dispatch(setCreateCompanySuccess(false));
  // dispatch(setCreateUserAccountProgress(0));
  // dispatch(setQuickTourProgress(0));
  // dispatch(setInitalizeSwitcher("loginPage"));
  dispatch(setReauthRequired(false));
};

const validSession = (tokens: Tokens) => {
  if (
    tokens.refreshTokenExpiry &&
    tokens.refreshToken &&
    tokens.accessToken &&
    validateToken(tokens.refreshTokenExpiry)
  ) {
    return true;
  }
  return false;
};

const App = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tokens = useSelector(getTokens);
  const validSetup = useSelector(selectValidSetup);
  const { refreshToken, accessToken } = tokens;
  const initializeSwitcher = useSelector(getInitalizeSwitcher) ?? "loginPage";
  useNetworkStatus();
  const { companyId, userId } = useSelector(getUser);

  const { data: licenseData, isLoading: licenseLoading } = useGetLicensesQuery(
    userId ? { userId } : skipToken
  );

  const { data: companyData } = useGetCompanyDetailsQuery(
    companyId ? { companyId } : skipToken
  );

  const version = getAppVersionString();
  const userStatistic: UpdateUserStatistic = { version, appType };

  const [updateUserStatisticMutation] = useUpdateUserStatisticMutation();

  const [removeLicenseFromUser] = useRemoveLicenseFromUserMutation();

  const expiredLicenses = licenseData?.filter(
    (license) =>
      isNil(license.projectId) &&
      license.renewAuto === false &&
      dayjs().diff(license.endDate, "day") >= 0
  );

  let tableData: ILicenseTableData[] | undefined = undefined;

  if (!isUndefined(expiredLicenses)) {
    tableData = expiredLicenses.map((license) => {
      return {
        userData: license.user,
        info: {
          text: license.text,
          licenseId: license.id
        },
        endDate: license.endDate,
        actions: {
          renewLicense: () => {
            dispatch(
              addToRenewalCart({
                licenseId: license.id,
                licensePriceId: license.licensePriceId
              })
            );
            dispatch(setCurrentAdminTab("licenses"));
            dispatch(closeRenewLicenseModal());
            dispatch(openRenewalModal());
            dispatch(setPendingRoute("/admin"));
          },
          removeLicense: () => {
            removeLicenseFromUser({ licenseId: license.id });
          }
        }
      };
    });
  }

  // Update user statistic when we have a valid session and the tokens change (e.g. refresh token)
  useEffect(() => {
    if (validSession(tokens)) {
      updateUserStatisticMutation(userStatistic);
    }
  }, [userId]);

  // Open licenses modal if there are any expired licenses
  useEffect(() => {
    if (!isUndefined(expiredLicenses) && expiredLicenses.length > 0) {
      dispatch(openRenewLicenseModal());
    }
  }, [licenseData]);

  useEffect(() => {
    // The following scenarios are covered:
    // 1. Valid refresh token exists
    //    a. User setup is valid
    //       I.  User is online  -> Online mode
    //       II. User is offline -> Offline mode
    //    b. User setup is not valid
    //       I.  User is online  -> Reroute to registration wizzard
    //       II. User is offline -> Sign out
    // 2. Refresh token is expired
    //    a. User setup is valid
    //       I.  User is online  -> Online mode
    //       II. User is offline -> Sign out
    //    b. User setup is not valid
    //       I.  User is either online or offline
    //                           -> Sign out
    // 3. No tokens exist        -> Sign out

    if (validSession(tokens)) {
      // 1
      if (validSetup) {
        // 1.a.I
        // 1.a.II
        dispatch(setInitalizeSwitcher("appLayout"));
      } else {
        // 1.b
        if (navigator.onLine) {
          // 1.b.I
          dispatch(setCreateUserSuccess(true));
          dispatch(setCreateUserAccountProgress(1));
          dispatch(setInitalizeSwitcher("registerWizard"));
        } else {
          // 1.b.II
          clearSessionAndData(dispatch);
        }
      }
    } else if (accessToken && refreshToken) {
      // 2
      if (validSetup) {
        // 2.a
        if (navigator.onLine) {
          // 2.a.I
          dispatch(setInitalizeSwitcher("appLayout"));
        } else {
          // 2.a.II
          clearSessionAndData(dispatch);
        }
      } else {
        // 2.b.I
        clearSessionAndData(dispatch);
      }
    } else {
      // 3
      clearSessionAndData(dispatch);
    }
  }, []);

  const switcher = () => {
    switch (initializeSwitcher) {
      case "loginPage":
        return <LoginPage />;
      case "appLayout":
        return <AppLayout />;
      case "registerWizard":
        return <CreateAccountPage />;
    }
  };

  return (
    <div className="App">
      <HashRouter basename="/">{switcher()}</HashRouter>
      {
        <LicenseExpiredModal
          tableData={tableData ?? []}
          licenseLoading={licenseLoading}
          companyData={companyData}
        />
      }
    </div>
  );
};

export default App;
