import React, { useRef, useState } from "react";
import {
  Row,
  Col,
  Typography,
  Space,
  Input,
  Radio,
  Popover,
  Checkbox,
  Layout,
  Badge,
  Spin,
  notification,
  Pagination
} from "antd";
import { size } from "../helpers/pageHelper";
import {
  AppstoreAddOutlined,
  AppstoreOutlined,
  CheckOutlined,
  CloudUploadOutlined,
  PlusOutlined,
  SearchOutlined,
  TableOutlined,
  FilterOutlined
} from "@ant-design/icons";
import {
  shadowLarge,
  NormalButton,
  PrimaryButton
} from "../components/Common/CommonButtons";
import { useDispatch, useSelector } from "react-redux";
import {
  openCreateProjectModal,
  openProjectInvitesModal,
  projectsState,
  setViewMode
} from "../state/projectSlice";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  useGetAllProjectsQuery,
  useGetProjectInvitesQuery,
  useGetUsersQuery,
  useUploadDatxMutation
} from "../state/cargologRestApi";
import mobitronColors from "../styles/mobitronColors";
import { dateToUnix } from "../helpers/dateHelper";
import { DictionaryTransKeys } from "../lib/i18n";
import ProjectsTable from "../components/Projects/ProjectsTable";
import ProjectsGrid from "../components/Projects/ProjectsGrid";
import ArchiveProjectModal from "../components/Modals/ArchiveProjectModal";
import RecoverProjectModal from "../components/Modals/RecoverProjectModal";
import CreateProjectModal from "../components/Modals/CreateProjectModal";
import EditProjectModal from "../components/Modals/EditProjectModal";
import InviteExternalUserModal from "../components/Modals/InviteExternalUserModal";
import ProjectInvitesModal from "../components/Modals/ProjectInvitesModal";
import AddPersonModal from "../components/Modals/AddInternalUserModal";
import { isNil, isNull } from "lodash-es";
import { getUser } from "../state/sessionSlice";
import {
  CheckUserRightsAccess,
  LicenseAccess
} from "../components/MicroComponents/LicenseAccess";
import MoveDeviceModal from "../components/Projects/MoveDeviceModal";
import PositionsModal from "../components/Modals/PositionsModal";
import ProjectModal from "../components/Projects/ProjectModal";
import { Buffer } from "buffer";
import { LargeHeaderTitle } from "../components/Common/CommonFonts";
import _, { isUndefined } from "lodash";

const { Text } = Typography;

/** Returns the current progress-percent between two dates based on present time. */
export const calcTimeDiff = (startDate: string, endDate: string) => {
  const start = dayjs(startDate).toDate();
  const end = dayjs(endDate).toDate();
  const presentTime = new Date();

  const startToEnd = dateToUnix(end) - dateToUnix(start);
  const diff = dateToUnix(presentTime) - dateToUnix(start);

  const result = (diff / startToEnd) * 100;

  const floatResult = parseFloat(result.toFixed(1));
  const intResult = Math.round(result);

  return result < 1 ? floatResult : intResult;
};

export const renderStatus = (
  t: (s: DictionaryTransKeys) => string,
  status?: number
) => {
  if (status === 0) {
    return { string: t("NoDataAvailable"), color: mobitronColors.primaryGray };
  }
  if (status === 1) {
    return { string: t("Active"), color: mobitronColors.altDarkGreen };
  }
  if (status === 2) {
    return { string: t("NoActiveDevices"), color: mobitronColors.primaryGray };
  }
  if (status === 3) {
    return { string: t("Archived"), color: mobitronColors.primaryGray };
  }
  return { string: "N/A", color: mobitronColors.primaryGray };
};

export const warningColor = (isWarning: boolean) => {
  return isWarning ? "#cf1322" : "#3f8600";
};

interface UniqueUser {
  email: string;
  name?: string;
}

const ProjectsPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { viewMode } = useSelector(projectsState);
  const user = useSelector(getUser);

  const { data: projects, isLoading: isLoadingProjects } =
    useGetAllProjectsQuery();
  const { data: projectInvites } = useGetProjectInvitesQuery();
  const filteredInvites = projectInvites?.filter(
    (invite) => invite.email === user.email && isNil(invite.answered)
  );

  const { data: companyUsers } = useGetUsersQuery();

  const handleViewChange = (e: any) => {
    setCurrentPage(1);
    dispatch(setViewMode(e.target.value));
  };

  const [searchQuery, setSearchQuery] = useState<string>("");

  let usersArr: UniqueUser[] = [];
  let uniqueUsers: UniqueUser[] = [];
  const [filterUsers, setFilterUsers] = useState<string[]>([]);

  const [filterStatus, setFilterStatus] = useState<number[]>([0, 1, 2]);

  let companyArr: string[] = [];
  const [filterCompany, setFilterCompany] = useState<string[]>([]);

  const projectNames = projects?.map((e) => e.title);

  let filteredProjects = projects;

  if (!CheckUserRightsAccess("ADI")) {
    filteredProjects = projects?.filter((project) =>
      project.projectUsers?.find((e) => e.user.userId === user.userId)
    );
  }

  if (filteredProjects) {
    // Users
    filteredProjects.forEach((project) => {
      project.projectUsers?.forEach((e) => {
        if (e.user.email) {
          usersArr.push({
            email: e.user.email,
            name: `${e.user.firstName ?? ""} ${e.user.lastName ?? ""}`
          });
        }
      });
    });

    // Excludes all duplicates in the array
    uniqueUsers = [...new Map(usersArr.map((m) => [m.email, m])).values()].sort(
      (a, b) =>
        (a.name ?? "")
          .toLowerCase()
          .localeCompare((b.name ?? "").toLowerCase(), "sv")
    );

    // Company
    filteredProjects.forEach((e) => {
      const companyIndex = companyArr.findIndex(
        (name) => e.companyName === name
      );

      if (companyIndex <= -1 && e.companyName) {
        companyArr.push(e.companyName);
      }
      companyArr.sort((a, b) =>
        a.toLocaleLowerCase().localeCompare(b.toLowerCase(), "sv")
      );
    });
  }

  interface IStatus {
    value: number;
    text: string;
  }
  const StatusCheckbox = (props: IStatus) => {
    return (
      <Row style={{ width: "150px" }}>
        <Col span={24}>
          <Checkbox
            defaultChecked={filterStatus.includes(props.value)}
            value={props.value}
            checked={filterStatus.includes(props.value)}
          >
            {props.text}
          </Checkbox>
        </Col>
      </Row>
    );
  };

  const [uploadDatx, requestStatus] = useUploadDatxMutation();
  const [uploadSuccessful, setUploadSuccessful] = useState<boolean>(false);
  const { isLoading: requestIsLoading } = requestStatus;

  // reference to hidden file input
  const localFile = useRef<HTMLInputElement | null>(null);

  const handleUploadFileToCloud = async (request: Buffer) => {
    const result: any = await uploadDatx(request);
    if (result.data) {
      setUploadSuccessful(true);
    } else if (result.error) {
      notification.error({ message: t("FailedToUploadDatxFile") });
      setUploadSuccessful(false);
    }
  };

  // when local file is selected, upload it to the server
  const onSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          const data: Buffer = Buffer.from(reader.result as ArrayBuffer);
          handleUploadFileToCloud(data);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  // click on the hidden file input element
  const uploadFile = () => {
    if (!isNull(localFile?.current)) {
      localFile.current.value = "";
      localFile.current.click();
    }
  };

  const [currentPageSize, setCurrentPageSize] = useState<number>(20);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const ref = useRef<HTMLDivElement>(null);

  const onChangePageSize = (current: number, pageSize: number) => {
    setCurrentPage(current);
    setCurrentPageSize(pageSize);
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const filteredGridProjects = filteredProjects
    ?.filter(
      (project) =>
        filterStatus.length === 0 || filterStatus.includes(project.status)
    )
    .filter(
      (project) =>
        filterCompany.length === 0 ||
        (project.companyName && filterCompany.includes(project.companyName))
    )
    .filter((project) => {
      const createdByUserEmail = companyUsers?.find(
        (user) => user.userId === project?.createdByUserId
      )?.email;

      return (
        filterUsers.length === 0 ||
        (project.projectUsers &&
          project.projectUsers.length > 0 &&
          project.projectUsers.find(
            (e) =>
              e.user.email ===
              filterUsers.find((filter) => filter === e.user.email)
          )) ||
        (createdByUserEmail &&
          filterUsers.find((filter) => filter === createdByUserEmail))
      );
    })
    .filter(
      (project) =>
        searchQuery === "" ||
        (project.description &&
          project.description
            .toLowerCase()
            .includes(searchQuery.toLowerCase())) ||
        project.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        renderStatus(t, project.status)
          .string?.toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        project.companyName
          ?.toLowerCase()
          .includes(searchQuery.toLocaleLowerCase())
    )
    .reverse();

  /** A list of project chunks, one chunk per page */
  const gridProjectsChunks = _.chunk(filteredGridProjects, currentPageSize);

  const filteredGridProjectsLength = !isUndefined(filteredGridProjects)
    ? filteredGridProjects.length
    : 0;

  return (
    <div ref={ref}>
      <Row>
        <Col span={24}>
          <Row justify="center" style={{ padding: 0 }}>
            <Layout
              style={{
                width: "100%",
                maxWidth: "100%"
              }}
            >
              <Layout.Content
                style={{
                  paddingInline: size.l2,
                  paddingBlock: size.m1,
                  textAlign: "left"
                }}
              >
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: size.m1 }}
                >
                  <Col style={{ marginBlock: size.m1 }}>
                    <Space>
                      <LargeHeaderTitle>{t("Projects")}</LargeHeaderTitle>

                      {LicenseAccess(
                        "ADK",
                        <PrimaryButton
                          style={{ marginLeft: size.m1 }}
                          onClick={() => dispatch(openCreateProjectModal())}
                          icon={<PlusOutlined />}
                        >
                          {t("CreateNewProject")}
                        </PrimaryButton>
                      )}

                      {LicenseAccess(
                        "ADN",
                        <NormalButton
                          icon={
                            uploadSuccessful ? (
                              <CheckOutlined />
                            ) : (
                              <CloudUploadOutlined />
                            )
                          }
                          onClick={() => uploadFile()}
                          loading={requestIsLoading}
                        >
                          {t("UploadFile")}
                        </NormalButton>
                      )}

                      <div style={{ marginRight: size.m1 }}>
                        <Badge count={filteredInvites?.length} offset={[-6, 6]}>
                          <NormalButton
                            icon={<AppstoreAddOutlined />}
                            onClick={() => dispatch(openProjectInvitesModal())}
                          >
                            {t("ProjectInvites")}
                          </NormalButton>
                        </Badge>
                      </div>

                      <input
                        type="file"
                        id="file"
                        accept=".datx"
                        ref={localFile}
                        onInput={onSelectFile}
                        style={{ display: "none" }}
                      />
                    </Space>
                  </Col>

                  <Col style={{ marginBlock: size.m1 }}>
                    <Space>
                      <Radio.Group value={viewMode} onChange={handleViewChange}>
                        <Radio.Button style={shadowLarge} value="grid">
                          <AppstoreOutlined /> {t("Grid")}
                        </Radio.Button>
                        <Radio.Button style={shadowLarge} value="table">
                          <TableOutlined /> {t("Table")}
                        </Radio.Button>
                      </Radio.Group>

                      <Popover
                        placement="bottom"
                        trigger="click"
                        getPopupContainer={(triggerNode: HTMLElement) =>
                          triggerNode.parentNode as HTMLElement
                        }
                        content={
                          <Checkbox.Group
                            onChange={(v) => {
                              setFilterCompany(v as string[]);
                              setCurrentPage(1);
                            }}
                            value={filterCompany}
                            style={{ flexDirection: "column" }}
                          >
                            <>
                              {companyArr.length > 0 ? (
                                companyArr.map((e) => (
                                  <Checkbox
                                    defaultChecked={true}
                                    value={e}
                                    checked={
                                      filterCompany &&
                                      filterCompany?.includes(e)
                                        ? true
                                        : false
                                    }
                                    key={e}
                                  >
                                    {e}
                                  </Checkbox>
                                ))
                              ) : (
                                <Text type="secondary">{t("NoContent")}</Text>
                              )}
                            </>
                          </Checkbox.Group>
                        }
                      >
                        <NormalButton
                          icon={<FilterOutlined />}
                          active={filterCompany.length > 0}
                        >
                          {t("Company")}
                        </NormalButton>
                      </Popover>

                      <Popover
                        placement="bottom"
                        trigger="click"
                        getPopupContainer={(triggerNode: HTMLElement) =>
                          triggerNode.parentNode as HTMLElement
                        }
                        content={
                          <Checkbox.Group
                            onChange={(v) => {
                              setFilterUsers(v as string[]);
                              setCurrentPage(1);
                            }}
                            style={{ flexDirection: "column" }}
                          >
                            <>
                              {uniqueUsers.length > 0 ? (
                                uniqueUsers.map((e, index) => (
                                  <Checkbox
                                    defaultChecked={false}
                                    value={e.email}
                                    checked={
                                      filterUsers &&
                                      filterUsers.includes(e.email)
                                        ? true
                                        : false
                                    }
                                    key={index}
                                  >
                                    <Text>
                                      {e.name}{" "}
                                      <Text
                                        style={{ fontSize: 12 }}
                                        type="secondary"
                                      >
                                        ({e.email})
                                      </Text>
                                    </Text>
                                  </Checkbox>
                                ))
                              ) : (
                                <Text type="secondary">{t("NoContent")}</Text>
                              )}
                            </>
                          </Checkbox.Group>
                        }
                      >
                        <NormalButton
                          icon={<FilterOutlined />}
                          active={filterUsers.length > 0}
                        >
                          {t("Users")}
                        </NormalButton>
                      </Popover>

                      <Popover
                        placement="bottom"
                        trigger="click"
                        getPopupContainer={(triggerNode: HTMLElement) =>
                          triggerNode.parentNode as HTMLElement
                        }
                        content={
                          <Checkbox.Group
                            onChange={(v) => {
                              setFilterStatus(v as number[]);
                              setCurrentPage(1);
                            }}
                            value={filterStatus}
                            style={{ flexDirection: "column" }}
                          >
                            <StatusCheckbox
                              value={0}
                              text={t("NoDataAvailable")}
                            />
                            <StatusCheckbox value={1} text={t("Active")} />
                            <StatusCheckbox
                              value={2}
                              text={t("NoActiveDevices")}
                            />
                            <StatusCheckbox value={3} text={t("Archived")} />
                          </Checkbox.Group>
                        }
                      >
                        <NormalButton
                          icon={<FilterOutlined />}
                          active={filterStatus.length > 0}
                        >
                          {t("ProjectStatus")}
                        </NormalButton>
                      </Popover>

                      <Input
                        placeholder={t("FilterProjects")}
                        prefix={<SearchOutlined />}
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                          setCurrentPage(1);
                        }}
                        allowClear
                      />
                    </Space>
                  </Col>
                </Row>

                <Row
                  gutter={[size.l2, size.l2]}
                  style={{ marginBottom: size.m1 }}
                >
                  {isLoadingProjects && (
                    <Row
                      justify="center"
                      align="middle"
                      style={{ height: "calc(75vh - 320px)", width: "100%" }}
                    >
                      <Spin />
                    </Row>
                  )}
                  {viewMode === "grid" && !isLoadingProjects && (
                    <ProjectsGrid
                      projects={gridProjectsChunks[currentPage - 1]}
                    />
                  )}
                  {viewMode === "table" && !isLoadingProjects && (
                    <ProjectsTable
                      projects={filteredProjects}
                      searchQuery={searchQuery}
                      filterCompany={filterCompany}
                      filterUsers={filterUsers}
                      filterStatus={filterStatus}
                    />
                  )}
                </Row>
              </Layout.Content>
              {viewMode === "grid" &&
                !isLoadingProjects &&
                filteredGridProjectsLength > currentPageSize && (
                  <Layout.Footer
                    style={{
                      position: "sticky",
                      bottom: 0,
                      zIndex: 1000,
                      width: "100%",
                      backgroundColor: "white"
                    }}
                  >
                    <Row justify="end">
                      <Pagination
                        showSizeChanger
                        defaultPageSize={currentPageSize}
                        pageSize={currentPageSize}
                        pageSizeOptions={["10", "25", "50"]}
                        hideOnSinglePage={true}
                        onChange={(current: number, pageSize: number) => {
                          onChangePageSize(current, pageSize);
                        }}
                        total={filteredGridProjects?.length}
                      />
                    </Row>
                  </Layout.Footer>
                )}
            </Layout>
          </Row>
        </Col>
      </Row>
      <ProjectModal />
      <CreateProjectModal projectNames={projectNames} />
      <ProjectInvitesModal />
      <EditProjectModal />
      <AddPersonModal />
      <InviteExternalUserModal />
      <MoveDeviceModal />
      <ArchiveProjectModal />
      <RecoverProjectModal />
      <PositionsModal />
    </div>
  );
};

export default ProjectsPage;
