import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, List, Space, Typography } from "antd";
import { ScheduleOutlined } from "@ant-design/icons";
import StandardModal from "../Common/StandardModal";
import { PrimaryButton } from "../Common/CommonButtons";
import {
  closeOrderDeliveredModal,
  licenseStoreState,
  setCartState
} from "../../state/licenseStoreSlice";
import { insertIf } from "../../utils/generalUtils";
import {
  openProjectModal,
  projectsState,
  setProjectModalTab,
  setSelectedProjectId
} from "../../state/projectSlice";
import { Help } from "../MicroComponents/Help";
import { setCurrentAdminTab } from "../../state/navigationSlice";
import { useTranslation } from "react-i18next";
import { InfoAlert } from "../Common/CommonAlerts";
import { size } from "../../helpers/pageHelper";
import { HeaderTitle } from "../Common/CommonFonts";
import CurrencyAndPrice from "../MicroComponents/CurrencyAndPrice";
import { getUser } from "../../state/sessionSlice";
import { useGetCompanyDetailsQuery } from "../../state/cargologRestApi";
import { skipToken } from "@reduxjs/toolkit/query";
import { selectHelp } from "../../state/helpSlice";
const { Text } = Typography;

const OrderDeliveredModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { selectedProjectId } = useSelector(projectsState);
  const { cartState, totalCartPrice, isOrderDeliveredModalOpen } =
    useSelector(licenseStoreState);
  const { isHelpModeActive } = useSelector(selectHelp);

  const { companyId } = useSelector(getUser);
  const { data: companyData } = useGetCompanyDetailsQuery(
    companyId ? { companyId: companyId } : skipToken
  );

  // Calculate variables
  const calculateVAT = totalCartPrice * 0.25;

  return (
    <StandardModal
      open={isOrderDeliveredModalOpen}
      onCancel={() => {
        dispatch(closeOrderDeliveredModal());
        dispatch(setCartState([]));
      }}
      closable={false}
      title={t("OrderHasBeenSent")}
      footer={[
        ...insertIf(
          selectedProjectId !== undefined,
          <PrimaryButton
            onClick={() => {
              dispatch(closeOrderDeliveredModal());
              dispatch(setCartState([]));
              dispatch(openProjectModal());
              dispatch(setProjectModalTab("people"));
              navigate("/projects");
            }}
          >
            <ScheduleOutlined /> {t("BackToLastProject")}
          </PrimaryButton>
        ),
        Help(
          <PrimaryButton
            onClick={() => {
              dispatch(closeOrderDeliveredModal());
              dispatch(setCartState([]));
              dispatch(setSelectedProjectId(undefined));
              dispatch(setCurrentAdminTab("users"));
              navigate("/admin");
            }}
          >
            {t("AssignLicenses")}
          </PrimaryButton>,
          t("AssignTheLicenseToAUser"),
          "top",
          isHelpModeActive
        )
      ]}
      width={450}
      zIndex={1048}
    >
      <InfoAlert
        message={t("CheckoutMessage")}
        style={{
          marginTop: size.s1,
          marginBottom: size.l2
        }}
      />
      <List
        header={
          <Row>
            <Col>
              <HeaderTitle>{t("YourOrder")}:</HeaderTitle>
            </Col>
          </Row>
        }
        bordered
        dataSource={cartState}
        renderItem={(item) => (
          <List.Item key={item.licensePriceId}>
            <List.Item.Meta
              title={<>{item.name}</>}
              description={
                <>
                  {item.months} {t("months")}
                </>
              }
            />
            <Space direction="vertical" style={{ textAlign: "right" }}>
              <Text strong>
                {item.quantity} ×{" "}
                <CurrencyAndPrice price={item.price * item.months} />
              </Text>
              {item.companyDiscountPercent > 0 ? (
                <Text type="success">
                  -{" "}
                  <CurrencyAndPrice
                    price={
                      (item.price *
                        item.quantity *
                        item.months *
                        item.companyDiscountPercent) /
                      100
                    }
                  />
                </Text>
              ) : (
                <></>
              )}
            </Space>
          </List.Item>
        )}
        footer={[
          <Row justify="space-between">
            <Col>
              <Text style={{ fontSize: 18 }}>{t("cartTotalPrice")}:</Text>
            </Col>
            <Col>
              <Text style={{ fontWeight: "bold", fontSize: 18 }}>
                <CurrencyAndPrice price={totalCartPrice} />
              </Text>
            </Col>
          </Row>,
          ...insertIf(
            companyData !== undefined &&
              companyData.countryCode === "SE" &&
              companyData.vatNumber === "",
            <Row justify="space-between">
              <Col>
                <Text type="secondary">{t("VAT")}:</Text>
              </Col>
              <Col>
                <Text type="secondary">
                  +<CurrencyAndPrice price={calculateVAT} />
                </Text>
              </Col>
            </Row>
          )
        ]}
      />
    </StandardModal>
  );
};

export default React.memo(OrderDeliveredModal);
